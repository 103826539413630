import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { clamp, degreeToRadians, rotatePoint, round } from './libs/helpers';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';

// Throttle time for smooth scrolling
const CLICK_INIT_DELAY = 80;
const CLICK_DOWN_UP_DELAY = 16;
const CLICK_DELAY = 50;

type PinchDragMode = 'none' | 'zoom' | 'zoom-pan' | 'drag';

interface Transform {
  x: number;
  y: number;
  scale: number;
  rotate: number;
}

@Injectable()
export class ZoomService {
  private pinchZoomEl: HTMLElement | undefined;
  private wrapperEl: HTMLElement | undefined;
  private proxyLayerEl: HTMLElement | undefined;
  private iframeEl: HTMLIFrameElement | undefined;
  private wrapperX$ = new BehaviorSubject<number>(0);
  private wrapperY$ = new BehaviorSubject<number>(0);
  private wrapperWidth$ = new BehaviorSubject<number>(1);
  private wrapperHeight$ = new BehaviorSubject<number>(1);
  private wrapperBounds = new BehaviorSubject<{ x: number, y: number, width: number, height: number }>({ x: 0, y: 0, width: 0, height: 0 });
  private wrapperInnerX = new BehaviorSubject<number>(0);
  private wrapperInnerY = new BehaviorSubject<number>(0);
  private wrapperInnerWidth = new BehaviorSubject<number>(1);
  private wrapperInnerHeight = new BehaviorSubject<number>(1);
  private wrapperInnerRatio = new BehaviorSubject<number>(1);
  private canvasNaturalWidth = new BehaviorSubject<number>(1);
  private canvasNaturalHeight = new BehaviorSubject<number>(1);
  private canvasNaturalRatio = new BehaviorSubject<number>(1);
  private naturalScale = new BehaviorSubject<number>(1);
  private translate = new BehaviorSubject<[number, number]>([0, 0]);
  private baseTranslateOffset: [number, number] = [0, 0];
  private shouldApplyBaseTranslateOffset$ = new BehaviorSubject<boolean>(false);
  private scale = new BehaviorSubject<number>(1);
  private rotate = new BehaviorSubject<number>(0);
  private offset = new BehaviorSubject<{ top: number; left: number; right: number; bottom: number }>({ top: 0, left: 0, right: 0, bottom: 0 });
  private minScale = 1;
  private maxScale = 4;
  private _renderingScale = 0;
  renderingScale = new BehaviorSubject<number>(1);
  renderingTranslate = new BehaviorSubject<[number, number]>([0, 0]);
  // renderingRotate = new BehaviorSubject<number>(0);
  transitionDuration = 0.3;
  private bounds = true;
  private rotationEnabled = false;
  private transform: Transform | undefined;
  exposedTransform = new BehaviorSubject<Transform>({ x: 0, y: 0, scale: 1, rotate: 0 });

  private isInitFlow = false;
  private ngDestroy$ = new Subject<boolean>();

  // Touch
  private touchStarts: Array<{ client: [number, number]; canvasRel: [number, number] }> | null = null;
  private prevTouchesForScroll = null;
  private touchStartTranslate: [number, number] = [0, 0];
  private pinchDragMode: PinchDragMode = 'none';
  private modeLocked = false;

  private iframeScrollableEl!: HTMLElement | null;
  private iframeScrollableElRotateAngleRadians!: number | null;
  private lastClickTime = 0;

  private isResponsive = false;

  constructor() {}

  initZoom(params: {
    pinchZoomEl: HTMLElement,
    wrapperEl: HTMLElement,
    proxyLayerEl: HTMLElement,
    iframeEl: HTMLIFrameElement,
    canvasNaturalWidth: number,
    canvasNaturalHeight: number,
    isResponsive: boolean,
    offset?: { top: number; left: number; right: number; bottom: number },
    bounds?: boolean,
    rotationEnabled?: boolean,
    minScale?: number,
    maxScale?: number,
  }) {
    this.isInitFlow = true;
    this.pinchZoomEl = params.pinchZoomEl;
    this.wrapperEl = params.wrapperEl;
    this.proxyLayerEl = params.proxyLayerEl;
    this.iframeEl = params.iframeEl;
    this.canvasNaturalWidth.next(params.canvasNaturalWidth);
    this.canvasNaturalHeight.next(params.canvasNaturalHeight);
    this.isResponsive = params.isResponsive;
    if (params.offset !== undefined) {
      this.offset.next(params.offset);
    }
    if (params.bounds !== undefined) {
      this.bounds = params.bounds;
    }
    if (params.rotationEnabled !== undefined) {
      this.rotationEnabled = params.rotationEnabled;
    }
    if (params.minScale !== undefined) {
      this.minScale = params.minScale;
    }
    if (params.maxScale !== undefined) {
      this.maxScale = params.maxScale;
    }

    if (!this.wrapperEl) {
      console.error('ZoomService: wrapperEl not provided!');
      return;
    }

    this.ngDestroy$ = new Subject<boolean>();

    // 
    const boundingRect = this.pinchZoomEl.getBoundingClientRect();
    // console.log('### boundingRect: ', this.pinchZoomEl, boundingRect);
    this.wrapperX$.next(boundingRect.x);
    this.wrapperY$.next(boundingRect.y);
    this.wrapperWidth$.next(boundingRect.width);
    this.wrapperHeight$.next(boundingRect.height);
    this.wrapperBounds.next({
      x: boundingRect.x,
      y: boundingRect.y,
      width: boundingRect.width,
      height: boundingRect.height,
    });


    combineLatest([this.wrapperX$, this.offset]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([x, offset]) => this.wrapperInnerX.next(x + offset.left));

    combineLatest([this.wrapperY$, this.offset]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([y, offset]) => this.wrapperInnerY.next(y + offset.left));

    combineLatest([this.wrapperWidth$, this.offset]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([width, offset]) => this.wrapperInnerWidth.next(width - offset.left - offset.right));

    combineLatest([this.wrapperHeight$, this.offset]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([height, offset]) => this.wrapperInnerHeight.next(height - offset.top - offset.bottom));

    combineLatest([this.wrapperInnerWidth, this.wrapperInnerHeight]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([width, height]) => this.wrapperInnerRatio.next(width / height));

    combineLatest([this.canvasNaturalWidth, this.canvasNaturalHeight]).pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(([width, height]) => this.canvasNaturalRatio.next(width / height));

    combineLatest([this.scale, this.naturalScale]).pipe(
      skip(1),
      takeUntil(this.ngDestroy$),
    ).subscribe(([scale, naturalScale]) => {
      const rs = scale * naturalScale;
      this._renderingScale = rs;
      if (this.isInitFlow) {
        setTimeout(() => {
          this.renderingScale.next(rs);
          this.isInitFlow = false;
          // console.log('### renderingScale set after init!', this.isInitFlow, rs);
        }, 16);
      } else {
        this.renderingScale.next(rs);
      }
      // console.log('### renderingScale set!', this.isInitFlow, rs);
    });

    combineLatest([this.wrapperInnerRatio, this.canvasNaturalRatio, this.wrapperInnerWidth, this.wrapperInnerHeight, this.canvasNaturalWidth, this.canvasNaturalHeight]).pipe(
      debounceTime(0),
      takeUntil(this.ngDestroy$),
    ).subscribe(([wrapperRatio, canvasRatio, wrapperWidth, wrapperHeight, canvasWidth, canvasHeight]) => {
      let newScale;
      if (canvasRatio >= wrapperRatio) {
        newScale = wrapperWidth / canvasWidth;
        if (!this.isResponsive) {
          // Fits width. Need to set y translate offset
          const translateYOffset = (wrapperHeight - (canvasHeight * newScale)) / 2;
          // console.log('### translateYOffset: ', translateYOffset);
          this.baseTranslateOffset = [0, translateYOffset];
          this.shouldApplyBaseTranslateOffset$.next(true);
        }
      } else {
        newScale = wrapperHeight / canvasHeight;
        if (!this.isResponsive) {
          // Fits height. Need to set x translate offset
          const translateXOffset = (wrapperWidth - (canvasWidth * newScale)) / 2;
          // console.log('### translateXOffset: ', translateXOffset);
          this.baseTranslateOffset = [translateXOffset, 0];
          this.shouldApplyBaseTranslateOffset$.next(true)
        }
      }
      this.naturalScale.next(newScale);
      // console.log('### naturalScale: ', newScale);
    });

    combineLatest([this.offset, this.translate]).pipe(
      skip(1),
      takeUntil(this.ngDestroy$),
    ).subscribe(([offset, translate]) => {
      let newTranslateX = translate[0];
      let newTranslateY = translate[1];
      const rt = [
        offset.left + newTranslateX,
        offset.top + newTranslateY,
      ];
      this.renderingTranslate.next(rt as [number, number]);
      // console.log('### renderingTranslate set!', this.isInitFlow, rt);
    });

    this.shouldApplyBaseTranslateOffset$.asObservable().pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe((shouldApply: boolean) => {
      if (shouldApply) {
        // console.log('### should apply baseTranslateOffset');
        const translate = this.translate.getValue();
        // const baseTranslateOffset = this.baseTranslateOffset.getValue();
        if (
          translate && !isNaN(translate[0]) && !isNaN(translate[1])
          && this.baseTranslateOffset && !isNaN(this.baseTranslateOffset[0]) && !isNaN(this.baseTranslateOffset[1])
        ) {
          let newTranslateX = translate[0] + this.baseTranslateOffset[0];
          let newTranslateY = translate[1] + this.baseTranslateOffset[1];
          setTimeout(() => {
            // console.log('### baseTranslateOffset applied!', translate, this.baseTranslateOffset);
            this.setTranslate([
              newTranslateX,
              newTranslateY,
            ]);
          }, 10);
        }

        this.shouldApplyBaseTranslateOffset$.next(false);
      }
    });

    if (this.pinchZoomEl && this.wrapperEl) {
      this.initializeScrolling();
    }

    // console.log('### initZoom done');
  }

  private setTranslate([newTranslateX, newTranslateY]: [number, number]) {
    const overflowX = this.canvasNaturalWidth.getValue() * this._renderingScale - this.wrapperInnerWidth.getValue();
    const overflowY = this.canvasNaturalHeight.getValue() * this._renderingScale - this.wrapperInnerHeight.getValue();
    let _translateX = 0;
    let _translateY = 0;

    if (this.rotationEnabled || !this.bounds) {
      _translateX = newTranslateX;
      _translateY = newTranslateY;
    } else {
      // Calculate the overflow of the canvas
      const borderLeft = -overflowX;
      const borderRight = Math.max(0, -overflowX / 2);

      const borderTop = -overflowY;
      const borderBottom = Math.max(0, -overflowY / 2);

      _translateX = clamp(newTranslateX, borderLeft, borderRight);
      _translateY = clamp(newTranslateY, borderTop, borderBottom);
      // console.log('### setTranslate: ', _translateX, newTranslateX, borderLeft, borderRight, _translateY, newTranslateY, borderTop, borderBottom);
    }

    this.translate.next([_translateX, _translateY]);
  }
  private setScale(newScale: number) {
    // console.log('### setScale: ', newScale);
    this.scale.next(clamp(newScale, this.minScale, this.maxScale));
  }
  private setRotate(newRotate: number) {
    this.rotate.next(newRotate);
  }
  setExposedTransform(newTransform: Transform) {
    const roundedNewX = round(newTransform.x, 6);
    const roundedNewY = round(newTransform.y, 6);
    const roundedNewScale = round(newTransform.scale, 6);
    const roundedNewRotate = round(newTransform.rotate, 6);
    if (
      this.transform &&
      roundedNewX === this.transform.x &&
      roundedNewY === this.transform.y &&
      roundedNewScale === this.transform.scale &&
      roundedNewRotate === this.transform.rotate
    ) {
      return;
    }

    const radians = degreeToRadians(newTransform.rotate);
    const offset = this.getCenterOffset(newTransform.scale, [0, 0], radians);
    this.setTranslate([newTransform.x - offset[0], newTransform.y - offset[1]]);
    this.setScale(newTransform.scale);
    this.setRotate(radians);

    this.transform = {
      x: round(newTransform.x, 6),
      y: round(newTransform.y, 6),
      scale: round(newTransform.scale, 6),
      rotate: round(newTransform.rotate, 6),
    };

    this.exposedTransform.next(this.transform);
  }

  private getCenterOffset = (scale: number, translate: [number, number], rotate: number): [number, number] => {
    const centeredTranslationOffset = this.calcProjectionTranslate(scale, [0.5, 0.5], [0.5, 0.5], 0);
    const centeredPointNormal = [
      this.offset.getValue().left + centeredTranslationOffset[0] + this.canvasNaturalWidth.getValue() * (scale * this.naturalScale.getValue()) * 0.5,
      this.offset.getValue().top + centeredTranslationOffset[1] + this.canvasNaturalHeight.getValue() * (scale * this.naturalScale.getValue()) * 0.5,
    ];
    const composedPoint = this.composePoint(0.5, 0.5, scale, translate, rotate);

    const diffX = composedPoint[0] - centeredPointNormal[0];
    const diffY = composedPoint[1] - centeredPointNormal[1];

    return [diffX, diffY];
  }

  // Helper function that calculates the translation needed to map a point on the canvas to a point on the wrapper
  private calcProjectionTranslate = (
    newScale: number,
    wrapperPosition: [number, number],
    canvasPosition: [number, number],
    virtualRotate?: number
  ): [number, number] => {
    const canvasIntrinsicWidth = this.wrapperInnerWidth.getValue() * newScale;
    const canvasIntrinsicHeight = this.wrapperInnerHeight.getValue() * newScale;

    const canvasRealX = canvasPosition[0] * canvasIntrinsicWidth;
    const canvasRealY = canvasPosition[1] * canvasIntrinsicHeight;

    const rotatedPoint = rotatePoint([canvasRealX, canvasRealY], [0, 0], virtualRotate ?? this.rotate.getValue());

    const wrapperRealX = wrapperPosition[0] * this.wrapperInnerWidth.getValue();
    const wrapperRealY = wrapperPosition[1] * this.wrapperInnerHeight.getValue();

    const deltaX = wrapperRealX - rotatedPoint[0];
    const deltaY = wrapperRealY - rotatedPoint[1];

    return [deltaX, deltaY];
  }

  // Converts absolute client to coordinates to absolute inner-wrapper coorinates
  private clientCoordsToWrapperCoords = (clientX: number, clientY: number) => {
    return [clientX - this.wrapperInnerX.getValue(), clientY - this.wrapperInnerY.getValue()] as [number, number];
  }
  // Converts absolute inner wrapper coordinates to relative canvas coordinates (0-1, 0-1)
  private getCanvasCoords = (x: number, y: number) => {
    // Anchor is relative wrapper inner 0,0
    const anchor = [0, 0] as [number, number];
    // Untranslate the point
    const untranslatedPoint = [x - this.translate.getValue()[0], y - this.translate.getValue()[1]] as [number, number];
    // console.log('### getCanvasCoord: untranslatedPoint:', untranslatedPoint);
    // Unrotate the point
    const unrotatedPoint = rotatePoint(untranslatedPoint, anchor, -this.rotate.getValue());
    // console.log('### getCanvasCoord: unrotatedPoint:', unrotatedPoint);
    // Unscale the point
    // const unscaledPoint = [unrotatedPoint[0] / this.renderingScale.getValue(), unrotatedPoint[1] / this.renderingScale.getValue()];
    const unscaledPoint = [unrotatedPoint[0] / this._renderingScale, unrotatedPoint[1] / this._renderingScale];
    // console.log('### getCanvasCoord: unscaledPoint:', unscaledPoint, this.renderingScale.getValue());
    // Return the point relative to the canvas natural size
    const pointRel = [unscaledPoint[0] / this.canvasNaturalWidth.getValue(), unscaledPoint[1] / this.canvasNaturalHeight.getValue()] as [
      number,
      number
    ];
    // console.log('### getCanvasCoord: ', pointRel, this.canvasNaturalWidth.getValue(), this.canvasNaturalHeight.getValue());
    return pointRel;
  }
  // Converts client coordinates to relative canvas coordinates (0-1, 0-1)
  private normalizeMatrixCoordinates = (clientX: number, clientY: number) => {
    const innerWrapperCoords = this.clientCoordsToWrapperCoords(clientX, clientY);
    return this.getCanvasCoords(innerWrapperCoords[0], innerWrapperCoords[1]);
  }

  // Projects a point on the canvas to the wrapper
  private composePoint = (x: number, y: number, currScale?: number, currTranslate?: [number, number], currRotate?: number) => {
    currScale = currScale ?? this.scale.getValue();
    currTranslate = currTranslate ?? this.translate.getValue();
    currRotate = currRotate ?? this.rotate.getValue();

    const anchor = [this.wrapperInnerX.getValue(), this.wrapperInnerY.getValue()] as [number, number];
    const scaledPoint = [
      this.wrapperInnerX.getValue() + this.wrapperInnerWidth.getValue() * (currScale * x),
      this.wrapperInnerY.getValue() + this.wrapperInnerHeight.getValue() * (currScale * y)
    ] as [number, number];

    const rotatedPoint = rotatePoint(scaledPoint, anchor, currRotate);
    const translatedPoint = [rotatedPoint[0] + currTranslate[0], rotatedPoint[1] + currTranslate[1]];

    return translatedPoint;
  }

  destroy() {
    this.ngDestroy$?.next(true);
    this.ngDestroy$?.complete();
    this.ngDestroy$ = null!;

    if (this.pinchZoomEl) {
      this.pinchZoomEl.removeEventListener('touchstart', this.onTouchStart);
      this.pinchZoomEl.removeEventListener('touchmove', this.onTouchMove);
      this.pinchZoomEl.removeEventListener('touchend', this.onTouchEnd);
    }

    // Reset default values
    this.resetZoom();

    // console.log('### destroyed!');
  }

  private initializeScrolling() {
    if (this.pinchZoomEl) {
      this.pinchZoomEl.addEventListener('touchstart', this.onTouchStart);
      this.pinchZoomEl.addEventListener('touchmove', this.onTouchMove);
      this.pinchZoomEl.addEventListener('touchend', this.onTouchEnd);
    }
  }

  private contentScroll(event: TouchEvent) {
    const points = Array.from(event.touches);
    if (points.length === 1) {
      const touch = points[0];
      const dx = touch.clientX - this.prevTouchesForScroll.clientX;
      const dy = touch.clientY - this.prevTouchesForScroll.clientY;

      this.applyScroll(dx, dy);

      this.prevTouchesForScroll = touch; // Update previous touch position
    } else if (points.length === 3) {
      const midPoint = this.getMidpointOfThreeTouches(event.touches);
      if (midPoint) {
        const dx = midPoint.x - this.prevTouchesForScroll.x;
        const dy = midPoint.y - this.prevTouchesForScroll.y;
        this.applyScroll(dx, dy);

        this.prevTouchesForScroll = midPoint;
      }
    }
  }

  private getMidpointOfThreeTouches(touches: TouchList): { x: number; y: number } | null {
    if (touches.length < 3) {
        console.error('Less than three touches detected');
        return null; // Not enough touches to calculate midpoint
    }

    // Extract the coordinates of the first three touches
    const [touch1, touch2, touch3] = [touches[0], touches[1], touches[2]];

    // Calculate the average of x and y coordinates
    const midX = (touch1.clientX + touch2.clientX + touch3.clientX) / 3;
    const midY = (touch1.clientY + touch2.clientY + touch3.clientY) / 3;

    return { x: midX, y: midY };
}

  private onTouchStart = (event: TouchEvent) => {
    this.touchStarts = this.freezeTouches(event.touches);
    const points = Array.from(event.touches);
    this.prevTouchesForScroll = points?.length === 1 ? event.touches[0] : points?.length === 3 ? this.getMidpointOfThreeTouches(event.touches) : event.touches;
    this.pinchDragMode = 'none';
    this.iframeScrollableEl = null;
    this.iframeScrollableElRotateAngleRadians = null;
    this.modeLocked = false;
  }
  private onTouchMove = (event: TouchEvent) => {
    if (!this.modeLocked) {
      if (event.touches.length === 2) {
        this.pinchDragMode = 'zoom';
        this.modeLocked = true;
      } else if (this.scale.getValue() > 1 && event.touches.length === 1) {
        this.touchStartTranslate = [...this.translate.getValue()];
        this.pinchDragMode = 'zoom-pan'
        this.modeLocked = true;
      } else if (
        (this.scale.getValue() <= 1 && event.touches.length === 1)
        || (this.scale.getValue() > 1 && event.touches.length === 3)
      ) {
        this.pinchDragMode = 'drag';
        if (this.iframeScrollableEl === null) {
          this.detectElementToScroll(event);
        }
        this.modeLocked = true;
      }
    }

    switch (this.pinchDragMode) {
      case 'zoom':
        this.handlePinchZoom(event);
        break;
      case 'zoom-pan':
        this.handleFingerDrag(event);
        break;
      case 'drag':
        // this.handleContentScroll(event);
        this.contentScroll(event);
        break;
      default:
        break;
    }
  }
  private onTouchEnd = (event: TouchEvent) => {
    if (this.touchStarts?.length === 1 && this.pinchDragMode === 'none') {
      this.passClickToIframe(event);
    }

    this.touchStarts = null;
    this.prevTouchesForScroll = null;
    this.iframeScrollableEl = null
    this.pinchDragMode = 'none';
  }

  private applyScroll(dx: number, dy: number) {
    requestAnimationFrame(() => {
      if (this.iframeScrollableEl && this.pinchDragMode === 'drag') {
        const scrollX = dx / this._renderingScale;
        const scrollY = dy / this._renderingScale;
        if (this.iframeScrollableElRotateAngleRadians !== null) {
          const rotatedX = scrollX * Math.cos(this.iframeScrollableElRotateAngleRadians) + scrollY * Math.sin(this.iframeScrollableElRotateAngleRadians);
          const rotatedY = scrollX * -Math.sin(this.iframeScrollableElRotateAngleRadians) + scrollY * Math.cos(this.iframeScrollableElRotateAngleRadians);
          this.iframeScrollableEl.scrollBy(-rotatedX, -rotatedY);
        } else {
          this.iframeScrollableEl.scrollBy(-scrollX, -scrollY);
        }
      }
    });
  }

  // Touch
  private freezeTouches = (touches: TouchList) => {
    return Array.from(touches).map((touch) => {
      const wrapperCoords = this.clientCoordsToWrapperCoords(touch.clientX, touch.clientY);
      return {
        client: [touch.clientX, touch.clientY] as [number, number],
        canvasRel: this.getCanvasCoords(wrapperCoords[0], wrapperCoords[1]),
      };
    });
  }

  private handlePinchZoom = (event: TouchEvent) => {
    const touchPositions = Array.from(event.touches).map((touch) => this.clientCoordsToWrapperCoords(touch.clientX, touch.clientY));
    if (this.touchStarts && this.touchStarts.length >= 2 && touchPositions.length >= 2) {
      // Multi-touch logic for scaling and rotating
      const fingerOneStartCanvasCoords = [
        this.touchStarts[0].canvasRel[0] * this.canvasNaturalWidth.getValue(),
        this.touchStarts[0].canvasRel[1] * this.canvasNaturalHeight.getValue(),
      ] as [number, number];

      const fingerTwoStartCanvasCoords = [
        this.touchStarts[1].canvasRel[0] * this.canvasNaturalWidth.getValue(),
        this.touchStarts[1].canvasRel[1] * this.canvasNaturalHeight.getValue(),
      ] as [number, number];

      const fingerStartCanvasCoordsDelta = Math.sqrt(
        Math.pow(fingerOneStartCanvasCoords[0] - fingerTwoStartCanvasCoords[0], 2) +
          Math.pow(fingerOneStartCanvasCoords[1] - fingerTwoStartCanvasCoords[1], 2)
      );
;
      const fingersNowDelta = Math.sqrt(
        Math.pow(touchPositions[0][0] - touchPositions[1][0], 2) + Math.pow(touchPositions[0][1] - touchPositions[1][1], 2)
      ) / this.naturalScale.getValue();

      // console.log('### touchmove: fingersNowDelta:', fingersNowDelta);

      const futureScale = clamp(fingersNowDelta / fingerStartCanvasCoordsDelta, this.minScale, this.maxScale);

      const innerWrapperRelPos = [
        touchPositions[0][0] / this.wrapperInnerWidth.getValue(),
        touchPositions[0][1] / this.wrapperInnerHeight.getValue(),
      ] as [number, number];

      // console.log('### touchmove: futureScale:', futureScale);
      // console.log('### touchmove: innerWrapperRelPos:', innerWrapperRelPos);
      const innerCanvasRel = this.touchStarts[0].canvasRel;
      // console.log('### touchmove: innerCanvasRel:', innerCanvasRel);
      const [scaleDeltaX, scaleDeltaY] = this.calcProjectionTranslate(futureScale, innerWrapperRelPos, innerCanvasRel, 0);
      // console.log('### touchmove: scaleDeltaX:', scaleDeltaX, scaleDeltaY);
      let rotationDeltaX = 0;
      let rotationDeltaY = 0;
      let deltaAngle = 0;

      this.setScale(futureScale);
      this.setTranslate([scaleDeltaX + rotationDeltaX, scaleDeltaY + rotationDeltaY]);
    }
  }

  private handleFingerDrag = (event: TouchEvent) => {
    if (this.touchStarts.length && this.touchStartTranslate.length) {
      const deltaX = event.touches[0].clientX - this.touchStarts![0].client[0];
      const deltaY = event.touches[0].clientY - this.touchStarts![0].client[1];
      const futureTranslate: [number, number] = [this.touchStartTranslate[0] + deltaX, this.touchStartTranslate[1] + deltaY];
      this.setTranslate(futureTranslate);
    }
  }

  private passClickToIframe(event: TouchEvent) {
    const iframeDoc = this.iframeEl?.contentDocument || this.iframeEl?.contentWindow?.document;

    if (iframeDoc) {
      const touch = event.changedTouches[0];

      // Calculate the coordinates of the click event relative to the iframe's viewport
      const rect = this.iframeEl?.getBoundingClientRect();
      if (rect) {
        const scale = this.scale.getValue();
        const x = touch.clientX / scale - rect.left / scale;
        const y = touch.clientY / scale - rect.top /scale;

        if (this.proxyLayerEl) {
          const currentTime = Date.now();
          if (currentTime - this.lastClickTime < CLICK_DELAY) return; // Prevent rapid toggling
          this.lastClickTime = currentTime;

          // Set pointer events to 'none' briefly to pass click through
          this.proxyLayerEl.style.pointerEvents = 'none';

          // Manually trigger pointer events at specified coordinates
          const simulatedPointerDown = new PointerEvent('pointerdown', {
            bubbles: true,
            cancelable: true,
            clientX: x,
            clientY: y
          });

          const simulatedPointerUp = new PointerEvent('pointerup', {
            bubbles: true,
            cancelable: true,
            clientX: x,
            clientY: y
          });

          // Dispatch events directly to the iframe
          setTimeout(() => {
            if (this.iframeEl?.contentDocument) {
              this.iframeEl.contentDocument.dispatchEvent(simulatedPointerDown);
              setTimeout(() => {
                this.iframeEl.contentDocument.dispatchEvent(simulatedPointerUp);
                setTimeout(() => {
                  if (this.proxyLayerEl)
                    this.proxyLayerEl.style.pointerEvents = 'auto'; // Re-enable pointer events on proxy layer
                }, CLICK_DELAY); // Ensure pointer events toggle back after the specified delay
              }, CLICK_DOWN_UP_DELAY);
            }
          }, CLICK_INIT_DELAY);
        }
      }
    }
  }

  private detectElementToScroll(event: TouchEvent) {
    const iframeDoc = this.iframeEl?.contentDocument || this.iframeEl?.contentWindow?.document;

    if (iframeDoc) {
      const touch = event.changedTouches[0];

      // Calculate the coordinates of the click event relative to the iframe's viewport
      const rect = this.iframeEl?.getBoundingClientRect();
      if (rect) {
        const scale = this.scale.getValue();
        const x = touch.clientX / scale - rect.left / scale;
        const y = touch.clientY / scale - rect.top /scale;

        // Get the element at the clicked coordinates within the iframe
        const elementAtPoint = iframeDoc.elementFromPoint(x, y);
        if (elementAtPoint) {
          this.iframeScrollableEl = this.recursiveFindScrollableEl(elementAtPoint as HTMLElement);
          // 
          const style: any = window.getComputedStyle(this.iframeScrollableEl);
          const matrix = style.transform || style.webkitTransform || style.mozTransform;

          if (matrix !== "none") {
            const values = matrix.split('(')[1].split(')')[0].split(',');
            const a = parseFloat(values[0]);
            const b = parseFloat(values[1]);
            const angle = Math.round(Math.atan2(b, a) * (180 / Math.PI)); // Convert radians to degrees
            const normalizedAngle = (angle + 360) % 360;
            this.iframeScrollableElRotateAngleRadians = normalizedAngle * (Math.PI / 180);
          } else {
            this.iframeScrollableElRotateAngleRadians = null;
          }
          // console.log('### radians: ', this.iframeScrollableElRotateAngleRadians);
        }
      }
    }
  }
  private recursiveFindScrollableEl(element: HTMLElement): HTMLElement {
    if (!element) {
      return null!;
    } else if (element.tagName.toLowerCase() === 'html') {
      return element;
    }

    const hasScrollableContent = element.scrollHeight > element.clientHeight;
    const overflowYStyle = window.getComputedStyle(element).overflowY;
    const isOverflowHidden = overflowYStyle.includes('hidden');
    const isScrollable = hasScrollableContent && !isOverflowHidden;

    if (isScrollable) {
      return element;
    }

    return this.recursiveFindScrollableEl(element.parentElement!);
  }

  handleScreenResize(
    width: number,
    height: number,
  ) {
    this.canvasNaturalWidth.next(width);
    this.canvasNaturalHeight.next(height);

    const boundingRect = this.pinchZoomEl?.getBoundingClientRect();
    // console.log('### handleScreenResize boundingRect: ', width, height, this.pinchZoomEl, boundingRect);
    if (boundingRect) {
      this.wrapperX$.next(boundingRect.x);
      this.wrapperY$.next(boundingRect.y);
      this.wrapperWidth$.next(boundingRect.width);
      this.wrapperHeight$.next(boundingRect.height);
      this.wrapperBounds.next({
        x: boundingRect.x,
        y: boundingRect.y,
        width: boundingRect.width,
        height: boundingRect.height,
      });

      setTimeout(() => {
        const transalte = this.translate.getValue();
        // console.log('### call setTranslate after resize: ', transalte);
        this.setTranslate([transalte[0], transalte[1]]);
      }, 100);
    }
  }

  private resetZoom() {
    this.setScale(0);
    this.translate.next([0, 0]);
    this._renderingScale = 0;
    this.renderingScale.next(0);
    this.renderingTranslate.next([0, 0]);
    this.naturalScale.next(0);
    this.canvasNaturalWidth.next(1);
    this.canvasNaturalHeight.next(1);
    this.baseTranslateOffset = [0, 0];
    this.minScale = 1;
    this.maxScale = 4;
  }
}
