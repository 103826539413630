import { POSITION_GROUP_BASED_CALCULATED_RATINGS_IO_CONFIGURATION_NAME } from './shared.config';
export const DB_KEY_PREFIXES = {
  ACTIVITY: 'activity_',
  MEETING_ACTIVITY: 'activity_meeting_',
  EMAIL_ACTIVITY: 'activity_email_',
  TIMEOFF_ACTIVITY: 'activity_timeoff_',
  SAMPLE_ACTIVITY: 'activity_sample_',
  FOLLOW_UP_ACTIVITY: 'activity_follow_up_',
  ORDER_ACTIVITY: 'activity_order_',
  UNKNOWN_ACTIVITY: 'activity_unknown_',
  SURGERY_ORDER_ACTIVITY: 'activity_surgery_order_',
  STORE_CHECK_ACTIVITY: 'activity_store_check_',
  ACCOUNT: 'accounts',
  SEC_INFO_CONFG: 'sec_info_config',
  CONTACT: 'contacts',
  PRODUCT: 'brands',
  PRESENTATION: 'presentations',
  RESOURCE: 'resources',
  OFFLINE_RESOURCE: 'offlineresource',
  TIMEOFF: 'timeoff_',
  MY_TIMEOFF: 'timeoff_my_',
  MY_TIMEOFF_POSITIONS: 'myTimeOffPositions',
  MY_TIMEOFF_USERS: 'myTimeOffUsers',
  TEAM_TIMEOFF: 'timeoff_team_',
  TIMEOFF_REASONS: 'timeOffReasons',
  // SAMPLE_ALLOCATION: 'sample_alloc_',
  CUST_SAMPLE_ALLOC: 'cust_sample_alloc_',
  LOT: 'lot_',
  MY_COACHING: 'my_coaching_',
  TEAM_COACHING: 'team_coaching_',
  COACHING_TEMPLATES: 'coaching_template_',
  ALLOC_SHIPMENT: 'alloc_shipment_',
  ALLOC_SHIPMENT_AND_TRANSFER: 'alloc_shipment_and_transfer',
  ALLOC_SHIPMENT_ADJUSTMENT: 'alloc_shipment_adjustment',
  ALLOC_SHIPMENT_TEAM_ADJUSTMENT: 'alloc_shipment_adjustment_team',
  USER_POSITIONS: 'users_positions',
  ACCOMPANIED_USERS: 'accompanied_list',
  CUSTOMER_SEGMENT: 'customer_segment',
  SCHEDULER: 'scheduler_',
  SCHEDULER_PATTERS: 'scheduler_patterns',
  SCHEDULER_PRIORITIES: 'scheduler_priorities',
  SCHEDULER_ZIP: 'scheduler_zip',
  SCHEDULER_CITY: 'scheduler_city',
  COACHING: 'coaching_',
  COACHING_SCALES: 'coaching_scales',
  COACHING_USERS: 'coaching_users',
  CONSENTS: 'consents_',
  CHANNELS: 'channels_',
  CONSENT_TERMS: 'consent_terms_',
  CONSENT_ACTIVITIES: 'consent_activities_',
  CONSENT_TERMS_ALLOCATION_ORDERS: 'consent_term_allocation_orders_',
  EMAIL_TEMPLATE: 'email_template_',
  LAST_MODIFIED_KEYS: 'lastModifiedKeys',
  POLL_RESULTS: 'poll_results_',
  ACCOUNT_PLANS: 'account_plans',
  MARKETING_PLANS:'marketing_plans',
  ACCOUNT_PLAN_PROGRESS_REPORT: 'plan_progress_reports',
  SPECIALTY: 'specialty_',
  LANGUAGE: 'language_',
  SCIENTIFIC_PLANS: 'scientific_plan',
  SCIENTIFIC_PLANS_USERS: 'scientific_plan_users',
  THERAPEUTIC_AREA: 'therapeutic_area',
  MY_CASES: 'my_cases_',
  TEAM_CASES: 'team_cases_',
  PRICE_LISTS: 'price_lists_',
  COUNTRIES: 'countries',
  CONTACT_EVENT: 'contact_events_',
  ALLOC_TRANSFER_REASONS: 'alloc_transfer_reasons',
  ALLOC_ADJUST_REASONS: 'alloc_adjust_reasons',
  ALLOC_TRANSFER_USERS: 'alloc_transfer_users',
  SHIPMENT_LOSS_REASONS: 'shipmentlossreasons',
  CONTENT_MATCHING: 'contentmatching',
  CONTENT_MATCHING_MESSAGES: 'contentmatching_messages',
  MY_ASSISTANT_DATA: 'MyAssistantData',
  SYNC_SCHEDULE_COUNT: 'sync_schedule_count',
  DOMAINS: 'domains',
  USER: 'user',
  USER_CONFIG: 'userConfig',
  DB_ENCRYPTION_STATE: 'db_enc_state',
  DB_CRYPTO_KEY: 'kfc',
  OPPORTUNITIES: 'opportunities',
  OPPORTUNITY_QUOTES:'opportunity_quotes',
  OPPORTUNITY_AGREEMENTS: 'opportunity_agreements',
  ANALYTICS_EVENTS: "analytics_events",
  SETTINGS_ABOUT: "settings_about",
  EMBEDDED_INTERACTIONS: 'embedded_interactions_',
  CONTACT_RECENT_SEARCHES: 'contact_recent_searches',
  USER_SAVED_SEARCHES:'user_saved_searches',
  USER_SEARCH_TOOL_NAMES:'user_search_tool_names',
  CONSENT_RECENT_SEARCHES: 'consent_recent_searches',
  ACCOUNT_RECENT_SEARCHES: 'account_recent_searches',
  RESOURCE_RECENT_SEARCHES: 'resource_recent_searches',
  DYNAMIC_FORM: 'configured_dynamics_form',
  PRESENTATION_RECENT_SEARCHES: 'presentation_recent_searches',
  CONTENT_RECENT_SEARCHES: 'content_recent_searches',
  EMAIL_TEMPLATE_RECENT_SEARCHES: 'emailTemplate_recent_searches',
  TIME_OFF_RECENT_SEARCHES: 'timeOff_recent_searches',
  TIME_OFF_TEAM_RECENT_SEARCHES: 'timeOff_team_recent_searches',
  EXPERT_CATEGORIES: 'expert_categories',
  RESPONSE_PREFERENCE: 'response_preference',
  CASE_PRODUCTS: 'case_products',
  EMAIL_PRODUCTS: 'email_products',
  SAME_LEVEL_AND_CHILD_USERS_LIST: 'same_level_and_child_users_list',
  PROFESSIONAL_DESIGNATION: 'professional_designation',
  NOTE_ASSISTANT_CONFIG: 'note_assistant_config',
  XPERIENCE_CUSTOMERS: 'xperience_customers',
  XPERIENCE_OPTION_SETS: 'xperience_option_sets',
  XPERIENCE_INTEREST: 'xperience_interest',
  CUSTOMER_LICENSES: 'customer_licenses',
  MEETING_TYPES: 'meeting_types',
  NEXT_CALL_OBJECTIVES:'next_call_objectives',
  COACHING_TEMPLATE_USER_MAP: 'coaching_template_user_map',
  PHONE_CALL_ACTIVITY:'phone_call_activity_',
  MY_CALL_PLANS_RECENT_SEARCHES: 'my_call_plans_recent_searches',
  TEAM_CALL_PLANS_RECENT_SEARCHES: 'team_call_plans_recent_searches',
  XPERIENCES_TRENDING_ACCOUNTS: 'xperiences_trending_accounts',
  XPERIENCES_TRENDING_FEEDS: 'xperiences_trending_feeds',
  MY_COACHING_RECENT_SEARCHES: 'recent_searches_my_coaching',
  TEAM_COACHING_RECENT_SEARCHES: 'recent_searches_team_coaching',
  OLAP_MEETINGREPORT_CUBE : 'olap_meetingreport_cube',
  OFFLINE_CUSTOMER_NOTES: 'offline_customer_notes',
  CONTACT_PROFILE_OFFLINE_DATA: 'offline_contact_profile_data',
  MARKET_SCANS: 'market_scans_',
  CUSTOMER_SEGMENTATION: 'customer_segmentations',
  EMAIL_TEMPLATE_CONTENT_TOKEN: 'content_token_',
  EVENT_TYPES_ACTIVITY_BULK: 'event_types_activity_bulk',
  EVENT_TYPES: 'event_types',
  EVENT_GOALS: 'event_goals',
  SPEAKERS: 'speakers',
  EDGEANALYTICS_MEETINGFACTS: 'edgeanalytics_meeting_facts',
  EDGEANALYTICS_MESSAGE_FACTS: 'edgeanalytics_message_facts',
  EDGEANALYTICS_COACHING_FACTS: 'edgeanalytics_coaching_facts',
  EDGEANALYTICS_MEETING_DIMENSIONS: 'edgeanalytics_meeting_dimension',
  EDGEANALYTICS_CONFIGURATIONS: 'edgeanalytics_configurations',
  ACCOUNT_LINKED_ENTITY: 'account_linked_entity',
  CONTACT_CR: 'contact_cr_',
  ACCOUNT_CR: 'account_cr_',
  SOURCE_TYPE_OPTIONS: 'source_type_options_',
  MDM_OPTIONS: 'mdm_options_',
  CONTACT_CR_LINKED_ENTITY: 'contact_cr_linked_entity',
  ACCOUNT_CR_LINKED_ENTITY: 'account_cr_linked_entity',
  MEETING_ACTIVITY_TYPES: 'meeting_activity_types',
  MEETING_SUB_ACTIVITY_TYPES: 'meeting_sub_activity_types',
  MEETING_ACTIVITY_SUBJECTS: 'meeting_subjects',
  ACTIVITY_SNAPSHOT: 'activity_snapshot',
  MARKET_SCAN_CATEGORIES: 'market_scan_categories',
  SURGERY_PRODUCT_HIERARCHY: 'surgery_new_product-hierarchy',
  SURGERY_CATEGORY_PRODUCT_POSITION: 'surgery_category_product_position',
  SURGERY_RETIRED_PRODUCTS:'surgery_retired_products',
  HYPERLINK_LIST: 'hyperlink_list',
  UNSOLICITED_MEETING_DATA: 'unsolicited_meetings_data',
  MARKET_SCAN_EVENTS: 'market_scan_events',
  MARKET_SCAN_CAMPAIGNS: 'market_scan_campaigns',
  MARKET_SCAN_ACCOUNTS: 'market_scan_accounts',
  TEAM_ADJUSTMENTS: 'alloc_team_adjustments',
  OK_API_CONFIG: 'ok_api_config',
  VEEVA_WIDGET_CONFIG: 'veeva_widget_config',
  STAKEHOLDERS_CONNECTION_ROLES: 'stakeholder_connection_roles',
  BUSINESS_UNTIS:'business_units',
  OPPORTUNITY_TYPES: 'opportunity_types',
  COLLABORATOR_CONNECTION_ROLES: 'collaborator_connection_roles',
  FOLLOW_UP_ACTION_TYPES: 'followup_action_types',
  OFFLINE_ACCOUNT_NOTES: 'offline_account_notes',
  ONEKEY_CODES_LABELS: 'onekey_codes_labels',
  MY_POSITON_CALL_PLANS: 'my_position_call_plans',
  MY_POSITION_ACCOUNT_CALL_PLANS: 'my_position_account_call_plans',
  OTHER_POSITON_CALL_PLANS: 'other_position_call_plans',
  CONTACT_TAG: 'contact_tag_',
  FEATURE_REVISION_STORE: 'feature_revision_store',
  SURVEY_FORM: 'survey_form',
  SURVEY_RESPONSE: 'survey_response',
  SURVEY_LOOKUP_: 'survey_lookup_',
  AGENDA_PINNED_ITEMS: 'agenda_pinned_items',
  NOTIFICATIONS_LIST: 'notifications_list',
  CUSTOMER_ASSESS_TEMPLATES: 'customer_assess_templates_',
  CUSTOMER_ASSESS_TEAMVIEW_TEMPLATES: 'customer_assess_teamview_templates_',
  CUSTOMER_ASSESSMENT: 'customer_assessment_',
  CUSTOMER_ASSESSMENT_FOR_SEARCH: 'customer_assessment_for_search',
  POSITION_PRODUCT_MAP: 'position_product_map',
  POSITIONGROUP_PRODUCT_MAP: 'positiongroup_product_map',
  ASSESSED_CONTACTS: 'assessed_contacts',
  ASSESSED_ACCOUNTS: 'assessed_accounts',
  EVENT_TYPES_PICKLIST_OPTIONSETS: 'event_types_picklist_optionsets_',
  PREFERRED_ADDRESS: 'preferred_address',
  PROCEDURE_TRACKER_ACTIVITY: 'activity_procedure_tracker_',
  BUSINESS_UNIT_CHANNELS: 'business_unit_channels_',
  CUSTOMER_ASSETS: 'customer_assets_',
  ASSET_TRANSFERS: 'asset_transfers_',
  ASSET_NOTES: 'asset_notes_',
  ASSET_ACCOUNT_MANAGERS: 'asset_account_managers_',
  MEETING_CONTENT_REASONS: 'meeting_content_reasons',
  OPPORTUNITY_COMPETITORS: 'opportunity_competitors',
  OPPORTUNITY_REASONS: 'opportunity_reasons',
  OPPORTUNTIY_PRODUCTS_BY_POSITION: 'opportunity_products_by_position',
  PROCEDURE_TRACKER_SURGERY_PRODUCT_HIERARCHY: 'procedure_tracker_surgery_new_product-hierarchy',
  OPPORTUNITY_OPTION_SETS: 'opportunity_option_sets',
  FOLLOWUP_STATUS_REASON_CODES: 'followup_status_reason_codes',
  OFFLINE_MEETING_NOTES: 'offline_meeting_notes',
  SPECIALTY_PROCEDURES_FOR_ASSESSMENT: 'specialty_procedures_for_assessment',
  ALL_USERS: 'all_users',
  MEETING_ASSETS: 'meeting_assets_',
  DYNAMIC_FORMS_ALL_LOOKUP_FIELDS: 'dynamic_forms_all_lookup_fields',
  OFFLINE_CONTACT_ASSESSMENT: 'offline_contact_assessment_',
  OFFLINE_ACCOUNT_ASSESSMENT: 'offline_account_assessment_',
  ALL_CONTACT_ASSESSMENT: 'all_contact_assessment',
  ALL_ACCOUNT_ASSESSMENT: 'all_account_assessment',
  ALLOCATION_INVENTORY: 'allocation_inventory',
  CURRENCIES: 'currencies',
  CONTACT_ACTIVITIES_TIMELINE: 'contact_activities_timeline_',
  CONTACT_TIMELINE_OFFLINE_UPDATE_ACTIVITIES_BY_CONTACT: 'contact_timeline_offline_update_activities_by_contact',
  CONTACT_TIMELINE_OFFLINE_DELETE_ACTIVITIES_BY_CONTACT: 'contact_timeline_offline_delete_activities_by_contact',
  CONTACT_TIMELINE_OFFLINE_UPDATE_CONTACTS_BY_ACTIVITY: 'contact_timeline_offline_update_contacts_by_activity',
  CALLPLAN_ACTIVITIES_TIMELINE: 'callplan_activities_timeline_',
  CONTACT_REGISTRATION_EVENTS_TIMELINE: 'contact_registration_events_timeline_',
  CONTACT_CHECKIN_EVENTS_TIMELINE: 'contact_checkin_events_timeline_',
  CONTACT_COMPLETED_EVENTS_TIMELINE: 'contact_completed_events_timeline_',
  MULTILINGUAL_LOOKUP_ENTITIES:'multilingual_lookup_entities_',
  MULTILINGUAL_LOOKUP_LABELS:'multilingual_lookup_labels_',
  QUOTES : 'quotes_tools',
  QUOTE_PRICE_LISTS : "quote_price_lists",
  ADDRESS_BUILDINGS: 'address_buildings',
  SALES_DATA_MEASURE:'sales_data_measure',
  USER_TAG: 'user_tag_',
  PHARMACOVIGILANCE_REPORTS: 'pharmacovigilance_reports',
  PHARMACOVIGILANCE_REPORTS_INFO_BTNS_DATA: 'pharmacovigilance_reports_info_btns_data',
  CONTACT_KOL_STATUSES: 'contactkolstatuses',
  CONTACT_DISEASE_AREAS: 'contactdiseaseareas',
  MEETING_OBJECTIVES: 'meeting_objectives',
  CURRENT_LIST_PERIOD: 'current_list_period',
  FOLLOWUP_ACTION_OBJECTIVES : "followup_action_objectives",
  CUSTOMER_SURVEY: 'customer_saved_survey_',
  OFFLINE_CONTACT_SURVEY: 'offline_contact_survey_',
  OFFLINE_ACCOUNT_SURVEY: 'offline_account_survey_',
  ALL_CONTACT_SURVEY: 'all_contact_survey',
  ALL_ACCOUNT_SURVEY: 'all_account_survey',
  CUSTOMER_SURVEY_TIMELINE: 'customer_survey_timeline_',
  ACCOUNT_SURVEY_TIMELINE: 'account_survey_timeline_',
  INTERNAL_SURVEY:'internal_surveys',
  ALL_INTERNAL_SURVEYS:'all_internal_surveys',
  ALL_CONTACT_APPT_SURVEY: 'all_contact_appt_survey',
  DCR_APPROVAL_REQ: 'dcr_approval_req',
  ACCOUNT_VISIT_ALLOWED_FORMAT_IDS: 'account_visit_allowed_format_ids',
  PRODUCT_INDICATIONS: 'product_indications',
  CUSTOMER_SURVEY_TEMPLATES: 'customer_survey_templates_',
  CUSTOMER_MEDICAL_INSIGHTS: 'customer_medical_insights',
  KEY_MESSAGES_BY_POSITION_GROUP: 'key_messages_by_position_group',
  ACTIVITY_OUTCOMES: 'activity_outcomes',
  SUB_SPECIALTIES: 'sub_specialties',
  SKU_SAMPLE_UNIT_PRICE: 'sku_sample_unit_price',
  COACHING_PLANS: 'coaching_plans',
  COACHING_PLANS_ACTIVITY: 'coaching_plans_activity',
  CONTACT_QUICK_GLANCE_OPTIONS: 'contact_quick_glance_options',
  ACCOUNT_QUICK_GLANCE_OPTIONS: 'account_quick_glance_options',
  ACTIVE_CONSENTS:'active_consents',
  ACCOUNT_ASSESSMENT_FOR_SEARCH: 'account_assessment_for_search',
  CONTACT_SURVEY_TIMELINE: 'contact_survey_timeline_',
  SURVEYED_CONTACTS_FOR_ONETIME_SURVEY: 'surveyed_contacts_onetime_survey',
  SURVEYED_ACCOUNTS_FOR_ONETIME_SURVEY: 'surveyed_accounts_onetime_survey',
  PROCEDURE_TYPES:"procedure_types",
  PROCEDURE_SUB_TYPES:"procedure_sub_types",
  PROCEDURE_CONTRACT : "procedure_contracts",
  POSITION_GROUP_BASED_CALCULATED_RATINGS : "position_group_based_calculated_ratings",
  PROCEDURE_CONTRACT_SIGNATURE_TYPE : 'procedure_signaturetypes',
  NUM_OF_SURVEYED: "number_of_surveyed",
  MARKETING_BUSINESS_PLAN_TYPES: "marketing_business_plan_types",
  CUSTOMER_SPECIALITIES : "customer_specialities",
  CUSTOMER_ADDRESSES: "customer_addresses",
  ACTIVITY_CANCELLATION_REASONS:"activity_cancellation_reasons",
  CONTRACT_TYPES:"contract_types",
  SET_BOOKING: 'set_booking',
  SET_BOOKING_LINKED_ENTITY: 'set_booking_linked_entity',
  FORM_SECTION_CONFIG: 'form_section_config',
  PRESENTATION_USER_TAGS: "presentation_user_tags",
  PROCEDURE_SHOREI_MASTER:"procedure_shorei_master",
  PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS: 'procedure_contract_position_group_products',
  IO_CONFIG_ALL_LOOKUP_FIELDS: 'io_config_all_lookup_fields',
  USER_TODO_DATA: "user_todo_data",
  USER_POSITION_EDGE_ANALYTICS_METRICS: 'user_position_edge_analytics_metrics',
  PROCEDURE_CONTRACT_PICKLIST_OPTIONSETS: 'procedure_contract_picklist_optionsets_',
  COACHING_NOTE: 'coaching_note',
  SUPPORTING_MATERIALS: 'supporting_materials',
  MARKETING_JOURNEYS: 'marketing_journeys',
  REALTIME_MARKETING_EMAILS_INFO: 'realtime_marketing_emails_info',
  REALTIME_EMAILS_TIMELINE: 'realtime_emails_timeline_',
  CONTACT_LINK_ENTITIY_PRODUCT_RATING: 'indskr_productrating',
  STORE_CHECK_PRODUCT_FAMILY_CATEGORIES: 'store_check_product_family_categories',
  K_CODES_DATA:"k_codes_data",
  PRESENTATION_GPS_DATA: 'presentation_gps_data',
  DELETED_ACTIVE_CONSENTS: "deleted_active_consents",
  OFFLINE_CONTACT_CHANNEL_VALUES: 'offline_contact_channel_values_',
  ALLOCATION_PRODUCT_ELIGIBILITIES: 'alloc_prod_eligibilities',
  CUST_SAMPLE_PRODUCT_ELIGIBILITIES: 'cust_sample_prod_eligibilities'
}

export const DB_SYNC_STATE_KEYS = {
  SYNC: 'sync_',
  SYNC_STATUS: 'sync_status',
  SYNC_DAILY: 'sync_daily',
  SYNC_ACTIVITY: 'sync_activity',
  SYNC_ACCOUNT: 'sync_account',
  SYNC_SEC_INFO_CONFIG: 'sync_info_config',
  SYNC_MY_CALLPLAN: 'sync_my_callplan',
  SYNC_TEAM_CALLPLAN: 'sync_team_callplan',
  SYNC_CONTACT: 'sync_contact',
  SYNC_PRESENTATION: 'sync_presentation',
  SYNC_RESOURCE: 'sync_resource',
  SYNC_SAMPLE_ALLOC: 'sync_sample_alloc',
  SYNC_CUST_SAMPLE_ALLOC: 'sync_cust_sample_alloc',
  SYNC_LOT: 'sync_lot',
  SYNC_ALLOC_SHIPMENT: 'sync_alloc_shipment',
  SYNC_ALLOC_ADJUSTMENT: 'sync_alloc_adjustment',
  SYNC_ALLOC_TEAM_ADJUSTMENT:'sync_alloc_team_adjustment',
  SYNC_TEAM_COACHING: 'sync_team_coaching',
  SYNC_COACHING_TEMPLATES: 'sync_coaching_template',
  SYNC_MY_COACHING: 'sync_my_coaching',
  SYNC_CONSENTS: 'sync_consents',
  SYNC_CONSENT_CHANNELS: 'sync_consent_channels',
  SYNC_CONSENT_TERMS: 'sync_consent_terms',
  SYNC_CONSENT_TERMS_ALLOCATION_ORDERS: 'sync_consent_terms_allocation_orders',
  SYNC_EMAIL_TEMPLATES: 'sync_email_templates',
  SYNC_EMAIL_ACTIVITIES: 'sync_email_activities',
  SYNC_CONTENT_MATCHING: 'sync_content_matching',
  SYNC_CONTENT_MATCHING_MESSAGES: 'sync_content_matching_messages',
  SYNC_POLL_RESULTS: 'sync_poll_results',
  SYNC_MEDICAL_PROFILE: 'sync_medical_profile',
  SYNC_MARKETING_INFO: 'sync_marketing_info',
  SYNC_CUSTOMER_INQUIRY: 'sync_customer_innquiry',
  SYNC_SCIENTIFIC_PLANS: 'sync_scientific_plans',
  SYNC_THERAPEUTIC_AREA: 'sync_therapeutic_area',
  SYNC_CONTACT_EVENT: 'sync_contact_events',
  SYNC_SETTINGS_ABOUT: 'sync_settings_about',
  SYNC_CUSTOMER_LICENSES: 'sync_customer_licenses',
  SYNC_ADVANCED_SHARED_NOTIFICATION: 'sync_advanced_shared',
  SYNC_CONTACT_DF: 'sync_contact_df',
  SYNC_MARKET_SCAN: 'sync_market_scan',
  SYNC_CONTACT_CR: 'sync_contact_cr',
  SYNC_ACCOUNT_CR: 'sync_account_cr',
  SYNC_EVENTS_TOOL: 'sync_events_tool',
  SYNC_ALL_APPOINTMENT_ACTIVITIES_FOR_CUSTOM_FIELDS: 'sync_all_appointments_for_custom_fields',
  SYNC_ALL_PHONECALL_ACTIVITIES_FOR_CUSTOM_FIELDS: 'sync_all_phonecalls_for_custom_fields',
  SYNC_EDGE_ANALYTICS_MEETING: 'sync_edge_analytics_meeting',
  SYNC_EDGE_ANALYTICS_MESSAGE: 'sync_edge_analytics_message',
  SYNC_EDGE_ANALYTICS_COACHING: 'sync_edge_analytics_coaching',
  SYNC_ORDER_ACTIVITIES: 'sync_order_activities',
  SYNC_HYPERLINK_LIST: 'sync_hyperlink_list',
  SYNC_MEETINGS: 'sync_meetings',
  SYNC_TIMEOFFS: 'sync_timeoffs',
  SYNC_ALLOCATION_ORDERS: 'sync_allocation_orders',
  SYNC_FOLLOWUP_TASKS: 'sync_followup_tasks',
  SYNC_PHONECALLS: 'sync_phonecalls',
  SYNC_SURGERY_ORDER_ACTIVITIES: 'sync_surgery_order_activities',
  SYNC_CUSTOMER_TAG : 'sync_customer_tag',
  SYNC_CUSTOMER_ASSESS_TEMPLATES: 'sync_customer_assess_templates',
  SYNC_COACHING_PLANS: 'sync_coaching_plans',
  SYNC_COACHING_PLANS_ACTIVITY: 'sync_coaching_plans_activity',
  SYNC_PROCEDURE_TRACKER_ACTIVITIES: 'sync_procedure_tracker_activities',
  SYNC_CUSTOMER_ASSESS_FOR_SEARCH: 'sync_customer_assess_for_search',
  SYNC_CUSTOMER_ASSETS: 'sync_customer_assets',
  SYNC_ASSET_TRANSFERS: 'sync_customer_transfers',
  SYNC_ASSET_NOTES: 'sync_asset_notes',
  SYNC_ALL_USERS: 'sync_all_users',
  SYNC_MEETING_ASSETS: 'sync_all_meeting_assets',
  SYNC_ALL_CONTACT_ASSESS: 'sync_all_contact_assess',
  SYNC_ALL_ACCOUNT_ASSESS: 'sync_all_account_assess',
  SYNC_ALLOCATION_INVENTORY: 'sync_allocation_inventory',
  SYNC_TEAM_ORDER_NOTIFICATIONS: 'sync_team_order_notifications',
  SYNC_ACCOUNT_TAG : 'sync_account_tag',
  SYNC_CUSTOM_NOTIFICATIONS:"sync_custom_notifications",
  SYNC_KOL_STATUSES: "sync_kol_statuses",
  SYNC_DISEASE_AREAS: "sync_disease_areas",
  SYNC_REVIEW_CUSTOMER_POSITION_LIST: "sync_review_customer_position_list",
  SYNC_APPROVED_CUSTOMER_POSITION_LIST: "sync_approved_customer_position_list",
  SYNC_REJECTED_CUSTOMER_POSITION_LIST: "sync_rejected_customer_position_list",
  SYNC_ALL_CONTACT_SURVEY: 'sync_all_contact_survey',
  SYNC_ALL_ACCOUNT_SURVEY: 'sync_all_accountt_survey',
  SYNC_ALL_INTERNAL_SURVEY:'sync_all-internal_survey',
  SYNC_ALL_CONTACT_SURVEY_APPT: 'sync_all_contact_survey_appt',
  SYNC_PRODUCT_INDICATIONS: "sync_product_indications",
  SYNC_CUSTOMER_SURVEY_TEMPLATES: 'sync_customer_survey_templates',
  SYNC_MEDICAL_INSIGHTS: "sync_medical_insights",
  SYNC_PRESENTATION_TAG : 'sync_presentation_tag',
  SYNC_SUB_SPECIALTIES: 'sync_sub_specialties',
  SYNC_ACCOUNT_ASSESS_FOR_SEARCH: 'sync_account_assess_for_search',
  SYNC_REVIEW_APPEAL_STATUS: "sync_review_appeal_status",
  SYNC_PENDING_FOR_SUBMISSION_APPEAL_STATUS: "sync_pending_for_submission_appeal_status",
  SYNC_APPROVED_APPEAL_STATUS: "sync_approved_appeal_status",
  SYNC_REJECTED_APPEAL_STATUS: "sync_rejected_appeal_status",
  SYNC_MARKETING_BUSINESS_PLAN_TYPES: "sync_Marketing_Business_Plan_Types",
  SYNC_RESOURCE_TAG : 'sync_resource_tag',
  SYNC_SET_BOOKING_ACTIVITIES: 'sync_set_booking_activities',
  SYNC_PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS: 'sync_procedure_contract_position_group_products',
  SYNC_SUPPORTING_MATERIALS: "sync_supporting_materials",
  SYNC_DELETED_ACTIVE_CONSENTS: 'sync_deleted_active_consents',
  SYNC_CUST_SAMPLE_PROD_ELIGIBILITIES: 'sync_cust_sample_prod_eligibilities',
  SYNC_MASTER_STATUS: 'sync_master_status'
};

export const PREFIX_SEARCH_ENDKEY_UNICODE = '\ufff0';

export const DB_ALLDOCS_QUERY_OPTIONS = {
  GET_ALL_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_MEETING_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MEETING_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.MEETING_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_PHONECALL_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_SAMPLE_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.SAMPLE_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.SAMPLE_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_TIMEOFF_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.TIMEOFF_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.TIMEOFF_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_FOLLOW_UP_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ORDER_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ORDER_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.ORDER_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOUNTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ACCOUNT,
    endkey: `${DB_KEY_PREFIXES.ACCOUNT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_MY_POSITION_CALLPLANS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS,
    endkey: `${DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_OTHER_POSITION_CALLPLANS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS,
    endkey: `${DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_USER_POSITION_EDGE_ANALYTICS_METRICS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS,
    endkey: `${DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT,
    endkey: `${DB_KEY_PREFIXES.CONTACT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_PRESENTATIONS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.PRESENTATION,
    endkey: `${DB_KEY_PREFIXES.PRESENTATION}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_RESOURCES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.RESOURCE,
    endkey: `${DB_KEY_PREFIXES.RESOURCE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_OFFLINE_RESOURCES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.OFFLINE_RESOURCE,
    endkey: `${DB_KEY_PREFIXES.OFFLINE_RESOURCE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_TIMEOFFS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.TIMEOFF,
    endkey: `${DB_KEY_PREFIXES.TIMEOFF}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_MY_TIMEOFFS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MY_TIMEOFF,
    endkey: `${DB_KEY_PREFIXES.MY_TIMEOFF}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_TEAM_TIMEOFFS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.TEAM_TIMEOFF,
    endkey: `${DB_KEY_PREFIXES.TEAM_TIMEOFF}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  /*GET_ALL_SAMPLE_ALLOCATIONS: {
      include_docs: true,
      startkey: DB_KEY_PREFIXES.SAMPLE_ALLOCATION,
      endkey: `${DB_KEY_PREFIXES.SAMPLE_ALLOCATION}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },*/
  GET_ALL_CUSTOMER_SAMPLE_ALLOCATIONS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC,
    endkey: `${DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_LOTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.LOT,
    endkey: `${DB_KEY_PREFIXES.LOT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_MY_COACHING: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MY_COACHING,
    endkey: `${DB_KEY_PREFIXES.MY_COACHING}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_TEAM_COACHING: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.TEAM_COACHING,
    endkey: `${DB_KEY_PREFIXES.TEAM_COACHING}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_COACHING_RELATED_DATA: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.COACHING,
    endkey: `${DB_KEY_PREFIXES.COACHING}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_COACHING_TEMPLATES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.COACHING_TEMPLATES,
    endkey: `${DB_KEY_PREFIXES.COACHING_TEMPLATES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ALLOCATION_RELATED_DATA: {
    include_docs: true,
    startkey: 'alloc_',
    endkey: `alloc_${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ALLOCATION_SHIPMENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALLOC_SHIPMENT,
    endkey: `${DB_KEY_PREFIXES.ALLOC_SHIPMENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACTIVE_CONSENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONSENTS,
    endkey: `${DB_KEY_PREFIXES.CONSENTS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONSENT_CHANNELS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CHANNELS,
    endkey: `${DB_KEY_PREFIXES.CHANNELS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONSENT_TERMS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONSENT_TERMS,
    endkey: `${DB_KEY_PREFIXES.CONSENT_TERMS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONSENT_TERMS_ALLOCATION_ORDERS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONSENT_TERMS_ALLOCATION_ORDERS,
    endkey: `${DB_KEY_PREFIXES.CONSENT_TERMS_ALLOCATION_ORDERS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_EMAIL_TEMPLATES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.EMAIL_TEMPLATE,
    endkey: `${DB_KEY_PREFIXES.EMAIL_TEMPLATE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_EMAIL_TEMPLATE_CONTENT_TOKENS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.EMAIL_TEMPLATE_CONTENT_TOKEN,
    endkey: `${DB_KEY_PREFIXES.EMAIL_TEMPLATE_CONTENT_TOKEN}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_EMAIL_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.EMAIL_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.EMAIL_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_SURGERY_ORDER_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOMPANIED_USERS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ACCOMPANIED_USERS,
    endkey: `${DB_KEY_PREFIXES.ACCOMPANIED_USERS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUSTOMER_SEGMENT: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_SEGMENT,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_SEGMENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_SCHEDULER_RELATED_DATA: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.SCHEDULER,
    endkey: `${DB_KEY_PREFIXES.SCHEDULER}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_POLL_RESULTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.POLL_RESULTS,
    endkey: `${DB_KEY_PREFIXES.POLL_RESULTS}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_SYNC_STATES: {
    include_docs: true,
    startkey: DB_SYNC_STATE_KEYS.SYNC,
    endkey: `${DB_SYNC_STATE_KEYS.SYNC}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_EVENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_EVENT,
    endkey: `${DB_KEY_PREFIXES.CONTACT_EVENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_ALLOC_TRANSFER_RELATED_DATA: {
    include_docs: true,
    startkey: 'alloc_transfer_',
    endkey: `alloc_transfer_${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_MY_CASES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MY_CASES,
    endkey: `${DB_KEY_PREFIXES.MY_CASES}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_TEAM_CASES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.TEAM_CASES,
    endkey: `${DB_KEY_PREFIXES.TEAM_CASES}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_CUSTOMER_LICENSES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_LICENSES,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_LICENSES}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_MARKET_SCAN: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MARKET_SCANS,
    endkey: `${DB_KEY_PREFIXES.MARKET_SCANS}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_NOTIFICATIONS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.NOTIFICATIONS_LIST,
    endkey: `${DB_KEY_PREFIXES.NOTIFICATIONS_LIST}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_CONTACT_CR: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_CR,
    endkey: `${DB_KEY_PREFIXES.CONTACT_CR}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_ACCOUNT_CR: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ACCOUNT_CR,
    endkey: `${DB_KEY_PREFIXES.ACCOUNT_CR}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_SOURCE_TYPE_OPTIONS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.SOURCE_TYPE_OPTIONS,
    endkey: `${DB_KEY_PREFIXES.SOURCE_TYPE_OPTIONS}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_MDM_OPTIONS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MDM_OPTIONS,
    endkey: `${DB_KEY_PREFIXES.MDM_OPTIONS}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_EDGE_ANALYTICS_DATA: {
    include_docs: true,
    startkey: 'edgeanalytics_',
    endkey: `edgeanalytics_${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },

  GET_ALL_CUSTOMER_TAG: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_TAG,
    endkey: `${DB_KEY_PREFIXES.CONTACT_TAG}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_USER_TAG: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.USER_TAG,
    endkey: `${DB_KEY_PREFIXES.USER_TAG}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_CUST_ASSESS_TEMPLATES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEMPLATES,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEMPLATES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_COACHING_PLANS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.COACHING_PLANS,
    endkey: `${DB_KEY_PREFIXES.COACHING_PLANS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_COACHING_PLANS_ACTIVITY: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.COACHING_PLANS_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.COACHING_PLANS_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_ASSESS_TEAMVIEW_TEMPLATES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEAMVIEW_TEMPLATES,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEAMVIEW_TEMPLATES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_ASSESSMENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_ASSESSMENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALL_CONTACT_ASSESSMENT,
    endkey: `${DB_KEY_PREFIXES.ALL_CONTACT_ASSESSMENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOUNT_ASSESSMENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALL_ACCOUNT_ASSESSMENT,
    endkey: `${DB_KEY_PREFIXES.ALL_ACCOUNT_ASSESSMENT}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_PROCEUDRE_TRACKER_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY,
    endkey: `${DB_KEY_PREFIXES.PROCEDURE_TRACKER_ACTIVITY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_ASSESSMENTS_FOR_SEARCH: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT_FOR_SEARCH,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT_FOR_SEARCH}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_ASSETS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_ASSETS,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_ASSETS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ASSET_TRANSFERS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ASSET_TRANSFERS,
    endkey: `${DB_KEY_PREFIXES.ASSET_TRANSFERS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_MEETING_ASSETS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.MEETING_ASSETS,
    endkey: `${DB_KEY_PREFIXES.MEETING_ASSETS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ASSET_NOTES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ASSET_NOTES,
    endkey: `${DB_KEY_PREFIXES.ASSET_NOTES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_SURGERY_ORDER_RELATED_DATA: {
    include_docs: true,
    startkey: 'surgery_',
    endkey: `surgery_${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOUNT_MANAGERS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ASSET_ACCOUNT_MANAGERS,
    endkey: `${DB_KEY_PREFIXES.ASSET_ACCOUNT_MANAGERS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ALLOCATION_INVENTORY: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALLOCATION_INVENTORY,
    endkey: `${DB_KEY_PREFIXES.ALLOCATION_INVENTORY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_TIMELINE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_ACTIVITIES_TIMELINE,
    endkey: `${DB_KEY_PREFIXES.CONTACT_ACTIVITIES_TIMELINE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CALLPLAN_TIMELINE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CALLPLAN_ACTIVITIES_TIMELINE,
    endkey: `${DB_KEY_PREFIXES.CALLPLAN_ACTIVITIES_TIMELINE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_REGISTRATION_EVENTS_TIMELINE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_REGISTRATION_EVENTS_TIMELINE,
    endkey: `${DB_KEY_PREFIXES.CONTACT_REGISTRATION_EVENTS_TIMELINE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_CHECKIN_EVENTS_TIMELINE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_CHECKIN_EVENTS_TIMELINE,
    endkey: `${DB_KEY_PREFIXES.CONTACT_CHECKIN_EVENTS_TIMELINE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_COMPLETED_EVENTS_TIMELINE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CONTACT_COMPLETED_EVENTS_TIMELINE,
    endkey: `${DB_KEY_PREFIXES.CONTACT_COMPLETED_EVENTS_TIMELINE}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_SURVEYS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_SURVEY,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_SURVEY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_SURVEYS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALL_CONTACT_SURVEY,
    endkey: `${DB_KEY_PREFIXES.ALL_CONTACT_SURVEY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOUNT_SURVEYS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALL_ACCOUNT_SURVEY,
    endkey: `${DB_KEY_PREFIXES.ALL_ACCOUNT_SURVEY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CONTACT_APPT_SURVEYS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ALL_CONTACT_APPT_SURVEY,
    endkey: `${DB_KEY_PREFIXES.ALL_CONTACT_APPT_SURVEY}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_CUST_SURVEY_TEMPLATES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.CUSTOMER_SURVEY_TEMPLATES,
    endkey: `${DB_KEY_PREFIXES.CUSTOMER_SURVEY_TEMPLATES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_ACCOUNT_ASSESSMENTS_FOR_SEARCH: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.ACCOUNT_ASSESSMENT_FOR_SEARCH,
    endkey: `${DB_KEY_PREFIXES.ACCOUNT_ASSESSMENT_FOR_SEARCH}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_SET_BOOKING_ACTIVITIES: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.SET_BOOKING,
    endkey: `${DB_KEY_PREFIXES.SET_BOOKING}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },
  GET_ALL_COACHING_NOTE: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.COACHING_NOTE,
    endkey: `${DB_KEY_PREFIXES.COACHING_NOTE}${PREFIX_SEARCH_ENDKEY_UNICODE}`,
  },
  GET_ALL_DELETED_ACTIVE_CONSENTS: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.DELETED_ACTIVE_CONSENTS,
    endkey: `${DB_KEY_PREFIXES.CONSENTS}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  },

  GET_ALL_OFFLINE_CONTACT_META_ADD: {
    include_docs: true,
    startkey: DB_KEY_PREFIXES.OFFLINE_CONTACT_CHANNEL_VALUES,
    endkey: `${DB_KEY_PREFIXES.OFFLINE_CONTACT_CHANNEL_VALUES}${PREFIX_SEARCH_ENDKEY_UNICODE}`
  }
}
