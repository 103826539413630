import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AssessmentTemplate, SurveyToolTab, SurveyType, SurveyStatus, PreviewMode, SurveyFrequency, InternalSurveyStatus, SurveyCategory } from "@omni/classes/customer-assessment/assessment-template.class";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
import { IndTabsDataModel } from "@omni/models/ind-tabs-data-model";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { EventsService } from "@omni/services/events/events.service";
import { CustomerAssessment, InternalSurvey } from "@omni/classes/customer-assessment/customer-assessment.class";
import { AlertService } from "@omni/services/alert/alert.service";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { AuthenticationService } from "@omni/services/authentication.service";
import { Contact } from "@omni/classes/contact/contact.class";
import { ActivityService, ActivitySource } from "@omni/services/activity/activity.service";
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { DeviceService } from '@omni/services/device/device.service';
import { IonContent } from '@ionic/angular';
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { Account } from "@omni/classes/account/account.class";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";


@Component({
  selector: 'survey-details',
  templateUrl: 'survey-details.html',
  styleUrls:['survey-details.scss']
})
export class SurveyDetailsComponent {
  @ViewChild('surveyForm') surveyForm;
  @Input() appointmentId;
  @Input() from;
  @ViewChild(IonContent) ionContent: IonContent;
  public surveyRawData: CustomerAssessment | InternalSurvey;
  tabsData: IndTabsDataModel[];
  selectedTab: SurveyToolTab = SurveyToolTab.PREVIEW;
  prevSelectedTab: SurveyToolTab;
  private currentSegment: SurveyToolTab = SurveyToolTab.PREVIEW;
  surveyDetailsPageTitle: IndPageTitleViewDataModel;
  surveySectionHeaderData: IndSectionHeaderViewDataModel;
  selectedTemplate: AssessmentTemplate;
  private IcurrentSurveySubscription: Subscription;
  private IpreviewSurveyModeSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  public isHideTabs: boolean = false;
  public previewMode: PreviewMode = PreviewMode.DEFAULT;
  private selectedContactForSurvey: Contact;
  private selectedAccountForSurvey: Account;
  private selectedContactNameForSurvey: string = '';
  private selectedAccountNameForSurvey: string = '';
  private selectedSurveyID: string = '';
  private isOpenedFromActivitiesTab: boolean = false;
  private isValidToSubmitSurvey: boolean = false;
  private isSegmentChangedFromActivities: boolean = false;
  @Input() onSaveOrSubmitSurvey: () => void;
  private isAwaitingForSaveAndSubmitResponse = false;
  
  constructor(
    public footerService: FooterService,
    private uiService: UIService,
    private customerSurveyService: CustomerSurveyService,
    public translate: TranslateService,
    private contactService: ContactOfflineService,
    private readonly navService: NavigationService,
    public customerAssessService: CustomerAssessService,
    public events: EventsService,
    private alertService: AlertService,
    private _cd: ChangeDetectorRef,
    private authService: AuthenticationService,
    public device: DeviceService,
    public accountService: AccountOfflineService,
    private readonly activityService: ActivityService
  ) {  }

  ngOnInit() {
    this._setTabsData();
    this.prevSelectedTab = this.selectedTab;
    this.IcurrentSurveySubscription = this.customerSurveyService.currentSurveyObserver.pipe(takeUntil(this.ngDestroy$)).subscribe((value: AssessmentTemplate) => {
      if (value) {
        this.selectedTemplate = value;
        this._initSurveyDetailsPageTitle();
        this._initFooterButtons();
      }
    });
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(isOffline => {
      this._initSurveyDetailsPageTitle();
      this._initFooterButtons();
    });
    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
        let surveyFor = this.selectedTemplate.indskr_entity == 'contact' ? 'contact' : 'account';
        this.isHideTabs = !value[0];
        this.previewMode = value[0] ? PreviewMode.DEFAULT : PreviewMode.EDITABLE;
        if(this.selectedTab == SurveyToolTab.ACTIVITIES) {
          this.selectedTab = SurveyToolTab.PREVIEW;
        }
        if(!_.isEmpty(value[1])) {
          this.selectedSurveyID = value[1].indskr_customerassessmentid || value[1].indskr_usersurveyid || value[1]._indskr_usersurvey_value;
          //Submitted Survey
          if(value[1].surveyStatus == SurveyStatus.SUBMITTED) {
            this.previewMode = (value[2] == SurveyToolTab.ACTIVITIES || value[2] == 'timeline') ? PreviewMode.READONLY : PreviewMode.READONLY_SUBMITTED;
            this.isOpenedFromActivitiesTab = value[2] == SurveyToolTab.ACTIVITIES || value[2] == 'timeline';
            this.isHideTabs = true;
            //Update page title - External/Internal
            if(value[1]['indskr_surveytype'] == SurveyType.EXTERNAL && !_.isEmpty(value[1].responses)) {
              if(surveyFor == 'contact') {
                this.selectedContactNameForSurvey = value[1].responses[0]['ca.indskr_customer_Formatted'] || '';
              } else {
                // response check & update
                this.selectedAccountNameForSurvey = value[1].responses[0]['ca.indskr_customer_Formatted'] || '';
              }
            }else if(value[1]['indskr_surveytype'] == SurveyType.INTERNAL) {
              this.selectedContactNameForSurvey = '';
              this.selectedAccountNameForSurvey = '';
            }
            this._initSurveyDetailsPageTitle();
            //Submitted survey from Activities tab
            if(this.previewMode == PreviewMode.READONLY) {
              this.footerService.initButtons(FooterViews.None);
            }
            if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent && this.navService.getCurrentMasterPageName() != PageName.SurveyDetailsComponent) {
              this.customerSurveyService.inMeetingCustomerSurvey = true;
            }
            //Submitted survey after submitting the survey
            else if(this.previewMode == PreviewMode.READONLY_SUBMITTED) {
              const targetButtons = [{id:'scrapSurvey', isDisabled: true}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: true}];
              this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
            }
          }
          //Saved Survey
          else if(value[1].surveyStatus == SurveyStatus.SAVED) {
            this.isOpenedFromActivitiesTab = value[2] == SurveyToolTab.ACTIVITIES;
            this.previewMode = PreviewMode.EDITABLE;
            this.isHideTabs = true;
            this._initSurveyDetailsPageTitle();
            const isEnabledSubmitBtn = (value[1].isValidToSubmit || this.isValidToSubmitSurvey) ? true : false;
            const targetButtons = [{id:'scrapSurvey', isDisabled: false}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: !isEnabledSubmitBtn}];
            this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
          }
        }else {
          //opend survey details from contact details - profile
          if(this.from && this.from == PageName.ContactDetailsComponent) {
            this.isHideTabs = true;
            this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation || null;
            const templateId: string = this.selectedTemplate.indskr_assessmenttemplateid;
            const selectedContactId = this.customerSurveyService.selectedContactForSurvey.ID;
            let isCapturedOneTimeSurvey: boolean = false;
            //check captured one-time survey
            this._initSurveyDetailsPageTitle();
            if(this.selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              isCapturedOneTimeSurvey = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedContactId, 'contact');
              if (this.selectedTemplate.indskr_surveytype === SurveyType.IN_MEETING) {
                this.footerService.initButtons(FooterViews.GenericRightPane);
                this._cd.detectChanges();
              } else if(this.from != ActivitySource.MEETING_PRESENTATION && !this.customerSurveyService.inMeetingCustomerSurvey) {
                const targetButtons = [{id:'startSurvey', isDisabled: isCapturedOneTimeSurvey}];
                this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
              }else {
                const targetButtons = [{id:'startSurvey', isDisabled: this.device.isOffline}];
                this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
              }
            }else {
              this._initFooterButtons();  
            }
          } else if(this.from && this.from == PageName.AccountDetailsComponent) {
            this.isHideTabs = true;
            this.customerSurveyService.selectedAccountForSurvey = this.accountService.selected || null;
            const templateId: string = this.selectedTemplate.indskr_assessmenttemplateid;
            const selectedAccuntId = this.customerSurveyService.selectedAccountForSurvey?.id;
            let isCapturedOneTimeSurvey: boolean = false;
            //check captured one-time survey
            this._initSurveyDetailsPageTitle();
            if(this.selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              isCapturedOneTimeSurvey = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedAccuntId, 'account');
              if (this.selectedTemplate.indskr_surveytype === SurveyType.IN_MEETING) {
                this.footerService.initButtons(FooterViews.GenericRightPane);
                this._cd.detectChanges();
              } else if(this.from != ActivitySource.MEETING_PRESENTATION && !this.customerSurveyService.inMeetingCustomerSurvey) {
                const targetButtons = [{id:'startSurvey', isDisabled: isCapturedOneTimeSurvey}];
                this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
              }else {
                const targetButtons = [{id:'startSurvey', isDisabled: this.device.isOffline}];
                this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
              }
            }else {
              this._initFooterButtons();  
            }
          } else if(this.from && this.from == PageName.AccountDetailsComponent) {
            this.isHideTabs = true;
          }
          //Default preview - Internal survey with One-time
          else if(this.selectedTemplate.indskr_surveytype == SurveyType.INTERNAL && this.selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
            const getCurrentStatus: InternalSurveyStatus = this.customerAssessService.getInternalSurveyStatus(this.selectedTemplate);
            this._initSurveyDetailsPageTitle();
            if(getCurrentStatus == InternalSurveyStatus.COMPLETED) {
              const targetButtons = [{id:'startSurvey', isDisabled: true}];
              this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
            }else {
              const targetButtons = [{id:'startSurvey', isDisabled: this.device.isOffline}];
              this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
            }
          }
          //Default preview - Internal survey with Recurring, External survey with One-time/Recurring
          else {
            this._initSurveyDetailsPageTitle();
            this._initFooterButtons();
          }
        }
        //Editable form preview without saved values
        if(this.previewMode == PreviewMode.EDITABLE && _.isEmpty(value[1]) && _.isEmpty(this.surveyRawData)) {
          const targetButtons = [{id:'scrapSurvey', isDisabled: true}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: true}];
          this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
        }
    });
    this.events.observe('toggleSurveyButtons').pipe(takeUntil(this.ngDestroy$)).subscribe((data) =>{
      const targetButtons = [{id:'scrapSurvey', isDisabled: data['scrapDisabled']}, {id:'saveSurvey', isDisabled: data['saveDisabled']}, {id:'submitSurvey', isDisabled: data['submitDisabled']}];
      this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
    })
  }

  ngOnDestroy() {
    this.customerSurveyService.inMeetingCustomerSurvey = false;
    this.isSegmentChangedFromActivities = false;
    if (this.IcurrentSurveySubscription) this.IcurrentSurveySubscription.unsubscribe();
    if (this.IpreviewSurveyModeSubscription) this.IpreviewSurveyModeSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initSurveyDetailsPageTitle(): void {
    const isAfterSubmitting: boolean = this.previewMode == PreviewMode.READONLY_SUBMITTED;
    let formattedTitle: string = this.selectedTemplate && this.selectedTemplate.indskr_name;
    if(this.selectedTemplate.indskr_surveytype == SurveyType.INTERNAL) {
      const userName: string = this.authService.user.displayName;
      formattedTitle = this.selectedTemplate.indskr_name + ' - ' + userName;
    }else {
      let surveyFor = this.selectedTemplate.indskr_entity == 'contact' ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT;
      if(surveyFor == SurveyCategory.CONTACT) {
        if(!_.isEmpty(this.customerSurveyService.selectedContactForSurvey) && this.customerSurveyService.selectedContactForSurvey.fullName) {
          formattedTitle = formattedTitle + ' - ' + this.customerSurveyService.selectedContactForSurvey.fullName;
        }else if(!_.isEmpty(this.selectedContactNameForSurvey)) {
          formattedTitle = formattedTitle + ' - ' + this.selectedContactNameForSurvey;
        }else if(!_.isEmpty(this.selectedContactForSurvey)) {
          formattedTitle = formattedTitle + ' - ' + this.selectedContactForSurvey.fullName;
        }
      } else {
        if(!_.isEmpty(this.customerSurveyService.selectedAccountForSurvey) && this.customerSurveyService.selectedAccountForSurvey.accountName) {
          formattedTitle = formattedTitle + ' - ' + this.customerSurveyService.selectedAccountForSurvey.accountName;
        }else if(!_.isEmpty(this.selectedAccountNameForSurvey)) {
          formattedTitle = formattedTitle + ' - ' + this.selectedAccountNameForSurvey;
        }else if(!_.isEmpty(this.selectedAccountForSurvey)) {
          formattedTitle = formattedTitle + ' - ' + this.selectedAccountForSurvey.accountName;
        }
      }
    }
    if(this.previewMode == PreviewMode.EDITABLE || this.previewMode == PreviewMode.READONLY_SUBMITTED) {
      
      this.surveyDetailsPageTitle = {
        id: 'survey-details-page-title',
        title: formattedTitle,
        controls: [
          {
            id: 'close',
            icon: 'chevron-back-outline',
            isDisabled: false,
            align: 'left'
          },
          {
            id: 'scrapSurvey',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: isAfterSubmitting || this.device.isOffline,
            align: 'right'
          },
          {
            id: 'saveSurvey',
            imgSrc: 'assets/imgs/header_save.svg',
            name: this.translate.instant('SAVE'),
            isDisabled: isAfterSubmitting || this.device.isOffline,
            align: 'right'
          },
          {
            id: 'submitSurvey',
            imgSrc: 'assets/imgs/submit_white.svg',
            name: this.translate.instant('SUBMIT'),
            isDisabled: isAfterSubmitting || this.device.isOffline,
            align: 'right'
          }
        ],
      };
    }else if(this.previewMode == PreviewMode.READONLY) {
      
      this.surveyDetailsPageTitle = {
        id: 'survey-details-page-title',
        title: formattedTitle,
        controls: [
          {
            id: 'close',
            icon: 'chevron-back-outline',
            isDisabled: false,
            align: 'left'
          },
        ],
      };
    }else {
      //default view
      formattedTitle = this.selectedTemplate.indskr_name;

      this.surveyDetailsPageTitle = {
        id: 'survey-details-page-title',
        title: formattedTitle,
        controls: [
        ],
      };

      if (
        (
          this.from === ActivitySource.MEETING_DETAIL
          || this.from === ActivitySource.MEETING_PRESENTATION
        ) || (
          !(
            this.from === ActivitySource.MEETING_DETAIL
            || this.from === ActivitySource.MEETING_PRESENTATION
          )
          && this.selectedTemplate.indskr_surveytype !== SurveyType.IN_MEETING
        )
      ) {
        this.surveyDetailsPageTitle.controls.push(
          {
            id: 'startSurvey',
            imgSrc: 'assets/imgs/survey-icon-white-start.svg',
            name: this.translate.instant('START_SURVEY'),
            isDisabled: this.device.isOffline,
            align: 'right'
          }
        );
      }

      if(this.from && (this.from == PageName.ContactDetailsComponent || this.from == PageName.AccountDetailsComponent)) {
        this.surveyDetailsPageTitle.controls.push({
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        });
      }
    }
  }

  private _initFooterButtons() {
    if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent && this.navService.getCurrentMasterPageName() != PageName.SurveyDetailsComponent) {
      this.customerSurveyService.inMeetingCustomerSurvey = true;
      if(this.previewMode == PreviewMode.EDITABLE || this.previewMode == PreviewMode.READONLY_SUBMITTED) {
        const targetButtons = [{id:'scrapSurvey', isDisabled: true}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: true}];
        this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
      }
      // this.footerService.initButtons(FooterViews.PreviewMeeting);
    } else {
      this.customerSurveyService.inMeetingCustomerSurvey = false;
      if (!(this.from === ActivitySource.MEETING_DETAIL || this.from === ActivitySource.MEETING_PRESENTATION) && this.selectedTemplate?.indskr_surveytype === SurveyType.IN_MEETING) {
        this.footerService.initButtons(FooterViews.GenericRightPane);
        this._cd.detectChanges();
      } else if(this.previewMode == PreviewMode.EDITABLE || this.previewMode == PreviewMode.READONLY_SUBMITTED) {
        const targetButtons = [{id:'scrapSurvey', isDisabled: true}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: true}];
        this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
      }else if(this.previewMode == PreviewMode.READONLY) {
        this.footerService.initButtons(FooterViews.None);
      }else {
         //Default preview - Internal survey with One-time
        if(this.selectedTemplate.indskr_surveytype == SurveyType.INTERNAL && this.selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          const getCurrentStatus: InternalSurveyStatus = this.customerAssessService.getInternalSurveyStatus(this.selectedTemplate);
          if(getCurrentStatus == InternalSurveyStatus.COMPLETED) {
            const targetButtons = [{id:'startSurvey', isDisabled: true}];
            this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
          } else {
            const targetButtons = [{id:'startSurvey', isDisabled: this.device.isOffline}];
            this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
          }
        } else {
          const targetButtons = [{id:'startSurvey', isDisabled: this.device.isOffline}];
          this.toggleButtons(FooterViews.SURVEY_DETAILS, targetButtons);
        }
      }
    }
  }
  
  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'startSurvey':
        if(this.device.isOffline) return;
        this._gotoContactAccountSelection();
        break;
      case 'close':
        this.onClosePage();
        break;
      case 'scrapSurvey':
        if(this.device.isOffline) return;
        this.onScrap();
        break;
      case 'saveSurvey':
        if(this.device.isOffline) return;
        this.onSaveSurvey();
        break;
      case 'submitSurvey':
        if(this.device.isOffline) return;
        this.onSubmitSurvey();
        break;  
      default:
        break;
    }
  }

  private _setTabsData() {
    this.tabsData = [
      {
        displayText: this.translate.instant('SURVEY_DETAILS'),
        value: "preview"
      },
      {
        displayText: this.translate.instant('ACTIVITIES'),
        value: "activities",
      }
    ];
  }

  async segmentChanged(selectedTab) {
    if (this.currentSegment === selectedTab) return;
    if (this.ionContent) {
      this.ionContent.scrollToTop();
    }
    this.currentSegment = selectedTab;
    this.prevSelectedTab = selectedTab;
    if(this.currentSegment == SurveyToolTab.PREVIEW) {
      this.isSegmentChangedFromActivities = true;
    }
  }

  async onClosePage() {
    if(this.customerAssessService.isSurveyFormDirty && !(await this.customerAssessService.discardChagesForSurvey())) return;
    this.isValidToSubmitSurvey = false;
    if(this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent && this.from == ActivitySource.MEETING_DETAIL) {
      this.navService.popChildNavPageWithPageTracking();
      return;
    } else if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
      this.uiService.activeView = 'Meeting';
      this.footerService.initButtons(FooterViews.PreviewMeeting);
      // this.customerSurveyService.inMeetingCustomerSurvey = false;
      return;
    }else if(this.from == PageName.ContactDetailsComponent) {
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
      return;
    }else if(this.from == PageName.AccountDetailsComponent) {
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Accounts);
      return;
    }
    //if the current view is on preview tab, reset values
    if(this.selectedTab == SurveyToolTab.PREVIEW) {
      this.surveyForm.closePage();
    }
    this.selectedContactForSurvey = null;
    this.selectedAccountForSurvey = null;
    this.customerSurveyService.setSurveyPreviewMode(true);

    if(!_.isEmpty(this.prevSelectedTab)) {
      this.selectedTab = this.prevSelectedTab;
    }

    //Case: opened the preview from activities tab, go back to activities tab - mobile view
    if(this.device.isMobileDevice && this.device.deviceOrientation === "PORTRAIT" && !this.isOpenedFromActivitiesTab) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.SurveyPageComponent);
      this.navService.setChildNavRightPaneView(false)
    }else if(this.prevSelectedTab = SurveyToolTab.ACTIVITIES) {
      this.isOpenedFromActivitiesTab = false;
      this.currentSegment = SurveyToolTab.ACTIVITIES;
    }
    this.selectedSurveyID = null;
  }

  onScrap() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_SURVEY'),
      message: this.translate.instant('R_U_SURE_SCRAP_SURVEY')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        const templatedId = this.selectedTemplate.indskr_assessmenttemplateid;
        const entityId = this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL ? '' : this.selectedTemplate.indskr_entity == 'contact' ? this.customerSurveyService.selectedContactForSurvey.ID : this.customerSurveyService.selectedAccountForSurvey.id;
        let surveyId = this.selectedSurveyID ? this.selectedSurveyID : this.customerSurveyService.inMeetingSelectedSurveyId;
        this.surveyForm.scrapSurveyForm(templatedId, entityId, surveyId, this.from);
        this.customerSurveyService.setSurveyPreviewMode(true);
        this.selectedContactForSurvey = null;
        this.selectedAccountForSurvey = null;
        this.isValidToSubmitSurvey = false;
      }
    });
  }

  async onSaveSurvey() {
    if (this.isAwaitingForSaveAndSubmitResponse) {
      return;
    }
    if(this.surveyForm) {
      try {
        this.isAwaitingForSaveAndSubmitResponse = true;
        if(this.selectedTemplate['indskr_surveytype'] == SurveyType.IN_MEETING && _.isEmpty(this.selectedTemplate.indskr_entity)) {
          this.selectedTemplate.indskr_entity = _.isEmpty(this.customerSurveyService.selectedContactForSurvey) ? 'account' : 'contact';
        }
        const targetEntity = this.selectedTemplate.indskr_entity;
        const targetId = this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL ? this.authService.user.xSystemUserID : this.selectedTemplate.indskr_entity == 'contact' ? this.customerSurveyService.selectedContactForSurvey.ID : this.customerSurveyService.selectedAccountForSurvey.id;
        if(this.selectedTemplate['indskr_surveytype'] != SurveyType.INTERNAL) {
          this.selectedContactForSurvey = targetEntity == 'contact' ? this.customerSurveyService.selectedContactForSurvey : null;
          this.selectedAccountForSurvey = targetEntity == 'account' ? this.customerSurveyService.selectedAccountForSurvey : null;
        } 
        // this.surveyForm.saveSurveyForm({ entity: targetEntity, id: targetId, appointmentId: this.appointmentId, from: this.from });
        await this.surveyForm.saveAndsubmitSurveyForm({ entity: targetEntity, id: targetId, appointmentId: this.appointmentId, from: this.from }, SurveyStatus.SAVED);
        if (this.from == ActivitySource.MEETING_PRESENTATION || this.from == ActivitySource.MEETING_DETAIL) {
          this.onSaveOrSubmitSurvey();
        }
      } catch (error) {
        console.error('onSaveSurvey: ', error);
      } finally {
        this.isAwaitingForSaveAndSubmitResponse = false;
      }
    }
  }

  onSubmitSurvey() {
    if (this.isAwaitingForSaveAndSubmitResponse) {
      return;
    }
    try {
      this.isAwaitingForSaveAndSubmitResponse = true;

    this.alertService.showAlert({
      title: this.translate.instant('SUBMIT'),
      message: this.translate.instant('R_U_SURE_SUBMIT_SURVEY')
    }, this.translate.instant('SUBMIT')
    ).then(async res => {
      if (res.role == "ok") {
        if(this.surveyForm) {
          const targetEntity = this.selectedTemplate.indskr_entity;
          const targetId = this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL ? this.authService.user.xSystemUserID : targetEntity == 'contact' ? this.customerSurveyService.selectedContactForSurvey.ID : this.customerSurveyService.selectedAccountForSurvey.id;;
          this.selectedContactForSurvey = this.selectedTemplate['indskr_surveytype'] == SurveyType.EXTERNAL && targetEntity == 'contact' ? this.customerSurveyService.selectedContactForSurvey : null;
          this.selectedAccountForSurvey = this.selectedTemplate['indskr_surveytype'] == SurveyType.EXTERNAL && targetEntity == 'account' ? this.customerSurveyService.selectedAccountForSurvey : null;
          // this.surveyForm.submitSurveyForm({ entity: targetEntity, id: targetId, appointmentId: this.appointmentId, from: this.from });
          await this.surveyForm.saveAndsubmitSurveyForm({ entity: targetEntity, id: targetId, appointmentId: this.appointmentId, from: this.from }, SurveyStatus.SUBMITTED);
          if (this.from == ActivitySource.MEETING_PRESENTATION || this.from == ActivitySource.MEETING_DETAIL) {
            this.onSaveOrSubmitSurvey();
          }
        }
        this.prevSelectedTab = this.selectedTab;
      }
    });

    } catch (error) {
      console.error('onSubmitSurvey: ', error);
    } finally {
      this.isAwaitingForSaveAndSubmitResponse = false;
    }
  }

  public savedSurveyForm(data: CustomerAssessment  | InternalSurvey) {
    this.surveyRawData = data;
    this._initSurveyDetailsPageTitle();
    if(!_.isEmpty(data)) {
      if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      } else {
        const targetButtons = [{id:'scrapSurvey', isDisabled: false}, {id:'saveSurvey', isDisabled: true}, {id:'submitSurvey', isDisabled: !this.isValidToSubmitSurvey}];
        this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
      }
    }
  }

  public submittedSurveyForm(data: CustomerAssessment) {
    this.surveyRawData = null;
    this._initSurveyDetailsPageTitle();
  }

  public isValidToSubmit(isValid: boolean) {
    this.isValidToSubmitSurvey = isValid;
    if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
      this.customerSurveyService.inMeetingCustomerSurvey = true;
      this.footerService.initButtons(FooterViews.PreviewMeeting);
    } else {
      const targetButtons = [{id:'scrapSurvey', isDisabled: false}, {id:'saveSurvey', isDisabled: false}, {id:'submitSurvey', isDisabled: !isValid}];
      this.customerSurveyService.inMeetingCustomerSurvey = false;
      this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
      // this._initSurveyDetailsPageTitle();
    }
  }

  public isSurveyFormDirty(isDirty: boolean) {
    if(this.from == ActivitySource.MEETING_PRESENTATION && this.navService.getCurrentMasterPageName() != PageName.SurveyPageComponent) {
      this.customerSurveyService.inMeetingCustomerSurvey = true;
      if(!(this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT && this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent)) this.footerService.initButtons(FooterViews.PreviewMeeting);
    } else {
      this.customerSurveyService.inMeetingCustomerSurvey = false;
      if(this.isSegmentChangedFromActivities) {
        this.isSegmentChangedFromActivities = false;
        this._initFooterButtons();
      }else {
        const targetButtons = [{id:'scrapSurvey', isDisabled: !isDirty}, {id:'saveSurvey', isDisabled: !isDirty}, {id:'submitSurvey', isDisabled: true}];
        this.toggleButtons(FooterViews.SAVE_SURVEY_DETAILS, targetButtons);
      }
    }
  }

  public toggleButtons(targetFooterView: FooterViews, targetButtons: Array<{id:string, isDisabled:boolean}>) {
    if(this.previewMode == PreviewMode.READONLY) this.footerService.initButtons(FooterViews.None);
    else if(!_.isEmpty(targetButtons)) {
      let disableButtonsList = [];
      let enableButtonsList = [];
      targetButtons.forEach(targetBtn => {
        const index = this.surveyDetailsPageTitle.controls.findIndex(button => button.id == targetBtn.id);
        if (index > -1) {
          this.surveyDetailsPageTitle.controls[index].isDisabled = targetBtn.isDisabled;
          if(targetBtn.isDisabled) {
            disableButtonsList.push(targetBtn.id)
          }else {
            enableButtonsList.push(targetBtn.id)
          }
        }
      });
      this.footerService.initButtons(targetFooterView, {disableButtons: disableButtonsList, enableButtons: enableButtonsList})
      this._cd.detectChanges();
    }
  }

  public footerButtonClicked(buttonId: string) {
    switch (buttonId) {
      case "startSurvey":
        if(this.device.isOffline) return;
        this._gotoContactAccountSelection();
        break;
      case 'scrapSurvey':
        this.onScrap();
        break;
      case 'saveSurvey':
        this.onSaveSurvey();
        break;
      case 'submitSurvey':
        this.onSubmitSurvey();
        break;    
      default:
        break;
    }
  }

  private async _gotoContactAccountSelection() {
    const templateId: string = this.selectedTemplate.indskr_assessmenttemplateid || '';
    const surveyFor = this.selectedTemplate['indskr_entity'] == 'contact' ? 'contact' : 'account';
    let surveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, surveyFor, false);

    //pre populate contact/account for suvey if cureent meeting has only one contact/account
    let addSurveyContact: boolean = false;
    let addSurveyAccount: boolean = false;
    if (this.from && ((this.from == ActivitySource.MEETING_DETAIL ||this.from == ActivitySource.MEETING_PRESENTATION) && this.activityService.selectedActivity instanceof AppointmentActivity)) {
      const meeting = this.activityService.selectedActivity;
      if (surveyFor == 'contact' && meeting.contacts?.length == 1) {
        addSurveyContact = true;
        this.customerSurveyService.selectedContactForSurvey = meeting.contacts[0];
      } else if (surveyFor == 'account' && meeting.accounts?.length == 1) {
        addSurveyAccount = true;
        this.customerSurveyService.selectedAccountForSurvey = meeting.accounts[0];
      } 
    }
    if ((this.from && this.from == PageName.ContactDetailsComponent) || addSurveyContact) {
      //check saved survey data from db before starting the survey
      const contactId: string = this.customerSurveyService.selectedContactForSurvey.ID || '';
      if(!_.isEmpty(contactId) && !_.isEmpty(templateId)) {
        // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
        if(!_.isEmpty(surveysByTemplateId)) {
          let surveyDataFromDB: CustomerAssessment;
          let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === contactId);
          if (savedSurvey) {
              surveyDataFromDB = savedSurvey
          }
          // response update
          if(!_.isEmpty(surveyDataFromDB)) {
            const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(surveyDataFromDB, 'contact', true);
            if(!_.isEmpty(responseData)) {
              surveyDataFromDB.responses = responseData;  
            }
          }
          return this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
        }else {
          //There is no saved data with the selected contact and survey template
          return this.customerSurveyService.setSurveyPreviewMode(false);  
        }
      }
    } else if ((this.from && this.from == PageName.AccountDetailsComponent) || addSurveyAccount) {
      //check saved survey data from db before starting the survey
      const accountId: string = this.customerSurveyService.selectedAccountForSurvey.id || '';
      if(!_.isEmpty(accountId) && !_.isEmpty(templateId)) {
        if(!_.isEmpty(surveysByTemplateId)) {
          let surveyDataFromDB: CustomerAssessment;
          let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === accountId);
          if (savedSurvey) {
              surveyDataFromDB = savedSurvey;
          }
          // response update
          if(!_.isEmpty(surveyDataFromDB)) {
            const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(surveyDataFromDB, 'account', true);
            if(!_.isEmpty(responseData)) {
              surveyDataFromDB.responses = responseData;  
            }
          }
          return this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
        }else {
          //There is no saved data with the selected contact and survey template
          return this.customerSurveyService.setSurveyPreviewMode(false);  
        }
      }
    }
    if(this.selectedTemplate && this.selectedTemplate['indskr_surveytype'] == SurveyType.INTERNAL) {
      //check saved data
      const internalSurveysFromDB: InternalSurvey[] = await this.customerAssessService.getAllInternalSurveysFromDB();
      if(!_.isEmpty(internalSurveysFromDB)) {
        const savedSurveys: InternalSurvey[] = internalSurveysFromDB.filter(survey => survey['ca.indskr_template'] === this.selectedTemplate.indskr_assessmenttemplateid && survey.surveyStatus  === SurveyStatus.SAVED);
        if(!_.isEmpty(savedSurveys)) {
          // const internalSurvey: InternalSurvey = savedSurveys[0];
          const internalSurveyId: string = savedSurveys[0].indskr_usersurveyid;
          const filteredSavedSurveys = savedSurveys.filter(sur => sur.indskr_usersurveyid == internalSurveyId);
          const formattedSurvey: InternalSurvey = await this.customerAssessService.mapInternalSurveyResp(filteredSavedSurveys);          
          return this.customerSurveyService.setSurveyPreviewMode(false, formattedSurvey);
        }else {
          return this.customerSurveyService.setSurveyPreviewMode(false);
        }
      }else {
        return this.customerSurveyService.setSurveyPreviewMode(false);
      }
    }
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'surveyDetails';
    this.uiService.showNewActivity = false;
    if(this.selectedTemplate['indskr_entity'] == 'contact') {
      let selectedContactId: string = '';
      this.contactService.accessedContactListFrom = PageName.SurveyDetailsComponent;
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
      if(!_.isEmpty(this.customerSurveyService.selectedContactForSurvey)) {
        selectedContactId = this.customerSurveyService.selectedContactForSurvey.ID;
      }
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { template: this.selectedTemplate, contactId: selectedContactId }, PageName.SurveyDetailsComponent, { animate: false }, null); 
    } else {
      let selectedAccountId: string = '';
      this.accountService.accessedAccountListFrom = PageName.SurveyDetailsComponent;
      this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
      this.accountService.accountPageMode = ComponentViewMode.SELECTION;
      if(!_.isEmpty(this.customerSurveyService.selectedAccountForSurvey)) {
        selectedAccountId = this.customerSurveyService.selectedAccountForSurvey.id;
      }
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { template: this.selectedTemplate, accountId: selectedAccountId, 'listMode': ComponentViewMode.SELECTION }, PageName.SurveyDetailsComponent, { animate: false }, null); 
    }
  }
}

