import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { IoSelectionSheetComponent } from "@omni/components/io-component/io-selection-sheet/io-selection-sheet";
import { IndSimpleTooltipComponent } from "@omni/components/shared/ind-simple-tooltip/ind-simple-tooltip";
import { KPI_COLOR_CODE_GREEN_IN_LOWER_CASE, KPI_COLOR_CODE_RED_IN_LOWER_CASE, KPI_COLOR_CODE_YELLOW_IN_LOWER_CASE } from "@omni/config/call-plan/user-position-edge-analytics-metrics.config";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { DeltaService } from "@omni/data-services/delta/delta.service";
import { UserPositionEdgeAnalyticsMetrics } from "@omni/interfaces/call-plan/user-position-edge-analytics-metrics.interface";
import { SelectListData } from "@omni/models/select-list-data-model";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { CallPlanOfflineService } from "@omni/services/call-plan/call-plan.offline.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { DeviceService } from "@omni/services/device/device.service";
import { DiskService } from "@omni/services/disk/disk.service";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { LocalizationService } from "@omni/services/localization/localization.service";
import { Quarter } from "@omni/types/shared.type";
import { sortObjArrayByStringFields } from "@omni/utility/common.utility";
import { Utility } from "@omni/utility/util";
import { format } from "date-fns";
import { uniqBy } from "lodash";
import { Subject } from "rxjs";
import { debounceTime, skip, takeUntil } from "rxjs/operators";

type CallPlanKpiDataID = 'targetCustomers' | 'targetAccounts' | 'quarterlyWorkload' | 'rpc' | 'totalCallAch' | 'cpaAB' | 'remoteEDA' | 'weComPush' | 'weComPushCoverage';


interface CallPlanKpiData {
  id: CallPlanKpiDataID,
  name: string,
  primaryValue: string,
  primaryValueColorCode?: string,
  secondaryValue?: string,
  secondaryValueColorCode?: string,
  secondaryValuePrefixIcon?: string,
  tertiaryValue?: string,
  tertiaryValueColorCode?: string,
  tertiaryValuePrefixIcon?: string,
  ringChartPercentage?: number,
  ringChartColorCode?: string,
  columnSize?: 2 | 3,
  infoText?: string,
}

interface SalesCallPlanTableData {
  salesCallPlanId: string,
  contactId?: string,
  accountId?: string,
  fullName: string,
  segment: string,
  totalCall: string,
  remoteEDA?: string,
  weComPush?: string,
  cpaIndicator?: boolean,
  productName?: string,
  children?: SalesCallPlanTableData[]
}

interface CallPlanDetailData {
  tableData: SalesCallPlanTableData[],
  cpaIndicatorColor: string,
  hasChildCallPlans: boolean
}

@Component({
  selector: 'call-plan-dashboard',
  templateUrl: 'call-plan-dashboard.html',
  styleUrls: ['call-plan-dashboard.scss'],
})
export class CallPlanDashboardComponent implements OnInit {
  private ngDestroy$ = new Subject<boolean>();
  private popover;

  @Output() invokeFooterButtonClick: EventEmitter<{buttonId: string, isAgendaFooter?: boolean}> = new EventEmitter();

  isAndroid: boolean = false;
  backgroundUploadInProgress: boolean = false;
  syncInProgress: boolean = false;

  isSummaryLoading = true;
  isDetailLoading = true;

  private summaryDataMap: Map<string, UserPositionEdgeAnalyticsMetrics> = new Map();  // Key: {monthIdx}-{reportTimeFrame}

  detailTableData: SalesCallPlanTableData[] = [];
  accountCpaIndicatorColor: string;
  customerCpaIndicatorColor: string;
  private accountDetailTableDataMTD: CallPlanDetailData = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false};
  private customerDetailTableDataMTD: CallPlanDetailData = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
  private accountDetailTableDataQTD: CallPlanDetailData = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
  private customerDetailTableDataQTD: CallPlanDetailData = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };

  targetCustomersData: CallPlanKpiData;
  targetAccountsData: CallPlanKpiData;
  quarterlyWorkloadData: CallPlanKpiData;
  rpcData: CallPlanKpiData;
  totalCallAchData: CallPlanKpiData;
  cpaABData: CallPlanKpiData;
  remoteEdaData: CallPlanKpiData;
  weComPushData: CallPlanKpiData;
  weComPushCoverageData: CallPlanKpiData;
  visitingDaysThisMonth: string;
  timeProgress: string;
  lastUpdatedDateTime: string;

  gaugeChartDiameter = 120;
  threeColoumnGaugeChartDiameter = 90;

  displayType: 'A' | 'B' = 'A';
  currentSegment: 'customers' | 'accounts' | null = null;
  currentReportPeriod: 'MTD' | 'QTD' = 'MTD';
  currentSelectedReportMonthIdx: number;
  currentSelectedReportMonthIdxIdx: number;
  currentSelectedReportMonthShortFormLabel: string;
  currentQuarter: Quarter;
  currentQuarterMonthsFullForm: string[];
  currentQuarterMonthsShortForm: string[];
  currentQuarterMonthIdxes: number[];
  currentMonthIdxIdx: number;
  private preventMtdSelection = false;

  public localDbDataLoadInProgress = false;
  public syncMessage = this.translate.instant('UPLOAD_IN_PROGRESS');
  private expandedIndex: number | null = null;
  hasChildAccountCallPlans: boolean=false;
  hasChildContactCallPlans: boolean=false;

  constructor(
    public deltaService: DeltaService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private device: DeviceService,
    private disk: DiskService,
    private agendaFooterService: AgendaFooterService,
    private callPlanService: CallPlanOfflineService,
    private contactService: ContactOfflineService,
    private popoverCtr: PopoverController,
    private faService: FeatureActionsService,
    private accountService: AccountOfflineService,
    private authService: AuthenticationService,
    private modalCtrl: ModalController,
    private localizationService: LocalizationService,
  ) {
    this.isAndroid = this.device.isAndroid();
  }

  private colorCodeValidityCheck(colorCode: string) {
    let isValid = false;
    const lowerCased = colorCode?.toLowerCase() ?? undefined;
    if (
      lowerCased
      && (
        lowerCased === KPI_COLOR_CODE_GREEN_IN_LOWER_CASE
        || lowerCased === KPI_COLOR_CODE_YELLOW_IN_LOWER_CASE
        || lowerCased === KPI_COLOR_CODE_RED_IN_LOWER_CASE
      )
    ) {
      isValid = true;
    }

    return isValid;
  }

  private async loadAndMapKpiData() {
    this.isSummaryLoading = true;
    this.summaryDataMap.clear();

    const rawData: UserPositionEdgeAnalyticsMetrics[] = await this.callPlanService.loadUserPositionEdgeAnalyticsMetrics();
    rawData?.forEach(item => {
      let monthIdx;
      try {
        const parsedIsoString = new Date(item.indskr_yearmonth).toISOString();
        const splitString = parsedIsoString.split('T');
        const parsedYearMonthDate = splitString[0].split('-');
        const parsedMonthNumber = parseInt(parsedYearMonthDate[1]);
        monthIdx = parsedMonthNumber - 1;
      } catch (error) {
        console.error('loadAndMapKpiData: ', error);
      }

      const reportTimeFrame = item.indskr_reporttimeframe ? item.indskr_reporttimeframe : 'MTD';
      if (monthIdx && reportTimeFrame) {
        this.summaryDataMap.set(
          `${monthIdx}-${reportTimeFrame}`,
          item,
        );
      }
    });
  }

  private loadKpiDataOfMonthAndTimeFrame(monthIdx: number, reportTimeFrame: 'MTD' | 'QTD' = 'MTD') {
    if (this.syncInProgress) return;
    this.isSummaryLoading = true;

    if (isNaN(monthIdx) || monthIdx < 0 || monthIdx > 11) {
      console.error('loadKpiDataOfMonth: invalid monthIdx: ', monthIdx, reportTimeFrame);
      return;
    }
    const data: UserPositionEdgeAnalyticsMetrics = this.summaryDataMap.get(`${monthIdx}-${reportTimeFrame}`);
    if (!data) {
      console.error('loadKpiDataOfMonth: KPI data not found: ', data, monthIdx, reportTimeFrame);
    }

    this.targetCustomersData = {
      id: 'targetCustomers',
      name: this.translate.instant('TARGET_CUSTOMERS'),
      primaryValue: !isNaN(data?.indskr_targetcustomercount) ? '' + data.indskr_targetcustomercount : '-',
    };
    this.targetAccountsData = {
      id: 'targetAccounts',
      name: this.translate.instant('TARGET_ACCOUNTS'),
      primaryValue: !isNaN(data?.indskr_targetaccountcount) ? '' + data.indskr_targetaccountcount : '-',
    };
    this.quarterlyWorkloadData = {
      id: 'quarterlyWorkload',
      name: this.translate.instant('QUARTERLY_WORKLOAD_PERCENTAGE_LABEL'),
      primaryValue: !isNaN(data?.indskr_quarterlyworkloadpercentage) ? `${data.indskr_quarterlyworkloadpercentage}%` : '-',
      primaryValueColorCode: this.colorCodeValidityCheck(data?.indskr_quarterlyworkloadpercentagecolor) ? data.indskr_quarterlyworkloadpercentagecolor : '#000',
      columnSize: this.displayType === 'A' ? 2 : null,
      infoText: data?.indskr_quarterlyworkloadpercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_QUARTERLY_WORKLOAD'),
    };
    this.rpcData = {
      id: 'rpc',
      name: this.translate.instant('RPC'),
      primaryValue: !isNaN(data?.indskr_realtimecallpercentage) ? `${data.indskr_realtimecallpercentage}%` : '-',
      primaryValueColorCode: this.colorCodeValidityCheck(data?.indskr_realtimecallpercentagecolor) ? data.indskr_realtimecallpercentagecolor : '#000',
      infoText: data?.indskr_realtimecallpercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_RPC'),
    };
    this.totalCallAchData = {
      id: 'totalCallAch',
      name: this.translate.instant('TOTAL_CALL_ACH_PERCENTAGE_LABEL'),
      primaryValue: !isNaN(data?.indskr_totalcallpercentage) ? `${data.indskr_totalcallpercentage}%` : '-',
      secondaryValue: !isNaN(data?.indskr_actualtotalcallcount) && !isNaN(data?.indskr_targettotalcallcount) ? `${data.indskr_actualtotalcallcount}/${data.indskr_targettotalcallcount}` : '-',
      tertiaryValue: !isNaN(data?.indskr_targettotalcallachievepercentage) ? `${data.indskr_targettotalcallachievepercentage}%` : '',
      tertiaryValuePrefixIcon: 'target-contact-icon',
      ringChartPercentage: data?.indskr_totalcallpercentage || 0,
      ringChartColorCode: this.colorCodeValidityCheck(data?.indskr_totalcallpercentagecolor) ? data.indskr_totalcallpercentagecolor : '#000',
      infoText: data?.indskr_totalcallpercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_TOTAL_CALL_ACH_PERCENTAGE'),
    };
    this.cpaABData = {
      id: 'cpaAB',
      name: this.translate.instant('CPA_A+B_LABEL'),
      primaryValue: !isNaN(data?.indskr_cpaabpercentage) ? `${data.indskr_cpaabpercentage}%` : '-',
      secondaryValue: !isNaN(data?.indskr_cpaabachieved) && !isNaN(data?.indskr_cpaabtotal) ? `${data.indskr_cpaabachieved}/${data.indskr_cpaabtotal}` : '-',
      tertiaryValue: !isNaN(data?.indskr_targetcpaab) ? `${data.indskr_targetcpaab}%` : '',
      tertiaryValuePrefixIcon: 'target-contact-icon',
      ringChartPercentage: data?.indskr_cpaabpercentage || 0,
      ringChartColorCode: this.colorCodeValidityCheck(data?.indskr_cpaabpercentagecolor) ? data.indskr_cpaabpercentagecolor : '#000',
      infoText: data?.indskr_cpaabpercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_CPA_A+B'),
    };
    this.remoteEdaData = {
      id: 'remoteEDA',
      name: this.translate.instant('REMOTE_EDA_PERCENTAGE_LABEL'),
      primaryValue: !isNaN(data?.indskr_remoteedapercentage) ? `${data.indskr_remoteedapercentage}%` : '-',
      columnSize: this.displayType === 'A' ? 2 : 3,
      infoText: data?.indskr_remoteedapercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_REMOTE_EDA_PERCENTAGE'),
    };
    this.weComPushData = {
      id: 'weComPush',
      name: this.translate.instant('WECOM_PUSH_PERCENTAGE_LABEL'),
      primaryValue: !isNaN(data?.indskr_wecompushpercentage) ? `${data.indskr_wecompushpercentage}%` : '-',
      primaryValueColorCode: this.colorCodeValidityCheck(data?.indskr_wecompushpercentagecolor) ? data.indskr_wecompushpercentagecolor : '#000',
      secondaryValue: !isNaN(data?.indskr_targetwecompushpercentage) ? `${data.indskr_targetwecompushpercentage}%` : '',
      secondaryValuePrefixIcon: 'target-contact-icon',
      columnSize: this.displayType === 'A' ? 2 : 3,
      infoText: data?.indskr_wecompushpercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_WECOM_PUSH_PERCENTAGE'),
    };
    this.weComPushCoverageData = {
      id: 'weComPushCoverage',
      name: this.translate.instant('WECOM_PUSH_COVERAGE_LABEL'),
      primaryValue: !isNaN(data?.indskr_wecompushcoveragepercentage) ? `${data.indskr_wecompushcoveragepercentage}%` : '-',
      primaryValueColorCode: this.colorCodeValidityCheck(data?.indskr_wecompushcoveragepercentagecolor) ? data.indskr_wecompushcoveragepercentagecolor : '#000',
      secondaryValue: !isNaN(data?.indskr_targetwecompushcoveragepercentage) ? `${data.indskr_targetwecompushcoveragepercentage}%` : '',
      secondaryValuePrefixIcon: 'target-contact-icon',
      columnSize: this.displayType === 'A' ? 2 : 3,
      infoText: data?.indskr_wecompushcoveragepercentagedefinition ?? this.translate.instant('CALL_PLAN_DASHBOARD_DEFAULT_DEFINITION_WECOM_PUSH_COVERAGE'),
    };
    this.visitingDaysThisMonth = data?.indskr_budgetfielddaycount ? '' + data.indskr_budgetfielddaycount : '-';
    this.timeProgress = !isNaN(data?.indskr_timeprogress) ? `${data.indskr_timeprogress}%` : '-';
    this.lastUpdatedDateTime = data?.indskr_lastupdatedatetime
      ? format(data.indskr_lastupdatedatetime, 'YYYY/MM/DD HH:mm')
      : '-';

    this.isSummaryLoading = false;
  }

  ngOnInit() {
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this.cd.detectChanges();
    });
    this.device.syncInProgress$.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.syncInProgress = inProgress;
      if (this.syncInProgress === false && !this.faService.isCallPlanDashboardEnabledInMobileDevice) {
        // FA disabled. Navigate back to short call home.
        this.invokeFooterButtonClick.emit({buttonId: 'short-call', isAgendaFooter: true});
        this.cd.detectChanges();
        return;
      }
      this.faCheck();
      this.resetReportTimeFrame();
      this.initCurrentQuaterMonthsFormats();
      this.loadAndMapKpiData().then(() => this.loadKpiDataOfMonthAndTimeFrame(new Date().getMonth()));
      this.loadDetailsData();
      this.cd.detectChanges();
    });
    this.device.screenWidth.pipe(
      takeUntil(this.ngDestroy$),
      skip(1),
      debounceTime(200),
    ).subscribe((width: number) => {
      if (width > 0) {
        this.resizeRingCharts();
      }
    });

    this.agendaFooterService.initButtons(AgendaFooterView.CallPlanDashboard);
    this.faCheck();
    this.resetReportTimeFrame();
    this.initCurrentQuaterMonthsFormats();
    this.loadAndMapKpiData().then(() => this.loadKpiDataOfMonthAndTimeFrame(new Date().getMonth()));
    this.loadDetailsData();
  }

  ngAfterViewInit() {
    setTimeout(() => this.resizeRingCharts(), 40);
  }

  private async loadDetailsData() {
    this.isDetailLoading = true;
    if (this.syncInProgress) return;
    this.resetDetailData();
    if (this.displayType === 'B') {
      await this.loadTabDetails('account');
    }
    await this.loadTabDetails('contact');
    this.loadTableDataBySegmentAndTimeFrame();
    this.isDetailLoading = false;

  }

  private loadTableDataBySegmentAndTimeFrame() {
    this.expandedIndex = null;
    if (this.currentSegment === 'accounts') {
      if (this.currentReportPeriod === 'MTD') {
        this.detailTableData = this.accountDetailTableDataMTD.tableData;
        this.accountCpaIndicatorColor = this.accountDetailTableDataMTD.cpaIndicatorColor;
        this.hasChildAccountCallPlans = this.accountDetailTableDataMTD.hasChildCallPlans;
      } else {
        this.detailTableData = this.accountDetailTableDataQTD.tableData;
        this.accountCpaIndicatorColor = this.accountDetailTableDataQTD.cpaIndicatorColor;
        this.hasChildAccountCallPlans = this.accountDetailTableDataQTD.hasChildCallPlans;
      }
    } else {
      if (this.currentReportPeriod === 'QTD') {
        this.detailTableData = this.customerDetailTableDataQTD.tableData;
        this.customerCpaIndicatorColor = this.customerDetailTableDataQTD.cpaIndicatorColor;
        this.hasChildContactCallPlans = this.customerDetailTableDataQTD.hasChildCallPlans;
      } else {
        this.detailTableData = this.customerDetailTableDataMTD.tableData;
        this.customerCpaIndicatorColor = this.customerDetailTableDataMTD.cpaIndicatorColor;
        this.hasChildContactCallPlans = this.customerDetailTableDataMTD.hasChildCallPlans;
      }
    }
  }

  private resetDetailData() {
    this.accountCpaIndicatorColor = null;
    this.customerCpaIndicatorColor = null;
    this.hasChildAccountCallPlans = false;
    this.hasChildContactCallPlans = false;
    this.customerDetailTableDataMTD = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
    this.customerDetailTableDataQTD = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
    this.accountDetailTableDataMTD = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
    this.accountDetailTableDataMTD = { tableData: [], cpaIndicatorColor: null, hasChildCallPlans: false };
  }

  private async loadTabDetails(type: 'account' | 'contact') {
    const getCallPlansFromDb = async (type: 'account' | 'contact') => {
      let callPlans = [];
      if (type === 'account') {
        const rawAccountCallPlans = await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITION_ACCOUNT_CALL_PLANS, true);
        callPlans = this.callPlanService.getPresentCallPlans(rawAccountCallPlans?.raw || []);
      } else {
        const myRawCallPlans = await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS, true);
        this.callPlanService.segmentCallPlans = Array.isArray(myRawCallPlans?.raw) ? myRawCallPlans.raw : [];
        const formattedCallPlans = this.callPlanService.formattedCallPlans;
        callPlans = formattedCallPlans?.present || [];
      }
      return uniqBy(callPlans, 'indskr_customercallplanid')
    };

    const rawCallPlans = await getCallPlansFromDb(type);
    await this.processCallPlansForTab(rawCallPlans, type);

  }


  private async processCallPlansForTab(callPlans: any[], type: 'account' | 'contact') {
    // Helper function to group call plans by MTD and QTD
    const groupByMTDAndQTD = (callPlans: any[]): { MTD: any[], QTD: any[] } => {
      const grouped = callPlans.reduce((result, item) => {
        const isQTD = item.reporttimeframe === 548910001;
        const isMTD = item.reporttimeframe === 548910000 || !item.reporttimeframe;
        if (isQTD) {
          result.QTD.push(item);
        } else if (isMTD) {
          result.MTD.push(item);
        }
        return result;
      }, { MTD: [], QTD: [] });
      return grouped;
    };

    const { MTD, QTD } = groupByMTDAndQTD(callPlans);

    // Function to group call plans further by accountId or contactId
    const groupCallPlans = (callPlans: any[], key: string): any => {
      return callPlans.reduce((group, item) => {
        const groupKey = item[key];
        if (!group[groupKey]) {
          group[groupKey] = { ...item, children: [] };
        }
        if (item?.indskr_repcallplandetail) {
          group[groupKey].children.push(item);
        }
        return group;
      }, {});
    };

    // Function to create table data from call plans
    const createTableData = (callPlan: any, type: string, subCallPlan?: boolean): SalesCallPlanTableData => {
      const baseData: SalesCallPlanTableData = {
        salesCallPlanId: callPlan.indskr_customercallplanid,
        segment: callPlan.indskr_primarysegmentation || '-',
        fullName: callPlan.indskr_name,
        cpaIndicator: !!callPlan.indskr_cpaindicatorcolor && !subCallPlan,
        totalCall: `${!isNaN(callPlan.indskr_totalcompletedcalls) && callPlan.indskr_totalcompletedcalls !== 0 ? callPlan.indskr_totalcompletedcalls : '-'}/${!isNaN(callPlan.indskr_totalcallgoals) && callPlan.indskr_totalcallgoals !== 0 ? callPlan.indskr_totalcallgoals : '-'}`,
      };

      let tableData: SalesCallPlanTableData = type === 'contact' ? {
        ...baseData,
        contactId: callPlan.contactId,
        remoteEDA: !isNaN(callPlan.totalremotemeetingscompleted) ? '' + callPlan.totalremotemeetingscompleted : '-',
        weComPush: !isNaN(callPlan.indskr_actualemails) ? '' + callPlan.indskr_actualemails : '-',
      } : {
        ...baseData,
        accountId: callPlan.accountId,
      };

      if (subCallPlan) {
        tableData.productName = callPlan.indskr_repcallplandetail;
      }

      return tableData;
    };

    // Function to sort call plans by segment or full name
    const sortTableData = (data: SalesCallPlanTableData[], segmentSortFirst: boolean = false): SalesCallPlanTableData[] => {
      return sortObjArrayByStringFields(
        data,
        segmentSortFirst ? 'segment' : 'fullName',
        'fullName',
        false,
        ['en'],
        this.translate.currentLang === 'zh_CN' ? ['zh-CN-u-co-pinyin'] : undefined,
      );
    };

    // Process grouped call plans
    const generateTableData = (groupedCallPlans: any[], type: 'account' | 'contact') => {
      const arrayWithSegmentValue: SalesCallPlanTableData[] = [];
      const arrayWithoutSegmentValue: SalesCallPlanTableData[] = [];
      let cpaIndicatorColor: string = null;
      let hasChildCallPlans: boolean = false;

      for (const callPlan of Object.values(groupedCallPlans)) {
        const entity = type === 'account' ? this.accountService.getAccountById(callPlan.accountId) : this.contactService.getContactByID(callPlan.contactId);
        if (entity) {
          const tableData = createTableData(callPlan, type);
          const subCallPlans = callPlan.children || [];
          if (subCallPlans.length > 0) {
            tableData.children = processSubCallPlans(subCallPlans, type);
          }

          // Update CPA Indicator color and child call plans flag based on current call plan
          if (!cpaIndicatorColor && callPlan.indskr_cpaindicatorcolor) {
            cpaIndicatorColor = callPlan.indskr_cpaindicatorcolor;
            tableData.cpaIndicator = true;
          }

          if (subCallPlans.length > 0) {
            hasChildCallPlans = true;
          }

          addTableDataBySegment(tableData, arrayWithSegmentValue, arrayWithoutSegmentValue);
        }
      }

      return { arrayWithSegmentValue, arrayWithoutSegmentValue, cpaIndicatorColor, hasChildCallPlans };
    };

    // Process sub call plans
    const processSubCallPlans = (subCallPlans: any[], type: string): SalesCallPlanTableData[] => {
      const arrayWithSegmentValue: SalesCallPlanTableData[] = [];
      const arrayWithoutSegmentValue: SalesCallPlanTableData[] = [];
      for (let sbc of subCallPlans) {
        const subTableData = createTableData(sbc, type, true);
        sbc.indskr_primarysegmentation !== '' ? arrayWithSegmentValue.push(subTableData) : arrayWithoutSegmentValue.push(subTableData);
      }
      return sortTableData(arrayWithSegmentValue.concat(arrayWithoutSegmentValue));
    };

    // Add table data by segment
    const addTableDataBySegment = (tableData: SalesCallPlanTableData, withSegment: SalesCallPlanTableData[], withoutSegment: SalesCallPlanTableData[]) => {
      if (tableData.segment !== '') {
        withSegment.push(tableData);
      } else {
        withoutSegment.push(tableData);
      }
    };

    // Process call plans for MTD or QTD
    const processForTimeFrame = (grouped: any[]) => {
      const groupedCallPlans = groupCallPlans(grouped, type === 'account' ? 'accountId' : 'contactId');
      const { arrayWithSegmentValue, arrayWithoutSegmentValue, cpaIndicatorColor, hasChildCallPlans } = generateTableData(groupedCallPlans, type);
      const tableData = sortTableData(arrayWithSegmentValue.concat(arrayWithoutSegmentValue));
      return {
        tableData,
        cpaIndicatorColor,
        hasChildCallPlans
      };
    };

    // Set the processed result for MTD and QTD
    if (type === 'account') {
      this.accountDetailTableDataMTD = processForTimeFrame(MTD);
      this.accountDetailTableDataQTD = processForTimeFrame(QTD);
    } else {
      this.customerDetailTableDataMTD = processForTimeFrame(MTD);
      this.customerDetailTableDataQTD = processForTimeFrame(QTD);
    }
  }

  private faCheck() {
    const styleB = !!(this.authService.hasFeatureAction(FeatureActionsMap.CALL_PLAN_HOME_SCREEN_STYLE_B));
    const newDisplayType = styleB ? 'B' : 'A';
    if (newDisplayType === 'B' && this.displayType === 'A') {
      this.currentSegment = 'accounts';
    } else if (!styleB) {
      this.currentSegment = null;
    }
    this.displayType = newDisplayType;
  }

  private resizeRingCharts() {
    this.gaugeChartDiameter = ((window.innerWidth - 48) / 2) * 0.6667;
    this.threeColoumnGaugeChartDiameter = ((window.innerWidth - 64) / 3) - 16;
  }

  async infoClicked(infoText: string, e) {
    if (this.popover?.dismiss) {
      await this.popover.dismiss();
      this.popover = null;
    }
    this.popover = await this.popoverCtr.create({
      component: IndSimpleTooltipComponent,
      componentProps: {
        text: infoText,
      },
      event: e,
      showBackdrop: false,
      backdropDismiss: true,
      cssClass: 'call-plan-dash-popover',
    });

    await this.popover.present();
  }

  onSegmentChange(ev) {
    this.currentSegment = ev.detail.value;
    this.loadTableDataBySegmentAndTimeFrame();
  }
  onReportPeriodChange(ev) {
    this.currentReportPeriod = ev.detail.value;
    if (this.currentReportPeriod === 'MTD') {
      this.preventMtdSelection = true;
      this.loadKpiDataOfMonthAndTimeFrame(this.currentQuarterMonthIdxes[this.currentMonthIdxIdx]);
    } else {
      this.initCurrentQuaterMonthsFormats();
      this.loadKpiDataOfMonthAndTimeFrame(this.currentQuarterMonthIdxes[this.currentMonthIdxIdx], 'QTD');
    }
    this.loadTableDataBySegmentAndTimeFrame();
  }
  async onMtdPeriodSelectionClick() {
    if (this.currentReportPeriod === 'QTD') {
      return;
    } else if (this.preventMtdSelection) {
      this.preventMtdSelection = false;
      return;
    }
    const listData: SelectListData[] = [];
    for (let i = 0; i < this.currentQuarterMonthsFullForm.length; i++) {
      const monthFullForm = this.currentQuarterMonthsFullForm[i];
      listData.push({
        title: monthFullForm,
        id: '' + i,
        isSelected: this.currentSelectedReportMonthIdxIdx === i,
        isDisabled: i > this.currentMonthIdxIdx,
      });
    }
    const fullYear = new Date().getFullYear();
    const mtdSelectionModal = await this.modalCtrl.create({
      component: IoSelectionSheetComponent,
      componentProps: {
        view: 'io-selection-sheet',
        primaryHeader: 'MTD',
        secondaryHeader: `Q${this.currentQuarter} ${fullYear}`,
        listData,
      },
      backdropDismiss: true,
      cssClass: ['io-selection-sheet', 'dynamic-height-modal'],
    });

    await mtdSelectionModal.present();
    const response = await mtdSelectionModal.onDidDismiss();

    if (response?.data?.isSelectClicked && response.data.data) {
      this.expandedIndex = null;
      try {
        const selectedMonthIdxIdx = parseInt(response.data.data.id);
        if (!isNaN(selectedMonthIdxIdx)) {
          this.setSelectedReportMonth(selectedMonthIdxIdx);
          this.loadKpiDataOfMonthAndTimeFrame(this.currentQuarterMonthIdxes[selectedMonthIdxIdx]);
        }
      } catch (error) {
        console.error('onMtdPeriodSelectionClick: ', error);
      }
    }
  }

  private getFullAndShortFormMonths(monthIdxes: number[]): { fullForm: string[], shortForm: string[] } {
    const response = {
      fullForm: [],
      shortForm: [],
    };

    try {
      const date = new Date();
      const localeObj = this.localizationService.getDateFnsLocaleObject();
      for (const monthIdx of monthIdxes) {
        date.setMonth(monthIdx);
        response.fullForm.push(
          format(date, 'MMMM', localeObj),
        );
        response.shortForm.push(
          format(date, 'MMM', localeObj),
        );
      }
    } catch (error) {
      console.error('getFullAndShortFormMonths: ', error);
    }

    return response;
  }
  private setSelectedReportMonth(monthIdxIdx: number) {
    if (monthIdxIdx >= 0 && Array.isArray(this.currentQuarterMonthsShortForm)) {
      this.currentSelectedReportMonthIdxIdx = monthIdxIdx;
      this.currentSelectedReportMonthIdx = this.currentQuarterMonthIdxes[monthIdxIdx];
      const date = new Date();
      date.setMonth(this.currentSelectedReportMonthIdx);
      this.setCurMonthShortFormLabel(date);
    }
  }
  private setCurMonthShortFormLabel(date: Date, curMonth?: boolean) {
    const today = new Date();
    const isCurMonth = curMonth || (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear());
    this.currentSelectedReportMonthShortFormLabel = isCurMonth ? 'MTD' : format(
      date,
      'MMM',
      this.localizationService.getDateFnsLocaleObject()
    );
  }
  private resetReportTimeFrame() {
    this.currentReportPeriod = 'MTD';
  }
  private initCurrentQuaterMonthsFormats() {
    try {
      const quarter: Quarter = Utility.getCurrentQuarter();
      const quarterMonthIdxes = Utility.getZeroBasedQuaterMonthIdxes(quarter);

      if (quarterMonthIdxes) {
        const curMonthIdx = new Date().getMonth();
        // Init as current month by default
        if (!this.currentSelectedReportMonthIdx) {
          this.currentSelectedReportMonthIdx = curMonthIdx;
        }
        // Report Period ShortForm Label needs to be displayed
        if (curMonthIdx !== this.currentSelectedReportMonthIdx) {
          const date = new Date();
          date.setMonth(this.currentSelectedReportMonthIdx);
          this.setCurMonthShortFormLabel(date);
        }

        const curMonthIdxIdx = quarterMonthIdxes.findIndex(n => n === curMonthIdx);
        if (curMonthIdxIdx < 0) {
          console.error('getCurrentQuaterMonthsFormats: ', quarterMonthIdxes, curMonthIdx);
          return;
        }
        const fullAndShortFormMonths = this.getFullAndShortFormMonths(quarterMonthIdxes);
        if (fullAndShortFormMonths.fullForm.length > 0 && fullAndShortFormMonths.shortForm.length > 0) {
          this.currentQuarter = quarter;
          this.currentQuarterMonthIdxes = quarterMonthIdxes;
          this.currentQuarterMonthsFullForm = fullAndShortFormMonths.fullForm;
          this.currentQuarterMonthsShortForm = fullAndShortFormMonths.shortForm;
          this.currentMonthIdxIdx = curMonthIdxIdx;
          this.currentSelectedReportMonthIdxIdx = curMonthIdxIdx;
        } else {
          console.error('getCurrentQuaterMonthFormats: invalid months: ', quarterMonthIdxes, fullAndShortFormMonths);
        }
      }
    } catch (error) {
      console.error('getCurrentQuaterMonthFormats: ', error);
    }
  }

  trackByIndex(index: number, obj: any): number {
    return index;
  }

  toggleChildVisibility(i: number): void {
    if (this.expandedIndex === i) {
      this.expandedIndex = null;  // Collapse if already expanded
    } else {
      this.expandedIndex = i;  // Expand the clicked row
    }
  }

  isChildVisible(i: number): boolean {
    return this.expandedIndex === i;
  }

  get callPlanSummaryIconStyle() {
    if (this.currentSegment == 'accounts') {
      return this.accountCpaIndicatorColor && this.hasChildAccountCallPlans ? 'detail-cpa' : this.accountCpaIndicatorColor || this.hasChildAccountCallPlans ? 'detail-or-cpa' : '';
    } else {
      return this.customerCpaIndicatorColor && this.hasChildContactCallPlans ? 'detail-cpa' : this.customerCpaIndicatorColor || this.hasChildContactCallPlans ? 'detail-or-cpa' : ''
    }
  }
}
