<ion-content>
  <!--Survey Detail Grid Form View - new design-->
  <ind-section-header *ngIf="isMeetingPresentation" [viewData]='surveySectionDetails' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <div class="survey-detail-wrapper">
    <div class="survey-icon-col">
      <div class="circle-background align-startIcon">
        <img *ngIf="detailViewData.startIconInline" class="start-inline-icon" [src]="detailViewData.startIconInline">
      </div>
    </div>
    <ion-label class="survey-detail-col">
      <ion-text class="survey-primary-detail">
        <h3 class="template-name">{{detailViewData.surveyName}}</h3>
        <h3 class="metadata">{{detailViewData.surveyType}}</h3>
        <h3 class="metadata">{{detailViewData.surveyFrequency}}</h3>
        <h3 *ngIf="detailViewData.surveyContact" class="metadata">{{detailViewData.surveyContact}}</h3>
      </ion-text>
      <div class="survey-secondary-detail hidden-on-mobile-portrait">
        <ng-container *ngTemplateOutlet="expiryInfoCard"></ng-container>
      </div>
      <ind-expandable-text *ngIf="detailViewData.description"
        class="survey-description"
        [text]="detailViewData.description"
        [clampLines]="expandableClampLines">
      </ind-expandable-text>
      <div class="survey-secondary-detail hidden-on-split-view">
        <ng-container *ngTemplateOutlet="expiryInfoCard"></ng-container>
      </div>
    </ion-label>
  </div>
  <ind-form-field *ngIf="isHCPEnabled" [viewData]="hcpFormField"></ind-form-field>

  <!--Survey Form View--> 
  <ng-container *ngFor="let viewData of currentFormView;trackBy:trackId">
  <ind-section-header *ngIf="viewData.type == 'section-header'" [viewData]='viewData.view'></ind-section-header>
  <div class="emptyContainer" *ngIf="viewData.type == 'empty-container'"></div>
  <ng-container *ngIf="viewData.type == 'form-field' && !viewData.isHidden">
    <ind-form-field *ngIf="viewData.control?.Type != '451680002'" [viewData]='viewData.view' [ngClass]="viewData.id" [id]='viewData.id'>
    </ind-form-field>
    <ng-container *ngIf="viewData.control?.Type == '451680002'">
      <ion-row>
        <ion-col size="6"><ind-datetime-form *ngIf="viewData.view && !viewData.isHidden" [viewData]='viewData.view'></ind-datetime-form></ion-col>
        <ion-col size="6"><ind-datetime-form *ngIf="viewData.timeView && !viewData.isHidden" [viewData]='viewData.timeView'></ind-datetime-form></ion-col>
        <ion-item *ngIf="(viewData.view.infoText && viewData.view.displayInfoText) || (viewData.timeView.infoText && viewData.timeView.displayInfoText)" class="message-container">
          <ion-label class="info-message-text">{{viewData.view.infoText ? viewData.view.infoText : viewData.timeView.infoText}}</ion-label>
        </ion-item>
      </ion-row>
    </ng-container>
  </ng-container>
  </ng-container>
</ion-content>


<ng-template #expiryInfoCard let-classNames>
  <ion-text class="expiry-info-card">
    <h3>{{detailViewData.surveyDuration}}</h3>
    <div>
      <span class="totalQuestionAndTimeBubble">{{detailViewData.surveyBubbleText}}</span>
    </div>
  </ion-text>
</ng-template>
