import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IndKeyControlsAreaModel } from '../../../models/indKeyControlsAreaModel';

/**
 * The Key Controls Area component is a user interface element designed to display a set of key controls, often used for navigation or interaction within an application. It provides a centralized location for users to access important functions or perform specific actions.
 * Each control within the Key Controls Area is represented by an object in the controls array. These objects contain properties such as an identifier (id), display text (text), alignment (leftAligned), and optional features like icons or images.
 * The component also supports event handling through the eventHandler function, allowing developers to define custom actions or behaviors when a control is interacted with.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-key-controls-area',
  templateUrl: 'ind-key-controls-area.html',
  styleUrls:['ind-key-controls-area.scss']
})
export class IndKeyControlsAreaComponent {

  @Input() viewData:IndKeyControlsAreaModel;
  @ViewChild('searchResults') searchResults: ElementRef;
  scrollEnabled: any;
  disbaledLeftArrow: boolean;
  disbledRightArrow: boolean;

  constructor(){
  }


  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchResults.nativeElement.scrollWidth > this.searchResults.nativeElement.clientWidth && this.searchResults.nativeElement.scrollLeft == 0 && this.viewData.isScrollArrowsIconSVisible == true) {
        this.scrollEnabled = true;
        this.disbaledLeftArrow = true;       
      }
    }, 500);
  }
  public onButtonClick(button,event){
    if(button.id && !button.isDisabled && this.viewData.eventHandler){
      if (this.viewData.isScrollArrowsIconSVisible == true) {
        if (this.searchResults.nativeElement.scrollLeft >= 0) {
          this.disbaledLeftArrow = true;
          this.disbledRightArrow = false;
        }
      }
        this.viewData.eventHandler(button.id,event,'controls-click');
    }
  }

  scrollLeft() {
    this.searchResults.nativeElement.scrollLeft -= 150;
    if (this.searchResults.nativeElement.scrollLeft === 0) {
      this.disbaledLeftArrow = true;
      this.disbledRightArrow = false;
    }
  }

  scrollRight() {
    this.searchResults.nativeElement.scrollLeft += 150;
    const isAtRight = Math.ceil( this.searchResults.nativeElement.scrollLeft + this.searchResults.nativeElement.clientWidth) >=  this.searchResults.nativeElement.scrollWidth;
    if (isAtRight) {
      this.disbaledLeftArrow = false;
      this.disbledRightArrow = true;
    }
  }
}
