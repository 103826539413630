import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { EventsService } from './../../../services/events/events.service';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from "@omni/services/activity/activity.service";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { TrackingEventNames, TrackService } from "@omni/services/logging/tracking.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { ChildNavNames, NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { LoadingController, PopoverController, ModalController, IonNav } from "@ionic/angular";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { addMinutes, differenceInCalendarDays, format, isPast, subDays, isBefore, isValid } from "date-fns";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { DeviceService } from '@omni/services/device/device.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { SurgeryOrderActivityDataService } from "@omni/data-services/surgery-order-activity/surgery-order-activity.data.service";
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { Account } from '@omni/classes/account/account.class';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import _ from 'lodash';
import * as moment from 'moment';
import { IONote } from '@omni/classes/io/io-note.class';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { DatePipe } from '@angular/common';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ProcedureTrackerActivityDataService } from '@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { Guid } from 'typescript-guid';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import { AssetSelectionViewDataModel } from '@omni/models/meeting-assets-selection-model';
import { CustomerAsset } from '@omni/classes/field-materials/customer-asset.class';
import { FieldMaterialManagementPageComponent } from '@omni/components/field-material-management/field-material-management-page/field-material-management-page';
import { ActivityOutcome, ActivityOutcomeType } from '@omni/classes/activity/activity.class';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { MarketingPlanSelectionViewDataModel } from '@omni/models/marketing-plan-selection-page-model';
import { MarketingPlanManagementPageComponent } from '@omni/pages/marketing-plan-page/marketing-plan-page';
import { Opportunities, UserTypes } from '@omni/classes/activity/procedure-tracker.activity.class';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { NewActivityComponent } from '@omni/components/activity/new-activity/new-activity';
import { AppointmentActivity, CovisitorAccess } from '@omni/classes/activity/appointment.activity.class';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { CaseManagementService } from '@omni/services/case-management/case-management.service';
import { BarcodeStatus, BarcodescannerService } from '@omni/services/barcodescanner/barcodescanner.service';
import { OmniAlertComponent } from '@omni/components/shared/omni-alert/omni-alert.component';
import { CoachingPlan } from '@omni/classes/coaching/coaching-plan.class';
import { CoachingReportService } from '@omni/services/coaching/coaching.report.service';
import { SurgeryOrderContractDetailsComponent } from '../surgery-order-contract-details/surgery-order-contract-details.component';
import { SurgeryOrderContractComponent } from '../surgery-order-contract/surgery-order-contract.component';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES, ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { MobileAppTemplateType } from '@omni/classes/survey/survey-form.class';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { AlertService } from "@omni/services/alert/alert.service";
import fa from '@mobiscroll/angular/dist/js/i18n/fa';

@Component({
  selector: 'surgery-order-info',
  templateUrl: './surgery-order-info.component.html',
  styleUrls: ['./surgery-order-info.component.scss'],
})
export class SurgeryOrderInfoComponent {
  @Input() from: any;
  @Input() activity: any;
  public currentSurgeryOrderActivity: SurgeryOrderActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = false;
  public isCompleteEnabled = false;
  public surgeryOrderDetailsPanePageTitle: IndPageTitleViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  public quantitiesHeader: IndSectionHeaderViewDataModel;
  public assetsHeader: IndSectionHeaderViewDataModel;
  public statusFormField: IndFormFieldViewDataModel;
  public subjectFormField: IndFormFieldViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;
  public accountFormField: IndFormFieldViewDataModel;
  public surgeryFormField: IndFormFieldViewDataModel;
  public productCategoyFormField: IndFormFieldViewDataModel;
  public productFormField: IndFormFieldViewDataModel;
  public competitorProductFormField: IndFormFieldViewDataModel;
  public OpportunityFormField: IndFormFieldViewDataModel;
  public selectedProductsFormFields: IndFormFieldViewDataModel[];
  public isInMeetingProcedureLogFlow: boolean = false;
  public noProcedureDay: boolean = false;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  public notesAndAttachHeaderModel: IndSectionHeaderViewDataModel;
  public followUpActivityHeader: IndSectionHeaderViewDataModel;
  public notesPlaceholder: string = '';
  public isSaveNotesEnabled: boolean = false;
  public tempNoteText: string = '';
  public cancellationReasonFormField: IndFormFieldViewDataModel;
  public cancellationReasonOtherFormField: IndFormFieldViewDataModel;
  public multipleOpportunitiesSectionTitle: IndSectionHeaderViewDataModel;

  @ViewChild('attachInput') attachInput: ElementRef;
  public annotationId: string = '';
  public attachmentTitle: string = '';
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;

  // date time picker
  public startTime: string;
  public endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private endTimeValue: string;
  private duration: string;
  public minStartDate: string;
  private ngDestroy$ = new Subject<boolean>();
  dateFormatSubscription: Subscription;
  public backgroundUploadInProgress: boolean = false;
  public globalCustomerText: string;
  // mandatory field
  private _isClickedCustomer: boolean = false;
  private _isClickedAccount: boolean = false;
  private _isClickedSurgery: boolean = false;
  private _isClickedProductCategory: boolean = false;
  private _isClickedProduct: boolean = false;
  private shoreiIsMandatory: boolean = false;
  public isWeeklyFlag: boolean = false;
  public hasTagAssets: boolean = false;
  public taggedAssets: [] = [];
  public isSurveyConfigured: boolean = false;
  public procedureSurveyHeaderModel: IndSectionHeaderViewDataModel;
  public surveyLookupResults: Array<any> = [];
  public surveyTemplate: any;
  private isSurveyValid: boolean = false;
  private _surveyJsAttributesFetched: boolean = false;
  public ownerFormField: IndFormFieldViewDataModel;
  public coOwnersFormField: IndFormFieldViewDataModel;
  public lastUpdatedByUserFormField: IndFormFieldViewDataModel;
  public lastUpdatedAtFormField: IndFormFieldViewDataModel;
  public activityOutcomeFormField: IndFormFieldViewDataModel;
  public _hasTagAssetsSurveyJS: boolean = false;
  private _surveyTemplateLoaded: boolean = false;
  private isLastUpdatedByAndOnEnabled: boolean = false;
  private activityOutcomeOptions: Array<ActivityOutcome> = [];
  private activityOutcomeisMandatory: boolean = false;
  private activityOutcomePopover: any;
  public applicableOpportunities: Opportunity[] = [];
  public multipleApplicableOpportunities: Opportunity[] = [];
  public marketBusinessPlanFormField: IndFormFieldViewDataModel;
  public coachingPlanFormField: IndFormFieldViewDataModel;
  private allCompetitorProducts: Array<Brand> = [];
  public allModelActivities: MainCardViewDataModel[];
  public applicableMeetings: AppointmentActivity[] = [];
  tabsData: IndTabsDataModel[] = [
    {
      displayText: this.translate.instant('INFO'),
      value: 'info'
    },
    {
      displayText: this.translate.instant('OCE_TRACKING'),
      value: 'oceTracking',
      disable: false,
    }
  ];
  public surgeryOrderDetailSegment: string = this.tabsData[0].value;
  private isCompetitorProductsEnabled: boolean = false;
  private selectedProductCateogries = [];
  private productsMappedToselectedProductCateogries = [];
  private isMarketingBusinessPlanEnabled: boolean = false;
  private allCoachingPlans: Array<CoachingPlan> = [];
  public procedureContractSectionHeader: IndSectionHeaderViewDataModel;
  public procedureTypeFormField: IndFormFieldViewDataModel;
  public shoreiMasterFormField: IndFormFieldViewDataModel;
  public procedureSubTypeFormField: IndFormFieldViewDataModel;

  public procedureTypes: any;
  public procedureSubTypes: any;
  public procedureContractEnabled = false;
  public procedureContractField: IndFormFieldViewDataModel;
  public contractDetailsHeader: IndPageTitleViewDataModel;
  public proceduresAllocated: IndFormFieldViewDataModel;
  public contractStartDateField: IndDateTimeFormViewDataModel;
  public contactEndDateField: IndDateTimeFormViewDataModel;
  public procedureContractData: any;
  public proceduresUsed: IndFormFieldViewDataModel;
  public proceduresAvailables: IndFormFieldViewDataModel;

  public isCoowner = false;
  public coVisitorsFormField: IndFormFieldViewDataModel;
  public hideProcedureAndPorductCategory = false;
  public isCovisitorEnabled = false;
  public isCovisitor = false;
  public covisitorAccess;
  public isOwner = false;

  public hideQunatityField = false;
  public hideFollowUpActivityField = false;

  public noteSectionEnabled = false;
  public noteAndAttachmentEnabled = false;
  public attachmentSectionEnabled = false;
  public isMeetingAttachmentEnabled = true;
  public meetingNoteAttachmentTitle: string = '';
  public isMeetingAttachmentAdded: boolean = false;
  public coVisitorAccess;
  public noteDisabled: boolean = false;
  public noteAndAttachSection: boolean = false;
  public noteSectionOnly: boolean = false;
  public isHideOpportunity: boolean = false;
  public isCancelEnabled = false;
  private contractFieldsReadOnly = false;
  public isAssetBooking = false;
  public meetingOpportunitiesSectionId: string = 'meetingOpportunitiesSectionId'
  public associateMultipleOpportunities: boolean = false;
  public opportunityDynamicUrl: string;
  public openOpportunityDynamic: boolean = false;
  public  isProcedureSubtypeAvailable = false;

  constructor(
    private readonly authService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly accountService: AccountOfflineService,
    private readonly agendaFooterService: AgendaFooterService,
    public activityService: ActivityService,
    public translate: TranslateService,
    private trackingService: TrackService,
    private _CD: ChangeDetectorRef,
    public navService: NavigationService,
    private popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
    private contactService: ContactOfflineService,
    public device: DeviceService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private events: EventsService,
    private notifications: NotificationService,
    private loadingController: LoadingController,
    public modalCtrl: ModalController,
    public navCtrl: IonNav,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    public readonly surveyService: SurveyService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public opportunityService: OpportunityManagementService,
    private readonly activityDataService: ActivityDataService,
    private readonly caseManagementService: CaseManagementService,
    private barcodescannerService: BarcodescannerService,
    public coachingReportService: CoachingReportService,
    private contractService: ProcedureContractService,
    private brandService: BrandOfflineService,
    private secondaryInfoService: SecInfoConfigDataService,
    private notificationService: NotificationService,
    private dynamics: DynamicsClientService,
    private fileService: IoFileService,
    private alertService: AlertService
  ) {
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe(df => {
      if (df) {
        this.initDateTimeFormFields();
      }
    });
    this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field: PageName) => {
      this._updateMandatoryField(field);
    });
    this.events.subscribe('reloadSurveyTemplate', () => {
      this.loadSurveyTemplate();
      this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
        this.initFooterViewButtons();
      });
    });
    this.coVisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonprocedurelog'];
    this.surveyService.surveyActiveFor = 'ProcedureLog';
  }

  async ngOnInit() {
    this.isWeeklyFlag = (this.authService.user.procedureLogDuration === 'Weekly');
    this.hasTagAssets = this.authService.user.hasTagAssetsToProcedureLog;
    this.isLastUpdatedByAndOnEnabled = this.authService.user.buSettings && this.authService.user.buSettings.indskr_lastupdatedby;
    this.procedureContractEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT);
    this.hideProcedureAndPorductCategory = this.authService.user.buSettings && this.authService.user.buSettings['indskr_hideprocedureproductcategory'];
    this.isCovisitorEnabled = this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_VISITOR);
    this.noteSectionEnabled = this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_NOTE_ENABLE);
    this.noteAndAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_NOTE_AND_ATTACHMENT_ENABLE);
    this.attachmentSectionEnabled = this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_ATTACHMENT_ENABLE);
    this.hideQunatityField = this.authService.user.buSettings && this.authService.user.buSettings['indskr_hidequantity'];
    this.hideFollowUpActivityField = this.authService.user.buSettings['indskr_hidefollowupactivities'];
    this.isHideOpportunity = this.authService.user.buSettings["indskr_displayopportunity"];
    this.isCancelEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelprocedurelogwithreason'];
    this.isAssetBooking = this.authService.user.buSettings && this.authService.user.buSettings['indskr_assetavailabilitylogic'] == 548910001;
    this.associateMultipleOpportunities = this.authService.user.buSettings['indskr_associatemultipleopportunitiestoprocedure'];
    this.openOpportunityDynamic = this.authService.user.buSettings['indskr_openopportunityindynamics'];
    this.opportunityDynamicUrl = this.authService.user.buSettings['indskr_opportunityurl'];
    console.log(this.authService.user);

    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.surgeryOrderDataService.isInMeetingProcedureLogFlow = true;
      }
      if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.isInMeetingProcedureLogFlow = true;
      } else {
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      }
    }
    this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity
    let x = this.activity;
    if (this.activity) {
      this.currentSurgeryOrderActivity = this.activity;
    }
    if (!_.isEmpty(this.currentSurgeryOrderActivity.opportunities)) {
      this.applicableOpportunities = [];
      this.currentSurgeryOrderActivity.opportunities.forEach(x => {
        if (!x['deleted'] || !x.hasOwnProperty('deleted')) {
          let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(x.indskr_opportunity || x['opportunityId']);
          if (foundOpportunity) {
            this.applicableOpportunities.push(foundOpportunity);
          }
        }
      })
    }


    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }

    this.noProcedureDay = this.currentSurgeryOrderActivity.indskr_noprocedureday;

    let dt = new Date().setHours(0, 0);
    this.minStartDate = format(subDays(dt, this.authService.user.maxMeetingStartDays));

    this.startTime = moment(this.currentSurgeryOrderActivity.scheduledStart).format();
    this.endTime = moment(this.currentSurgeryOrderActivity.scheduledEnd).format();

    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeSurgeryOrder$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
          this.startTime = timeData.time;
          setTimeout(() => {
            this.handleStartDateTimeValue();
          }, 0);
        } else if (timeData.id == DateTimeFieldType.EndTimeField) {
          this.endTime = timeData.time;
          setTimeout(() => {
            this.handleEndDateTimeValue();
          }, 0);
        }
      }
    });
    // init mandatory product field
    if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products && this.currentSurgeryOrderActivity.products.length > 0) {
      this.currentSurgeryOrderActivity.products.forEach(product => {
        if (product.isEmpty) product.isEmpty = false;
      });
    }
    await this._initProcedureLogSurveyJsAttributes();
    this.initAllDataModels();

    this.trackingService.tracking('OrderInfo', TrackingEventNames.SURGERY_ORDER);
    this.initProcedureNotesModel();
    this.initProcedureActivityModel();
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
        if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderDetailsComponent) {
          this._initProcedureLogSurveyJsAttributes();
          this.initFooterViewButtons();
        }
      }
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentSurgeryOrderActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentSurgeryOrderActivity.pendingPushToDynamics;
        this.initFooterViewButtons();
        this.getDetailsHeader();
        this.initProcedureNotesModel();
        this.initProcedureActivityModel();
        this.getSelectedProductsFormField();
      }
    });

    this.barcodescannerService.scanningInProgress = false;

    setTimeout(() => {
      try {
        document.getElementById('maindetailslist').scrollIntoView();
      } catch (error) {

      }
    }, 400);

    this.events.subscribe('surgeryCancelled', (activity) => {
      this.currentSurgeryOrderActivity = activity;
      this.initAllDataModels();
      this.initSurveyData();
    });

    this.setProductsandCategories();
    this.checkNoteDisabled();
  }

  ngDoCheck() {
    if (!_.isEmpty(this.currentSurgeryOrderActivity.opportunities)) {
      this.applicableOpportunities = [];
      this.currentSurgeryOrderActivity.opportunities.forEach(x => {
        if (!x['deleted'] || !x.hasOwnProperty('deleted')) {
          let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(x.indskr_opportunity || x['opportunityId']);
          if (foundOpportunity) {
            this.applicableOpportunities.push(foundOpportunity);
          }
        }
      })
    }
    if (this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated) {
      this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity;
      this.initAllDataModels();
      this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated = false;
      this.initSurveyData();
    }
    if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
      if (this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderDetailsComponent) {
        this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        this.initFooterViewButtons();
      }
    }
    if (this.from && this.from == 'InMeetingProcedure' && this.surgeryOrderDataService.isInMeetingProcedureLogFlow
      && this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.initFooterViewButtons();
    }
  }

  ngAfterContentChecked() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_NOTE_ENABLE)) {
      this.noteSectionOnly = true;
      this.noteAndAttachSection = false;
      this.noteDisabled = false;
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_ATTACHMENT_ENABLE)) {
      this.noteSectionOnly = false;
      this.noteAndAttachSection = true;
      this.noteDisabled = true;
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_NOTE_AND_ATTACHMENT_ENABLE)) {
      this.noteSectionOnly = false;
      this.noteAndAttachSection = true;
      this.noteDisabled = false;
    }
  }

  checkNoteDisabled() {
    // if(this.backgroundUploadInProgress){
    //   this.noteDisabled=true;
    // }
    // else if(this.isCovisitor && (this.coVisitorAccess === 548910001 || this.coVisitorAccess === 548910002)){
    //   this.noteDisabled = false;
    // }
    // else{
    //   this.noteDisabled = true;
    // }
  }

  public get procedureContractType() {
    const contractType = this.surgeryOrderDataService.findContractTypeByProcedureTypeId(this.currentSurgeryOrderActivity.indskr_proceduretype);
    if (!contractType) return;
    return contractType.indskr_usagetype;
  }

  public get isReopenButtonEnabled(): boolean {
    return this.surgeryOrderDataService.isReopenButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess,
    });
  }

  public get isScrapButtonEnabled(): boolean {
    return this.surgeryOrderDataService.isScrapButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess
    });
  }

  public get isCompleteButtonEnabled(): boolean {
    return this.surgeryOrderDataService.isCompleteButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess,
      noProcedureDay: this.noProcedureDay,
      // procedureContractType: this.procedureContractType,
      activityOutcomeisMandatory: this.activityOutcomeisMandatory,
      shoreiIsMandatory: this.shoreiIsMandatory
    });
  }

  public get procedureContractText() {
    this.procedureContractData = this.contractService.findProcedureContractById(this.currentSurgeryOrderActivity.indskr_procedurecontract);
    if (!this.procedureContractData && this.currentSurgeryOrderActivity.indskr_procedurecontract) return this.currentSurgeryOrderActivity.indskr_procedurecontractname;
    if (!this.procedureContractData) return '';
    return this.procedureContractData.indskr_name;
  }

  public get procedureTypeLabel() {
    return (this.currentSurgeryOrderActivity.indskr_proceduretype && this.surgeryOrderDataService.procedureTypes.length) ? this.surgeryOrderDataService.findProcedureTypeById(this.currentSurgeryOrderActivity.indskr_proceduretype).indskr_name : '';
  }

  public get shoreiMasterLabel() {
    return (this.currentSurgeryOrderActivity.jnjjapan_shorei && this.surgeryOrderDataService.shoreiMasterData.length) ? (this.surgeryOrderDataService.findShoreiMasterDataById(this.currentSurgeryOrderActivity.jnjjapan_shorei) ? this.surgeryOrderDataService.findShoreiMasterDataById(this.currentSurgeryOrderActivity.jnjjapan_shorei).jnjjapan_shoreiname : '') : '';
  }

  public get procedureSubTypeLabel() {
    return (this.currentSurgeryOrderActivity.indskr_proceduresubtype && this.surgeryOrderDataService.procedureSubTypes.length) ? this.surgeryOrderDataService.procedureSubTypes.find((procedureType) => procedureType.indskr_proceduresubtypeid === this.currentSurgeryOrderActivity.indskr_proceduresubtype).indskr_name : '';
  }

  private get _currentCustomerString(): string {
    let str = '';
    const currentCustomers = this.currentSurgeryOrderActivity.customers
    if (currentCustomers) {
      if (currentCustomers.length === 1) {
        str = `${this.secondaryInfoService.getFullNameForContactDisplaySequence(currentCustomers[0])}`;
        if (currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000) {
          str += ' (Inactive)';
        }
      } else if (currentCustomers.length >= 2) {
        currentCustomers.sort((a, b): number => {
          if (a.fullname > b.fullname) return 1;
          if (a.fullname < b.fullname) return -1;
          return 0;
        });
        let frstStr = '';
        let scndStr = '';
        if (currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000) {
          frstStr = ' (Inactive)';
        } else if (currentCustomers.some(a => a.statusCode !== 1 && a.statusCode !== 548910000)) {
          scndStr = ' (Inactive)';
        }
        str = `${this.secondaryInfoService.getFullNameForContactDisplaySequence(currentCustomers[0])}${frstStr} +${currentCustomers.length - 1}${scndStr}`;
      }
    }
    return str;
  }

  private get _currentProductsString(): string {
    let str = '';
    let currentProducts = this.currentSurgeryOrderActivity.products.filter(a => !a.isDeleted);
    if (currentProducts) {
      if (currentProducts.length === 1) {
        str = `${currentProducts[0].productName}`;
      } else if (currentProducts.length >= 2) {
        currentProducts.sort((a, b): number => {
          if (a.productName > b.productName) return 1;
          if (a.productName < b.productName) return -1;
          return 0;
        });
        str = `${currentProducts[0].productName} +${currentProducts.length - 1}`;
      }
    }
    return str;
  }

  private get _currentProductCategoriesString(): string {
    let str = '';
    let currentProducts = this.currentSurgeryOrderActivity.productCategory;
    if (currentProducts) {
      if (currentProducts.length === 1) {
        str = `${currentProducts[0].productCategoryName}`;
      } else if (currentProducts.length >= 2) {
        currentProducts.sort((a, b): number => {
          if (a.productCategoryName > b.productCategoryName) return 1;
          if (a.productCategoryName < b.productCategoryName) return -1;
          return 0;
        });
        str = `${currentProducts[0].productCategoryName} +${currentProducts.length - 1}`;
      }
    }
    return str;
  }

  private get _currentCompetitorProductsString(): string {
    let str = '';
    let currentCompetitorProducts = this.currentSurgeryOrderActivity.competitorProducts.filter(a => !a.isDeleted);
    if (currentCompetitorProducts) {
      if (currentCompetitorProducts.length === 1) {
        str = `${currentCompetitorProducts[0].productName}`;
      } else if (currentCompetitorProducts.length >= 2) {
        currentCompetitorProducts.sort((a, b): number => {
          if (a.productName > b.productName) return 1;
          if (a.productName < b.productName) return -1;
          return 0;
        });
        str = `${currentCompetitorProducts[0].productName} +${currentCompetitorProducts.length - 1}`;
      }
    }
    return str;
  }

  initAllDataModels() {
    this.activityOutcomeOptions = this.surgeryOrderDataService.activityOutcomes.filter(a => a.indskr_omnipresenceactivity == ActivityOutcomeType.ProcedureLog);
    this.activityOutcomeisMandatory = this.activityOutcomeOptions.some(a => a.indskr_mandatory);
    this.isCompetitorProductsEnabled = this.authService.user.buConfigs['indskr_competitorproduct'];
    this.isCovisitor = this.currentSurgeryOrderActivity.coVisitors?.some((coVisitors) => coVisitors.userId === this.authService.user.systemUserID);
    this.covisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonprocedurelog'];
    this.isCoowner = this.currentSurgeryOrderActivity.coOwners?.some((coOwner) => coOwner.userId === this.authService.user.systemUserID);
    this.isOwner = this.currentSurgeryOrderActivity.ownerId === this.authService.user.xSystemUserID;
    const procedureSubTypes = this.surgeryOrderDataService.findAssociatedProcedureSubtypes(this.currentSurgeryOrderActivity.indskr_proceduretype);
    this.isProcedureSubtypeAvailable = procedureSubTypes.length > 0;

    /////
    if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
      const contractType = this.surgeryOrderDataService.findContractTypeByProcedureTypeId(this.currentSurgeryOrderActivity.indskr_proceduretype);
      if(contractType) {
        this.contractFieldsReadOnly = this.contractService.shouldContractFieldsReadonly(contractType, this.currentSurgeryOrderActivity.contractStatus);
      }
    }

    if (this.isCompetitorProductsEnabled) {
      this.allCompetitorProducts = this.caseManagementService.caseProductSKU.filter(p => p.isCompetitorProduct && p.isCompetitorProduct == 'true');
    }

    this.isMarketingBusinessPlanEnabled = this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_displaymarketingbusinessplanonprocedurelo'];
    // this.initFooterViewButtons();
    this.isReadOnlyMode = !this.isOwner && !this.isCoowner &&
      (this.isCovisitor && (this.covisitorAccess == CovisitorAccess.READ_ACCESS || this.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS))
      || this.currentSurgeryOrderActivity.status === 548910001 || this.currentSurgeryOrderActivity.status === 548910005;

    if (!this.hideQunatityField) {
      this.getQuantitiesHeader();
      this.getSelectedProductsFormField();
    }

    this.getDetailsHeader();
    this.getAssetsHeader()
    this.getStatusFormField();
    this.getSubjectFormField();
    this.getCustomerFormField();
    this.getAccountFormField();
    this.getCoOwnersFormField();
    this.getOwnerFormField();
    this.getLastUpdatedByUserFormField();
    this.getLastUpdatedAtFormField()
    this.getActivityOutcomeFormField();
    this.getSurgeryFormField();
    this.getProductCategoryFormField();
    this.getProductFormField();
    this.getCompetitorProductFormField();
    this.getOpportunityFormField();
    this.multipleOpportunitiesSectionHeader();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    this.initProcedureNotesModel();
    this.initProcedureNotesAndAttachModel();
    this.initProcedureActivityModel();
    this.initProcedureContractModel();
    this.getCancellationReasonsFormField();
    this.getCancellationReasonsOtherFormField();

    this.getCoVisitorsFormField();

    if (this.authService.user.buConfigs['indskr_proceduretype']) {
      this.getProcedureTypeFormField();
    }

    if (this.authService.user.buSettings['indskr_displayshorei']) {
      this.shoreiIsMandatory = true;
      this.getShoreiMasterFormField();
    }

    if (this.procedureContractEnabled) {
      this.getProcedureContractFormField();
      this.getnumberOfAssistanceFormField();
      this.getcontractStartDateField();
      this.getcontractEndDateField();
      this.getContractDetailsHeader();
      this.getProceduresUsedFormField();
      this.getProceduresAvailableFormField();
    }

    if (this.authService.user.buConfigs['indskr_proceduresubtype'] && this.isProcedureSubtypeAvailable) {
      this.getProcedureSubTypeFormField();
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) {
      this.getMarketingBusinessPlanField();
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN)) {
      this.getCoachingPlanField();
      this.allCoachingPlans = this.coachingReportService.coachingPlans;
    }
  }

  public setProductsandCategories() {
    if (this.currentSurgeryOrderActivity.surgeryId) {
      this.selectedProductCateogries = this.surgeryOrderDataService.productHierarchies
        .find((productHierarchy) => productHierarchy.surgeryId === this.currentSurgeryOrderActivity.surgeryId).productCategories
        .filter(c => this.currentSurgeryOrderActivity.productCategory.some(o => o.productCategoryId == c.productCategoryId));

      this.selectedProductCateogries.forEach(productCategory => this.productsMappedToselectedProductCateogries = this.productsMappedToselectedProductCateogries.concat(productCategory.products));
    }
  }

  public getDetailsHeader() {
    let buttons = [];
    if (this.isInMeetingProcedureLogFlow) {
      buttons.push(
        {
          id: "reopensurgeryactivity",
          text: this.translate.instant('REOPEN'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        },
        {
          id: 'scrapsurgeryorderactivity',
          text: this.isCancelEnabled ? this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER') : this.translate.instant('SCRAP'),
          isDisabled: this.backgroundUploadInProgress ? true : this.currentSurgeryOrderActivity.status == 54891000 || !this.isScrapButtonEnabled,
        },
        {
          id: 'markcomplete',
          text: this.translate.instant('COMPLETE'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        });
    }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: buttons,
    };
  }

  public getQuantitiesHeader() {

    this.quantitiesHeader = {
      id: 'quantities-header',
      title: this.translate.instant('QUANTITIES'),
      controls: []
    };
  }

  public getAssetsHeader() {
    this.assetsHeader = {
      id: 'assets-header',
      title: this.translate.instant('ASSETS'),
      controls: [{
        id: "tagassets",
        text: this.translate.instant('ADD'),
        isDisabled: this.backgroundUploadInProgress ? true : (this.currentSurgeryOrderActivity.surgeryOrderStatusString != 'Draft' ||
          (!this.isOwner && !this.isCoowner || (!this.isOwner && !this.isCoowner && this.isCovisitor && this.covisitorAccess !== CovisitorAccess.FULL_ACCESS))
          || (this.isAssetBooking && this.currentSurgeryOrderActivity.assets && this.currentSurgeryOrderActivity.assets.length > 0)
          || (this.isAssetBooking && this.device.isOffline)
          || (this.isAssetBooking && !!this.currentSurgeryOrderActivity.indskr_assetbookingid)),
      }]
    };
  }

  private initFooterViewButtons(): boolean {
    if (!this.currentSurgeryOrderActivity) return;
    if (this.isInMeetingProcedureLogFlow) {
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    }
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.subscribe("surgeryOrder:footerEvents", (data) => {
      if (data) {
        this.handleButtonClick(data);
        data = null;
      }
    });
    switch (this.currentSurgeryOrderActivity.surgeryOrderStatusString) {
      case 'Draft':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = [];
          const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
          if (!this.isCompleteButtonEnabled || this.backgroundUploadInProgress || (selectedSurgery && selectedSurgery.surveyTemplateId && !this.isSurveyValid)) btnsArr.push('markCompleteSurgeryOrder');
          if (!this.isScrapButtonEnabled || this.backgroundUploadInProgress) {
            if (this.isCancelEnabled) {
              btnsArr.push('scraporcancelSurgeryOrderActivity')
            } else {
              btnsArr.push('scrapSurgeryOrderActivity');
            }
          }
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          if (this.device.isOffline || (!this.isOwner && !this.isCoowner && this.isCovisitor && this.covisitorAccess !== CovisitorAccess.FULL_ACCESS) && this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG)) btnsArr.push('assignOwnersurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = false;
        break;
      case 'Completed':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = ['markCompleteSurgeryOrder'];
          if (this.isCancelEnabled) {
            btnsArr.push('scraporcancelSurgeryOrderActivity')
          } else {
            btnsArr.push('scrapSurgeryOrderActivity');
          }
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          if (this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG)) btnsArr.push('assignOwnersurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      case 'Canceled':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = ['markCompleteSurgeryOrder', 'reopensurgeryactivity', 'assignOwnersurgeryactivity'];
          if (this.isCancelEnabled) {
            btnsArr.push('scraporcancelSurgeryOrderActivity')
          } else {
            btnsArr.push('scrapSurgeryOrderActivity');
          }
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      default:
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        }
        break;
    }
    if ((!this.isOwner && !this.isCoowner && !this.isCovisitor)
      || this.from === PageName.AccountPlanActivities || this.from === 'MarketingBusinessPlanTimeline') {
      this.isReadOnlyMode = true;
    }

    if (this.from === PageName.AccountPlanActivities) {
      let btnsArr = ['markCompleteSurgeryOrder', 'reopensurgeryactivity', 'assignOwnersurgeryactivity'];
      if (this.isCancelEnabled) {
        btnsArr.push('scraporcancelSurgeryOrderActivity')
      } else {
        btnsArr.push('scrapSurgeryOrderActivity');
      }
      this.footerService.disableButton(btnsArr);
    }
  }

  private updateFooterViewButtons(): boolean {
    if (!this.currentSurgeryOrderActivity) return;
    switch (this.currentSurgeryOrderActivity.surgeryOrderStatusString) {
      case 'Draft':
        this.isReadOnlyMode = false;
        break;
      case 'Completed':
        this.isReadOnlyMode = true;
        break;
      case 'Canceled':
        this.isReadOnlyMode = true;
        break;
      default:
        break;
    }
  }

  private setStatusString(){
    let str:string = '';
    switch(this.currentSurgeryOrderActivity.status){
        case 100001:
        case 548910001:
            str = this.translate.instant('COMPLETED');
            break;
        case 548910005:
          str = this.translate.instant('CANCELLED');
          break;
        default:
            str = this.translate.instant('OM_DRAFT');
    }
    return str;
  }

  public getStatusFormField() {
    const statusString = this.setStatusString();
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: statusString + ((this.currentSurgeryOrderActivity.pendingPushToDynamics) ? ' - Pending Sync' : ''),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.statusFormField = viewData;
  }

  public getSubjectFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: this.currentSurgeryOrderActivity.subject,
      inputValue: this.currentSurgeryOrderActivity.subject,
      placeholderLabel: this.translate.instant('SUBJECT'),
      id: 'subject-field',
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyMode || !this.authService.user.buConfigs['indskr_procedurelogsubjecteditable'] ,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      isHidden: !this.authService.user.buConfigs['indskr_procedurelogsubjecteditable'],
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.subjectFormField = viewData;
  }

  private initDateTimeFormFields() {
    let dateTimeValue = this.getSurgeryOrderTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTimeValue = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || (this.device.isOffline && this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0),
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isWeeklyFlag ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: (this.isReadOnlyMode || this.isWeeklyFlag || (this.device.isOffline && this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0)),
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: (this.backgroundUploadInProgress || this.isWeeklyFlag) ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
  }

  private getSurgeryOrderTimeText = (): any => {
    if (!this.currentSurgeryOrderActivity) return;
    let startDateTimeValue: Date = new Date(this.currentSurgeryOrderActivity.scheduledStart);
    let endDateTimeValue: Date = new Date(this.currentSurgeryOrderActivity.scheduledEnd);
    let startDayValue = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let endDayValue = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let startTimeValue = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let endTimeValue = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let formattedDuration: string = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

    let dateTimeValue: any = {
      startDateTime: '',
      startDate: '',
      startTime: '',
      endDateTime: '',
      endDate: '',
      endTime: '',
      duration: '',
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDayValue,
      startTime: startTimeValue,
      endDateTime: endDateTimeValue,
      endDate: endDayValue,
      endTime: endTimeValue,
      duration: formattedDuration,
    };
    return dateTimeValue;
  }

  public getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: this.currentSurgeryOrderActivity.customers.length != 0 ? this._currentCustomerString : '',
      customPlaceholderLabel: (this.isReadOnlyMode && this.currentSurgeryOrderActivity.customers.length == 0) ? this.translate.instant('NO_CUSTOMER') : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedCustomer && this.currentSurgeryOrderActivity.customers && this.currentSurgeryOrderActivity.customers.length == 0 ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.customerFormField = viewData;
  }

  public getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: (this.currentSurgeryOrderActivity.accountNameString) ? this.currentSurgeryOrderActivity.accountNameString + (this.currentSurgeryOrderActivity.isAccountActive ? '' : ' (Inactive)') : '',
      placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.accountId) ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || this.contractFieldsReadOnly,
      showArrow: !this.isReadOnlyMode && !this.contractFieldsReadOnly,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedAccount && !this.currentSurgeryOrderActivity.accountId ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.accountFormField = viewData;

    if (this.currentSurgeryOrderActivity.accountId) {
      const selectedAccount = this.accountService.accounts.find(obj => obj.id == this.currentSurgeryOrderActivity.accountId)
      if (selectedAccount && selectedAccount['raw']['indskr_accounttype_Formatted']) {
        this.secondaryInfoService.accounttypeString = selectedAccount['raw']['indskr_accounttype_Formatted'];
      }
    }
  }

  public getMarketingBusinessPlanField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('MARKETING_BUSINESS_PLAN'),
      inputText: this.currentSurgeryOrderActivity.marketingBusinessPlanId ? this.currentSurgeryOrderActivity.marketingBusinessPlanName : '',
      placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.accountId) ? this.translate.instant('NO_MARKETING_PLAN_ASSOCIATED') : this.translate.instant('MARKETING_BUSINESS_PLAN'),
      id: 'marketing-business-plan-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      isHidden: !this.isMarketingBusinessPlanEnabled,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.marketBusinessPlanFormField = viewData
  }

  public getCoachingPlanField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('COACHING_PLAN'),
      inputText: this.currentSurgeryOrderActivity.indskr_coachingplan ? this.currentSurgeryOrderActivity.indskr_coachingplanname : '',
      placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.accountId) ? this.translate.instant('NO_COACHING_PLAN') : this.translate.instant('COACHING_PLAN'),
      id: 'coaching-plan-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.coachingPlanFormField = viewData
  }

  public getOwnerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('OM_OWNER'),
      inputText: this.currentSurgeryOrderActivity.ownerNameString,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.ownerFormField = viewData;
  }

  public getCoOwnersFormField() {
    const userNames = _.orderBy(this.currentSurgeryOrderActivity.coOwners?.filter(coOwner => coOwner['deleted'] != true)).map(coOwner => coOwner.userFullName);
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CO_OWNERS'),
      inputText: _.isEmpty(userNames) ? '' : userNames[0] + (userNames.length > 1 ? ' +' + (userNames.length - 1) : ''),
      customPlaceholderLabel: _.isEmpty(userNames) ? this.translate.instant('SELECT_CO_OWNERS') : this.translate.instant("NO_CO_OWNERS"),
      id: 'co-owner-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isHidden: _.isEmpty(userNames) && !this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_OWNER),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.coOwnersFormField = viewData;
  }

  public getCoVisitorsFormField() {
    const userNames = _.orderBy(this.currentSurgeryOrderActivity.coVisitors?.filter(coOwner => coOwner['deleted'] != true)).map(coVisitors => coVisitors.userFullName);
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
      inputText: _.isEmpty(userNames) ? '' : userNames[0] + (userNames.length > 1 ? ' +' + (userNames.length - 1) : ''),
      customPlaceholderLabel: _.isEmpty(userNames) ? this.translate.instant('ACTIVITY_DETAILS_SELECT_COVISITORS') : this.translate.instant("ACTIVITY_DETAILS_NO_COVISITORS"),
      id: 'co-visitor-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isHidden: !this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_VISITOR),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.coVisitorsFormField = viewData;
  }

  public getLastUpdatedByUserFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('LAST_UPDATED_BY_USER'),
      inputText: this.currentSurgeryOrderActivity.modifiedBy,
      id: 'last-updated-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isHidden: !this.isLastUpdatedByAndOnEnabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.lastUpdatedByUserFormField = viewData;
  }

  public getLastUpdatedAtFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('LAST_UPDATED_AT'),
      inputText: this.currentSurgeryOrderActivity.modifiedOn ? format(this.currentSurgeryOrderActivity.modifiedOn, this.dateTimeFormatsService.dateTimeToUpper) : '',
      id: 'last-updated-at-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isHidden: !this.isLastUpdatedByAndOnEnabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.lastUpdatedAtFormField = viewData;
  }

  public getActivityOutcomeFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACTIVITY_OUTCOME'),
      inputText: this.currentSurgeryOrderActivity.activityOutcomeString,
      customPlaceholderLabel: this.activityOutcomeOptions.length == 0 ? this.translate.instant('NO_ACTIVITY_OUTCOME') : this.translate.instant('SELECT_ACTIVITY_OUTCOME'),
      id: 'activity-outcome-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: this.activityOutcomeisMandatory,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isHidden: ((!this.isReadOnlyMode && this.activityOutcomeOptions.length == 0) || (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.activityOutcomeId)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.activityOutcomeFormField = viewData;
  }

  public getProcedureTypeFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isProcedureTypeExists = (this.surgeryOrderDataService.procedureTypes.length && this.currentSurgeryOrderActivity.state === 0);
    viewData = {
      label: this.translate.instant('TYPE'),
      inputText: this.procedureTypeLabel,
      customPlaceholderLabel: isProcedureTypeExists ? this.translate.instant('SELECT_PROCEDURE_TYPE') : this.translate.instant('NO_PROCEDURE_TYPE'),
      id: 'procedure-type-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || this.contractFieldsReadOnly,
      showArrow: !this.isReadOnlyMode && !this.contractFieldsReadOnly,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.procedureTypeFormField = viewData;

    if (this.procedureTypeLabel) {
      this.secondaryInfoService.proceduretypeTitle = this.procedureTypeLabel;
    }
  }

  public getShoreiMasterFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isShoreiMasterDataExists = (this.surgeryOrderDataService.shoreiMasterData.length);
    viewData = {
      label: this.translate.instant('SHOREI'),
      inputText: this.shoreiMasterLabel,
      customPlaceholderLabel: isShoreiMasterDataExists ? this.translate.instant('SELECT_SHOREI') : this.translate.instant('NO_SHOREI'),
      id: 'procedure-shorei-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || this.contractFieldsReadOnly,
      showArrow: !this.isReadOnlyMode && !this.contractFieldsReadOnly,
      isRequired: this.shoreiIsMandatory,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.shoreiMasterFormField = viewData;
  }

  public getProcedureSubTypeFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isProcedureSubTypeExists = (this.surgeryOrderDataService.procedureSubTypes.length && this.currentSurgeryOrderActivity.state === 0);
    viewData = {
      label: this.translate.instant('SUB_TYPE'),
      inputText: this.currentSurgeryOrderActivity.indskr_proceduresubtypename,
      customPlaceholderLabel: isProcedureSubTypeExists ? this.translate.instant('SELECT_PROCEDURE_SUB_TYPE') : this.translate.instant('NO_PROCEDURE_SUBTYPE'),
      id: 'procedure-sub-type-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.procedureSubTypeFormField = viewData;
  }

  public getSurgeryFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText: this.currentSurgeryOrderActivity.surgeryNameString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PROCEDURE') : this.translate.instant('SELECT_PROCEDURE'),
      id: 'surgery-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable,
      showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable),
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedSurgery && !this.currentSurgeryOrderActivity.surgeryNameString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isHidden: this.hideProcedureAndPorductCategory,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.surgeryFormField = viewData;
  }

  public getProductCategoryFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PRODUCT_CATEGORY'),
      inputText: this._currentProductCategoriesString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCT_CATEGORY') : this.translate.instant('SELECT_PRODUCT_CATEGORY'),
      id: 'product-category-field',
      isReadOnly: true,
      isDisabled: !isSurgeryDataAvailable || !this.currentSurgeryOrderActivity.surgeryId,
      showArrow: isSurgeryDataAvailable && !!this.currentSurgeryOrderActivity.surgeryId,
      isRequired: !this.isReadOnlyMode && isSurgeryDataAvailable && !!this.currentSurgeryOrderActivity.surgeryId,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedProductCategory && this.currentSurgeryOrderActivity.surgeryId && !this._currentProductCategoriesString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isHidden: this.hideProcedureAndPorductCategory,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.productCategoyFormField = viewData;
  }

  public getProductFormField() {
    let viewData: IndFormFieldViewDataModel;
    let isSurgeryDataAvailable = true;
    let isProductCategorySelected = true;
    if (!this.hideProcedureAndPorductCategory) { // surgery and category check skip, when this flag is enabled
      isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0;
      isProductCategorySelected = this.currentSurgeryOrderActivity.productCategory.length > 0;
    }
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: this._currentProductsString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCTS') : this.translate.instant('SELECT_PRODUCTS'),
      id: 'product-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable || !isProductCategorySelected,
      showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable && isProductCategorySelected),
      isRequired: !this.isReadOnlyMode && isSurgeryDataAvailable && isProductCategorySelected && !this.hideProcedureAndPorductCategory,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedProduct && this.currentSurgeryOrderActivity.productCategory && this.currentSurgeryOrderActivity.productCategory.length > 0 && !this._currentProductsString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    if (this.authService.hasFeatureAction(FeatureActionsMap.SCAN_PRODUCTS_PROCEDURE_LOG)) {
      viewData = {
        ...viewData,
        additionalcontrols: [{
          id: 'scanIcon',
          src: 'assets/imgs/barcode.svg',
          disabled: this.isReadOnlyMode || !isSurgeryDataAvailable
        }]
      }
    }

    this.productFormField = viewData;
  }

  public getProcedureContractFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_IN_PROCEDURE_LOG'),
      inputText: this.procedureContractText,
      placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.indskr_procedurecontract) ? this.translate.instant('NO_PROCEDURE_CONTRACT') : this.translate.instant('PROCEDURE_CONTRACTS'),
      id: 'procedure-contract-field',
      isReadOnly: true,
      isDisabled: this.device.isOffline || this.isReadOnlyMode || !this.contractService.areProcedureContractFieldsFilled(this.currentSurgeryOrderActivity) || this.contractFieldsReadOnly,
      showArrow: !this.device.isOffline && !this.isReadOnlyMode && this.contractService.areProcedureContractFieldsFilled(this.currentSurgeryOrderActivity) && !this.contractFieldsReadOnly,
      isRequired: !this.isReadOnlyMode,
      isHidden: !this.surgeryOrderDataService.shouldShowProcedureContract(this.currentSurgeryOrderActivity),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.procedureContractField = viewData;
  }

  getContractDetailsHeader() {
    this.contractDetailsHeader = {
      id: 'contract-details-header',
      title: this.translate.instant('CONTRACT_DETAILS'),
      controls: []
    };
  }

  public getnumberOfAssistanceFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_ALLOCATED'),
      inputText: this.currentSurgeryOrderActivity.maximumnoofassistance + '',
      placeholderLabel: this.translate.instant('NO_PROCEDURES_ALLOCATED'),
      id: 'assistance-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.proceduresAllocated = viewData
  }

  public getProceduresUsedFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_USED'),
      inputText: this.currentSurgeryOrderActivity.noofassistanceavailed + '',
      placeholderLabel: this.translate.instant('NO_PROCEDURES_USED'),
      id: 'procedures-used-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.proceduresUsed = viewData
  }

  public getProceduresAvailableFormField() {
    let contractAvailabe = this.currentSurgeryOrderActivity.maximumnoofassistance
    let contractUsed = this.currentSurgeryOrderActivity.noofassistanceavailed
    let proceduresContractAvailables = contractAvailabe - contractUsed;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_AVAILABLE'),
      inputText: proceduresContractAvailables + '',
      placeholderLabel: this.translate.instant('NO_PROCEDURES_AVAILABLE'),
      id: 'procedures-available-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.proceduresAvailables = viewData
  }

  public getcontractStartDateField() {
    let contractStartDayValue = this.datePipe.transform(this.currentSurgeryOrderActivity.contractStartDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('START_DATE'),
      inputText: contractStartDayValue,
      placeholderLabel: this.translate.instant('No Date'),
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.contractStartDateField = viewData
  }

  public getcontractEndDateField() {
    let contractEndDayValue = this.datePipe.transform(this.currentSurgeryOrderActivity.contractEndDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('END_DATE'),
      inputText: contractEndDayValue,
      placeholderLabel: this.translate.instant('No Date'),
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.contactEndDateField = viewData
  }

  async scanProduct() {
    if (this.currentSurgeryOrderActivity.productCategory.length === 0) {
      this.notifications.notify(this.translate.instant('PLEASE_SELECT_PRODUCT_CATEGORY'), 'Procuedre', 'top', ToastStyle.DANGER);
      return;
    }

    const barCodeResponse = await this.barcodescannerService.openBarCodeScanner();

    if (!barCodeResponse || barCodeResponse.status !== BarcodeStatus.SUCCESS) {
      if (barCodeResponse.status === BarcodeStatus.ERROR_OCCURED) {
        this.notifications.notify(this.translate.instant('NOT_ABLE_TO_READ_BARCODE'), 'Procuedre', 'top', ToastStyle.DANGER);
      }
      return;
    }
    this.addProductToProcedureLog([barCodeResponse.data]);
  }

  public getCompetitorProductFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !this.isCompetitorProductsEnabled,
      label: this.translate.instant('COMPETITOR_PRODUCTS'),
      inputText: this._currentCompetitorProductsString,
      customPlaceholderLabel: !this.allCompetitorProducts ? this.translate.instant('NO_COMPETITOR_PRODUCTS') : this.translate.instant('SELECT_COMPETITOR_PRODUCTS'),
      id: 'competitor-products-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !this.allCompetitorProducts.length,
      showArrow: (!this.isReadOnlyMode && !!this.allCompetitorProducts.length),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.competitorProductFormField = viewData;
  }

  public getOpportunityFormField() {
    let viewData: IndFormFieldViewDataModel;
    const opportunities = this.opportunityService.opportunities.filter(opp => opp.opportunityName);
    if (this.applicableOpportunities.length > 0) {
      var opp1 = this.applicableOpportunities[0].opportunityName
    }
    const isOpportunitiesData: boolean = opportunities.length > 0;
    const oppFA = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    viewData = {
      label: this.translate.instant('OPPORTUNITY'),
      // inputText: (this.currentSurgeryOrderActivity.opportunities.length>0) ? opp.opportunityName : '',
      inputText: opp1 ? opp1 : '',
      customPlaceholderLabel: (this.isReadOnlyMode || !isOpportunitiesData) ? this.translate.instant('FOLLOW_UP_NO_OPPORTUNITY') : this.translate.instant('OM_SELECT_OPPORTUNITY'),
      id: 'opportunity-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,// || !oppFA,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if (isOpportunitiesData) {
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    this.OpportunityFormField = viewData;

  }

  public getCancellationReasonsFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CANCELLATION_REASON'),
      inputText: this.currentSurgeryOrderActivity.cancellationreason,
      id: 'cancellation-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.currentSurgeryOrderActivity.cancellationreason
    };
    this.cancellationReasonFormField = viewData;
  }

  public getCancellationReasonsOtherFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CANCELLATION_REASON_OTHER'),
      inputText: this.currentSurgeryOrderActivity.indskr_cancellationreasonother,
      id: 'cancellation-other-field',
      isReadOnly: true,
      isDisabled: true,
      isHidden: !this.currentSurgeryOrderActivity.indskr_cancellationreasonother
    };
    this.cancellationReasonOtherFormField = viewData;
  }

  public getSelectedProductsFormField() {
    this.selectedProductsFormFields = this.currentSurgeryOrderActivity.products.filter(prod => !prod.isDeleted).map((product) => {
      let viewModel: IndFormFieldViewDataModel = {
        label: product.productName + ' - ' + product.productCategoryName,
        inputText: product.quantity ? product.quantity + '' : '0',
        inputType: 'number',
        inputValue: product.quantity ? product.quantity : 0,
        id: product.productId,
        parentID: product.productCategoryID,
        isReadOnly: false,
        isDisabled: this.backgroundUploadInProgress || this.isReadOnlyMode,
        placeholderLabel: this.translate.instant('QUANTITY'),
        showArrow: true,
        isRequired: !this.isReadOnlyMode,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: product.isEmpty,
        formFieldType: FormFieldType.INLINE_INPUT,
        inputValidator: (id: string, value: any) => this._validateQuantityEntered(id, value),
        eventHandler: (id: string, event, eventName, data) => {
          if (eventName === 'input_value_confirm') {
            this.handleQuantityEntered(id, event.target, eventName, data);
          }
        }
      }
      return viewModel;
    });
  }

  private multipleOpportunitiesSectionHeader() {
    this.multipleOpportunitiesSectionTitle = {
      id: 'multipleopportunities',
      title: this.translate.instant('OPPORTUNITIES'),
      controls: [
        {
          id: 'add-opportunities',
          text: this.translate.instant('ADD'),
          isDisabled: this.isReadOnlyMode//!(!this.activityService.selectedActivity.isCothis.isReadOnlyModempleted && !this.isReadOnlyRecommendedMeeting && !this.enableReadonlyViewForMeetings  && this.authService.hasFeatureAction(FeatureActionsMap.ASSOCIATE_OPPORTUNITIES_TO_MEETINGS)) ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled
        }
      ]
    };
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'subject-field':
          if (eventName && eventName === 'input_value_confirm') {
            this.handleEditSubject(event);
          }
          break;
        case 'start-date-field':
          if (this.device.isOffline && this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0) return;
          else
            this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          if (this.device.isOffline && this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0)
            return;
          else
            this.openEndDatePicker(event);
          break;
        case 'customer-field':
          this._isClickedCustomer = true;
          this.openCustomerSelect();
          break;
        case 'account-field':
          this._isClickedAccount = true;
          this.openAccountSelect();
          break;
        case 'surgery-field':
          this._isClickedSurgery = true;
          this._isClickedProductCategory = false;
          this._isClickedProduct = false;
          this.openSurgerySelect();
          break;
        case 'product-category-field':
          this._isClickedProductCategory = true;
          this._isClickedProduct = false;
          this.openProductCategorySelect();
          break;
        case 'product-field':
          this._isClickedProduct = true;
          this.openProductSelect();
          break;
        case 'co-owner-field':
          this.openCoOwnerSelectionPage();
          break;
        case 'activity-outcome-field':
          this.handleActivityOutcomeField(event);
          break;
        case 'marketing-business-plan-field':
          this.openMarketingPlans();
          break;
        case 'coaching-plan-field':
          this.openCoachingPlans();
          break;
        case 'opportunity-field':
          this.handleOpportunityField();
          break;
        case 'competitor-products-field':
          this.handleCompetitorProductSelect();
          break;
        case 'procedure-type-field':
          this.handleProcedureTypesField(event);
          break;
        case 'procedure-sub-type-field':
          this.handleProcedureSubTypesField(event);
          break;
        case 'procedure-contract-field':
          this.openProcedureContract();
          break;
        case 'co-visitor-field':
          this.openCoVisitorSelectionPage();
          break;
        case 'procedure-shorei-field':
          this.handleProcedureShoreiField(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  public async confirmDateChangeWhenAssetsInUse(): Promise<any> {
    let cancelText = this.translate.instant("CANCEL");
    let confirmText = this.translate.instant("CONFIRM");
    return this.alertService.showAlert({
      header: '',
      message: this.translate.instant('PROCEDURE_LOG_WITH_ASSETS_DATE_CHANGE')
    }, confirmText, cancelText)
      .then(res => {
        if (res.role == "ok") {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.error('Error displaying alert:', error);
        return Promise.reject(false); // Reject with false in case of error
      });
  }

  async handleProcedureShoreiField(myEvent) {
    const shoreiMasterData = this.surgeryOrderDataService.shoreiMasterData;
    if (shoreiMasterData && shoreiMasterData.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-shorei',
        isMultipleSelectionEnabled: false,
        data: shoreiMasterData.map(type => {
          return {
            title: type.jnjjapan_shoreiname,
            id: type.jnjjapan_shorei,
            isSelected: type.jnjjapan_shorei == this.currentSurgeryOrderActivity.jnjjapan_shorei,
          }
        }),
      };

      const procedureTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
      });

      await procedureTypePopover.present();

      await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.currentSurgeryOrderActivity.indskr_proceduretype === data.selectedItems[0].id) return;
        this.currentSurgeryOrderActivity.jnjjapan_shorei = data.selectedItems[0].id;
        this.currentSurgeryOrderActivity.jnjjapan_shoreiname = data.selectedItems[0].title;

        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
        if (this.secondaryInfoService.isProcedureLogSecInfoConfigured && !this.currentSurgeryOrderActivity.indskr_subjectedited) {
          const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
          this.currentSurgeryOrderActivity.subject = subject;
        }
        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

        if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
          this.removeContractWhenCriteriaNoMet();
        }

        await this._updateSurgeryOrderActivity('updateShoreiMaster').catch((error) => {
          console.log(error);
        });
        this.initAllDataModels();
      });
    }
  }

  _handleAddMultipleOpportunities() {
    const contactIds = this.currentSurgeryOrderActivity.customers.map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentSurgeryOrderActivity.accountId || contactIds.includes(item.contactID) || this._checkIfOppHasTheseContacts(contactIds, item)));
    const selectedOpp = opportunitiesDataSet.find(a => a.ID == this.currentSurgeryOrderActivity.opportunityId);

    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentSurgeryOrderActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection: true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data, true),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

      this.navCtrl.push(OpportunitySelectionPageComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: opportuntiesSelectionViewData,
      }, { progressAnimation: false });
    } else {
      this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage,
        { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);

    }
  }

  handleOpportunityField() {
    const contactIds = this.currentSurgeryOrderActivity.customers.map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentSurgeryOrderActivity.accountId || contactIds.includes(item.contactID) || this._checkIfOppHasTheseContacts(contactIds, item)));
    const selectedOpp = opportunitiesDataSet.find((a,i) => {
      if (this.currentSurgeryOrderActivity?.opportunities.length && (a.ID == this.currentSurgeryOrderActivity?.opportunities[0]?.indskr_opportunity || a.ID == this.currentSurgeryOrderActivity?.opportunities[0]['opportunityId'])) {
        return true;
      }
    });
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentSurgeryOrderActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: selectedOpp ? [selectedOpp] : [],
      disableDoneButtonInOffline: false,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

      this.navCtrl.push(OpportunitySelectionPageComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: opportuntiesSelectionViewData,
      }, { progressAnimation: false });
    } else {
      this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage,
        { viewData: opportuntiesSelectionViewData, clickedOpportunity: opportuntiesSelectionViewData.selectedOpportunities[0] }, PageName.OpportunitySelectionPage);
    }
  }

  private _checkIfOppHasTheseContacts(contactIds: Array<string>, opp: Opportunity): boolean {
    let flag = false;
    for (let i = 0; i < opp.stakeholders.length; i++) {
      const stakeholder = opp.stakeholders[i];
      if (contactIds.includes(stakeholder.ID)) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  private async _handledOpportunitiesSelection(event: string, newSelectedOpportunities: Opportunity[], data: any, multipleOpp = false): Promise<void> {
    if (event && event == 'DONEBUTTONCLICK') {
      let opportuntiesPayload = [];
      newSelectedOpportunities.forEach(a => {
        opportuntiesPayload.push(a.ID);
      });
      const payload = opportuntiesPayload;
      if (_.isEmpty(newSelectedOpportunities)) {
        if (!_.isEmpty(this.currentSurgeryOrderActivity.opportunities) && !this.currentSurgeryOrderActivity.ID.includes('offline')) {
          this.currentSurgeryOrderActivity.opportunities.forEach(opp => {
            opp['deleted'] = true;
          });
        } else {
          this.currentSurgeryOrderActivity.opportunities = [];
        }
      } else {
        this.currentSurgeryOrderActivity.opportunities?.forEach(opp => {
          const index = newSelectedOpportunities.findIndex(item => item.ID == opp.indskr_opportunity || item.ID == opp['opportunityId']);
          if (index == -1 && !this.currentSurgeryOrderActivity.ID.includes('offline')) {
            opp['deleted'] = true;
          } else if (index == -1 && this.currentSurgeryOrderActivity.ID.includes('offline')) {
            this.currentSurgeryOrderActivity.opportunities.splice(index, 1);
          }
        });
      }
      newSelectedOpportunities.forEach(item => {
        const index = this.currentSurgeryOrderActivity.opportunities?.findIndex(opp => item.ID == opp.indskr_opportunity || item.ID == opp['opportunityId']);
        if (index == -1) {
          this.currentSurgeryOrderActivity.opportunities.push({
            indskr_opportunity: item.ID,
            indskr_procedurelogopportunityid: Guid.create().toString(),
            deleted: false
          })
        } else {
          this.currentSurgeryOrderActivity.opportunities[index]['deleted'] = false;
        }
      })
      this.applicableOpportunities = [];
      opportuntiesPayload.forEach(item => {
        let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(item);
        this.applicableOpportunities.push(foundOpportunity);
      });
      // if(!multipleOpp){
      //   if (this.applicableOpportunities.length) {
      //     this.currentSurgeryOrderActivity.opportunityId = this.applicableOpportunities.map(op => op.ID) + ''
      //     this.currentSurgeryOrderActivity.opportunityNameString = this.applicableOpportunities.map(op => op.opportunityName) + ''
      //     this.currentSurgeryOrderActivity.offlineOpportunityId = this.currentSurgeryOrderActivity.opportunityId.indexOf('offline') > -1 ? this.currentSurgeryOrderActivity.opportunityId : '';
      //   } else {
      //     this.currentSurgeryOrderActivity.opportunityNameString = '';
      //     this.currentSurgeryOrderActivity.opportunityId = '';
      //     this.currentSurgeryOrderActivity.offlineOpportunityId = '';
      //   }
      //   let operationDetailCode = 'updateOpportunity'
      //   await this._updateSurgeryOrderActivity(operationDetailCode);
      // }
      // else
      {
        let operationDetailCode = 'updateOpportunity'
        await this._updateSurgeryOrderActivity(operationDetailCode);
      }
    }
  }

  private async openCoOwnerSelectionPage() {
    let data = [];
    if (this.procedureTrackerDataService.users) {
      data = this.procedureTrackerDataService.users.map(user => {
        return {
          id: user.userId,
          title: user.userFullName,
          isSelected: this.currentSurgeryOrderActivity.coOwners?.some(coOwner => coOwner.userId === user.userId && coOwner['deleted'] != true)
        }
      })
    }

    if (!this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG) && (this.authService.user.buSettings && !this.authService.user.buSettings['indskr_setownerascoowner'])) {
      data = data.filter((user) => user.id !== this.authService.user.systemUserID)
    }

    data = _.orderBy(data, 'title');
    const coOwnersModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyMode
        }
      },
      backdropDismiss: false
    });
    coOwnersModal.present();
    coOwnersModal.onDidDismiss().then(async (obj) => {
      if (this.backgroundUploadInProgress) return;
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          if (!_.isEmpty(this.currentSurgeryOrderActivity.coOwners) && !this.currentSurgeryOrderActivity.ID.includes('offline')) {
            this.currentSurgeryOrderActivity.coOwners.forEach(coOwner => {
              coOwner['deleted'] = true;
            });
          } else {
            this.currentSurgeryOrderActivity.coOwners = [];
          }
        } else {
          this.currentSurgeryOrderActivity.coOwners?.forEach(coOwner => {
            const index = data.selectedItems.findIndex(item => item.id == coOwner.userId);
            if (index == -1 && !this.currentSurgeryOrderActivity.ID.includes('offline')) {
              coOwner['deleted'] = true;
            } else if (index == -1 && this.currentSurgeryOrderActivity.ID.includes('offline')) {
              this.currentSurgeryOrderActivity.coOwners.splice(index, 1);
            }
          });
          data.selectedItems.forEach(item => {
            const index = this.currentSurgeryOrderActivity.coOwners?.findIndex(coOwner => item.id == coOwner.userId);
            if (index == -1) {
              this.currentSurgeryOrderActivity.coOwners.push({
                userId: item.id,
                userFullName: item.title,
                indskr_procedurelogcoownerid: Guid.create().toString()
              })
            } else {
              this.currentSurgeryOrderActivity.coOwners[index]['deleted'] = false;
            }
          })
        }
        // if (!_.isEmpty(this.currentSurgeryOrderActivity.coOwners)) {
        const action = {
          onDynamics: !this.device.isOffline,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: {
            code: 'coOwnerUpdate',
            message: 'coOwnerUpdate',
          }
        };
        await this.uiService.displayLoader();
        let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
        this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
        await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime(), hasOfflineChanges);
        await this.uiService.dismissLoader();
        // }
        this.initAllDataModels();
      }
    })
  }

  private async openCoVisitorSelectionPage() {
    let data = [];
    let userList = _.cloneDeep(this.activityService.repUserList);
    if (userList.length > 0) {
      data = userList.map(user => {
        return {
          id: user.id,
          title: user.name,
          isSelected: this.currentSurgeryOrderActivity.coVisitors?.some(coVisitor => coVisitor.userId === user.id && coVisitor['deleted'] != true)
        }
      })
    }

    if (!this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG) && !this.currentSurgeryOrderActivity.coVisitors?.some(coVisitor => coVisitor.userId === this.authService.user.systemUserID)) {
      data = data.filter((user) => user.id !== this.authService.user.systemUserID)
    } else {
      let user = await this.authService.getOfflineUser();
      data.push({
        id: this.authService.user.xSystemUserID,
        title: user.displayName,
        isSelected: this.currentSurgeryOrderActivity.coVisitors?.some(coVisitor => coVisitor.userId === this.authService.user.xSystemUserID && coVisitor['deleted'] != true)
      })
    }

    data = _.orderBy(data, 'title');
    const coVisitorsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyMode
        }
      },
      backdropDismiss: false
    });
    coVisitorsModal.present();
    coVisitorsModal.onDidDismiss().then(async (obj) => {
      if (this.backgroundUploadInProgress) return;
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          if (!_.isEmpty(this.currentSurgeryOrderActivity.coVisitors)) {
            this.currentSurgeryOrderActivity.coVisitors.forEach(coVisitor => {
              coVisitor['deleted'] = true;
            });
          }
        } else {
          this.currentSurgeryOrderActivity.coVisitors?.forEach(coVisitor => {
            const index = data.selectedItems.findIndex(item => item.id == coVisitor.userId);
            if (index == -1) coVisitor['deleted'] = true;
          });
          data.selectedItems.forEach(item => {
            const index = this.currentSurgeryOrderActivity.coVisitors?.findIndex(coVisitor => item.id == coVisitor.userId);
            if (index == -1) {
              this.currentSurgeryOrderActivity.coVisitors.push({
                userId: item.id,
                userFullName: item.title,
                indskr_procedurelogcovisitorid: Guid.create().toString()
              })
            } else {
              this.currentSurgeryOrderActivity.coVisitors[index]['deleted'] = false;
            }
          })
        }
        if (!_.isEmpty(this.currentSurgeryOrderActivity.coVisitors)) {
          const action = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'coVisitorUpdate',
              message: 'coVisitorUpdate',
            }
          };
          await this.uiService.displayLoader();
          this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
          await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime());
          await this.uiService.dismissLoader();
        }
        this.initAllDataModels();
      }
    })
  }

  private openCustomerSelect(): void {
    if (!this.isReadOnlyMode || this.currentSurgeryOrderActivity.customers.length !== 0) {
      const customerIds = [];
      const accountIds = [];
      if (this.currentSurgeryOrderActivity.customers) {
        this.currentSurgeryOrderActivity.customers.forEach(con => {
          customerIds.push({
            id: con.ID,
            title: con.title,
            firstName: con.firstName,
            middleName: con.middleName,
            lastName: con.lastName,
            fullname: con.fullname,
            isActive: con.isActive,
            statusCode: con.statusCode
          });
        });
      }
      if (this.currentSurgeryOrderActivity.accountId) {
        accountIds.push({
          id: this.currentSurgeryOrderActivity.accountId
        });
      }
      this.trackingService.tracking('SelectCustomer', TrackingEventNames.SURGERY_ORDER);
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'contactDetails';
      this.uiService.showNewActivity = false;
      this.contactService.accessedContactListFrom = PageName.SurgeryOrderDetailsComponent;
      if (this.isReadOnlyMode) {
        this.contactService.contactPageMode = ComponentViewMode.READONLY;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(ContactPageComponent, {
          type: 'InMeetingProcedurePushedContactSelectionView', selectedContactIds: customerIds,
          selectedAccountIds: accountIds,
          callbackEvent: (data: any) => this._handleContactComponentCallback(data)
        }, { progressAnimation: false });
        return;
      } else {
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
          {
            type: 'PushedContactSelectionView', selectedContactIds: customerIds,
            selectedAccountIds: accountIds,
            callbackEvent: (data: any) => this._handleContactComponentCallback(data)
          }, PageName.DynamicFormComponent, { animate: false }, null);
      }
    }
  }

  private async _handleContactComponentCallback(data) {
    if (!this.isReadOnlyMode && data && (data.selectedItems || data.clearValue)) {
      if (data.clearValue) {
        data.selectedItems = [];
      }

      let updateAccountsForEventFlag = false;
      let primaryAccountToUpdate;

      if (data.selectedItems && data.selectedItems.length === 1) {
        updateAccountsForEventFlag = true;
        const contact = this.contactService.getContactByID(data.selectedItems[0].id);
        let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contact]);
        if (contact && contact.primaryAccount && affiliatedAccounts.length) {
          let primaryAccount = affiliatedAccounts.find(af => af.id == contact.primaryAccount.id);
          if (primaryAccount) {
            primaryAccountToUpdate = {
              id: primaryAccount['id'],
              name: primaryAccount['accountName'],
              isAccountActive: true
            }
          }
        }
      }

      if (data.selectedItems && data.selectedItems.length === 0) {
        updateAccountsForEventFlag = true;
      }

      if (updateAccountsForEventFlag) {
        this.currentSurgeryOrderActivity.accountId = primaryAccountToUpdate ? primaryAccountToUpdate.id : "";
        this.currentSurgeryOrderActivity.accountNameString = primaryAccountToUpdate ? primaryAccountToUpdate.name : "";
        this.currentSurgeryOrderActivity.isAccountActive = primaryAccountToUpdate ? primaryAccountToUpdate.isAccountActive : false;
      }

      this.currentSurgeryOrderActivity.customers = [];
      data.selectedItems.forEach(item => {
        this.currentSurgeryOrderActivity.customers.push({
          ID: item.id,
          title: item.title,
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          fullname: item.fullname,
          isActive: item.isActive,
          statusCode: item.statusCode,
        });
      });

      this.currentSurgeryOrderActivity.customers = this.currentSurgeryOrderActivity.customers.filter(c => c.isActive);
      this.currentSurgeryOrderActivity.customersNameString = this._currentCustomerString;

      let subject = this.currentSurgeryOrderActivity.subject;
      switch (this.currentSurgeryOrderActivity.customers.length) {
        case 0:
          subject = 'Procedure Log';
          break;
        case 1:
          subject = `Procedure Log - ${this.secondaryInfoService.getFullNameForContactDisplaySequence(this.currentSurgeryOrderActivity.customers[0])} - ${this.currentSurgeryOrderActivity.ownerNameString}`;
          break;
        default:
          if (this.currentSurgeryOrderActivity.customers.length > 1) {
            this.currentSurgeryOrderActivity.customers.sort((contactA, contactB): number => {
              let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
              let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
              if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
              if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
              return 0;
            });
            subject = `Procedure Log - ${this.secondaryInfoService.getFullNameForContactDisplaySequence(this.currentSurgeryOrderActivity.customers[0])} + ${this.currentSurgeryOrderActivity.customers.length - 1} - ${this.currentSurgeryOrderActivity.ownerNameString}`;
          }
      }

      if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
        this.removeContractWhenCriteriaNoMet();
      }

      if(!this.currentSurgeryOrderActivity.indskr_subjectedited){
        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
        if (this.secondaryInfoService.isProcedureLogSecInfoConfigured) {
          subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
          this.currentSurgeryOrderActivity.subject = subject;
        }
        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
        this.currentSurgeryOrderActivity.subject = subject;
      }
      let operationDetailCode = 'updatecustomers';
      this._updateSurgeryOrderActivity(operationDetailCode);
      if (this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow) {
        this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
      }
      this.initAllDataModels();
    }
  }

  private openAccountSelect(): void {
    if (!this.isReadOnlyMode || this.currentSurgeryOrderActivity.accountId) {
      let accountId = '';
      const contactIds = [];
      if (this.currentSurgeryOrderActivity.accountId) accountId = this.currentSurgeryOrderActivity.accountId;
      if (this.currentSurgeryOrderActivity.customers) {
        this.currentSurgeryOrderActivity.customers.forEach(cus => {
          if (cus.ID && cus.isActive) {
            contactIds.push(cus.ID);
          }
        });
      }

      let mode: any;
      if (!this.isReadOnlyMode) mode = ComponentViewMode.ADDNEW;
      else mode = ComponentViewMode.READONLY;
      this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
      this.accountService.accessedAccountListFrom = PageName.SurgeryOrderDetailsComponent;
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(AccountPageComponent, {
          listMode: mode, type: 'InMeetingProcedurePushedAccountSelectionView',
          selectedAccountId: accountId, selectedContactIds: contactIds,
          callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
        }, { progressAnimation: false });
      } else {
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent,
          {
            listMode: mode, type: 'PushedAccountSelectionView',
            selectedAccountId: accountId, selectedContactIds: contactIds,
            callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
          },
          PageName.SurgeryOrderDetailsComponent, { animate: false }, null);
      }
    }
  }

  private async _handleAccountComponentCallback(data) {
    if (!this.isReadOnlyMode && data && (data.selectedItem || data.clearValue)) {
      if (data.clearValue) {
        data.selectedItem = [];
      }
      // To Add/Remove affiliated contacts for event
      let updateContactsForEventFlag = false;
      let contactsToUpdate: Contact[] = [];
      const alreadySelectedAccounts: Account[] = [];
      const foundAcc = this.accountService.getAccountById(this.currentSurgeryOrderActivity.accountId);
      if (foundAcc) alreadySelectedAccounts.push(foundAcc);

      //Check for Remove
      if (this.currentSurgeryOrderActivity.customers.filter(a => a.isActive).length === 1 && this.currentSurgeryOrderActivity.accountId) {
        const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(alreadySelectedAccounts);
        if (affiliatedContacts.length === 1 && affiliatedContacts[0].ID === this.currentSurgeryOrderActivity.customers.filter(a => a.isActive)[0].ID) {
          // Remove the only added affiliated contact
          updateContactsForEventFlag = true;
          contactsToUpdate = [];
        }
      }
      //Check for add
      if (!this.currentSurgeryOrderActivity.accountId && data.selectedItem && this.currentSurgeryOrderActivity.customers.filter(a => a.isActive).length === 0) {
        const nowSelectedAccounts: Account[] = [];
        const foundAcc = this.accountService.getAccountById(data.selectedItem.id);
        if (foundAcc) {
          nowSelectedAccounts.push(foundAcc);
        }
        const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(nowSelectedAccounts);
        if (affiliatedContacts.length === 1) {
          // Add the only affiliated contact
          updateContactsForEventFlag = true;
          contactsToUpdate = affiliatedContacts;
          if(!this.currentSurgeryOrderActivity.indskr_subjectedited){
            this.currentSurgeryOrderActivity.subject = `Procedure Log - ${affiliatedContacts[0].fullname}`;
          }
        }
      }
      if (updateContactsForEventFlag) {
        this.currentSurgeryOrderActivity.customers = [];
        contactsToUpdate.forEach(item => {
          this.currentSurgeryOrderActivity.customers.push({
            ID: item.ID,
            title: item.title,
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
            fullname: item.fullname,
            isActive: item.isActive,
            statusCode: item.statusCode
          });
        });
      }

      this.currentSurgeryOrderActivity.accountId = data.selectedItem.id;
      this.currentSurgeryOrderActivity.accountNameString = data.selectedItem.name;

      const selectedAccount = this.accountService.accounts.find(obj => obj.id == this.currentSurgeryOrderActivity.accountId)
      if (selectedAccount && selectedAccount['raw']['indskr_accounttype_Formatted']) {
        this.secondaryInfoService.accounttypeString = selectedAccount['raw']['indskr_accounttype_Formatted'];
      }

      if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
        this.removeContractWhenCriteriaNoMet();
      }

      // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
      if (this.secondaryInfoService.isProcedureLogSecInfoConfigured && !this.currentSurgeryOrderActivity.indskr_subjectedited) {
        const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
        this.currentSurgeryOrderActivity.subject = subject;
      }
      // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

      this.currentSurgeryOrderActivity.isAccountActive = (data.statecode === 2 || data.statecode == 548910003 || data.statecode == 548910010 || data.statecode == 548910011 || data.statecode == 548910012 || data.statecode == 548910013) ? false : true;
      let operationDetailCode = (contactsToUpdate.length > 0 && updateContactsForEventFlag) ? 'updateaccountswithaffliation' : 'updateaccounts';
      await this._updateSurgeryOrderActivity(operationDetailCode).catch((error) => {
        console.log(error);
      });
      this.initFooterViewButtons();
      this.getCustomerFormField();
      this.getAccountFormField();
      this.getDetailsHeader();
      if (this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow) {
        this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
      }
    }
  }

  private async openSurgerySelect(): Promise<void> {
    if (!this.isReadOnlyMode) {
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PROCEDURE'),
        dividerTitle: this.translate.instant('ALL_PROCEDURE'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'surgery'),
        //searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
        data: this.getSurgeriesSelectionData(),
      };
      if (this.isReadOnlyMode) {
        this.currentSurgeryOrderActivity.products.forEach(pro => {
          const idx = listDetail.data.findIndex(it => it.id === pro.productId);
          if (idx < 0) {
            const viewData: MainCardViewDataModel = {
              id: pro.productId,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: true,
              endIconType: 'indegene-selectors-checkmark-icon',
              endIconCssClass: 'checkmark-icon',
              primaryTextRight: pro.productName,
              showArrow: false,
              arrowType: '',
            };
            listDetail.data.push(viewData);
          }
        });
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true, isHierarchyView: true });
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
      }
    }
  }

  private openProductCategorySelect(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCT_CATEGORY'),
      dividerTitle: this.translate.instant('ALL_PRODUCT_CATEGORY'),
      isSearchEnabled: !this.isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !this.isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: !this.isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'productcategory'),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'productcategory'),
      data: this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories.map(pro => {
        let isSelected = false;
        if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.productCategory && this.currentSurgeryOrderActivity.productCategory.some(sopro => (sopro.productCategoryId === pro.productCategoryId))) {
          isSelected = true;
        }
        return {
          id: pro.productCategoryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: pro.productCategoryName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.currentSurgeryOrderActivity.ownerId
        };
      }),
    };
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
    }
  }

  private openProductSelect(): void {
    if (!this.isReadOnlyMode) {
      let products = this.getAllProducts()

      if (!this.hideProcedureAndPorductCategory) {
        let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
          .find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId)
          .productCategories.filter(c => this.currentSurgeryOrderActivity.productCategory.some(o => o.productCategoryId == c.productCategoryId));
        products = this.getProductsSelectionData(selectedProdCategories);
      }

      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCTS'),
        dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
        searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'product'),
        data: products
      };
      if (this.isReadOnlyMode) {
        this.currentSurgeryOrderActivity.products.forEach(pro => {
          const idx = listDetail.data.findIndex(it => it.id === pro.productId);
          if (idx < 0) {
            const viewData: MainCardViewDataModel = {
              id: pro.productId,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: true,
              endIconType: 'indegene-selectors-checkmark-icon',
              endIconCssClass: 'checkmark-icon',
              primaryTextRight: pro.productName,
              showArrow: false,
              arrowType: '',
            };
            listDetail.data.push(viewData);
          }
        });
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true, isGroupedView: true });
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: true }, PageName.MainToolTemplateComponent);
      }
    }
  }

  private _handleProductsComponentSearch(text: string, formField: string): string[] {
    let ids: Array<string> = [];
    switch (formField) {
      case 'surgery':
        if (text.length >= 1) {
          ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.filter(pro => {
            return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.surgeryId);
        } else {
          ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => pro.surgeryId);
        }
        break;
      case 'productcategory':
        if (text.length >= 1) {
          ids = this.surgeryOrderDataService.productHierarchies
            && this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories.filter(pro => {
              return pro.productCategoryName.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(pro => pro.productCategoryId);
        } else {
          ids = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories.map(pro => pro.productCategoryId);
        }
        break;
      case 'product':
        if (!this.hideProcedureAndPorductCategory) {
          let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
            .find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId)
            .productCategories.filter(c => this.currentSurgeryOrderActivity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
          let selectedProdCategoriesProducts = [];
          selectedProdCategories.forEach(pc => {
            selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
          })
          if (text.length >= 1) {
            ids = selectedProdCategoriesProducts.filter(product => {
              return product.productName.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(prod => prod.productId);
          }
        } else {
          let products = _.sortBy(this.brandService?.brands.filter(b => !b.productApplicability.length || b.productApplicability.includes('100000000')), [function (o) { return o.name.toLowerCase(); }]);
          ids = products.filter(product => {
            return product.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(prod => prod.ID);
        }
        break;
      case 'competitorproducts':
        if (text.length >= 1) {
          ids = this.allCompetitorProducts && this.allCompetitorProducts.filter(pro => {
            return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.ID);
        } else {
          ids = this.allCompetitorProducts && this.allCompetitorProducts.map(pro => pro.ID);
        }
        break;
    }
    return ids;
  }

  private async _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed' && data && data.isDone) {
        let operationDetailCode: string;
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
          switch (fieldName) {
            case 'surgery':
              let surgeryProd = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == data.selectedItems[0].id)
              if (surgeryProd && this.currentSurgeryOrderActivity.surgeryId !== surgeryProd.surgeryId) {
                this.currentSurgeryOrderActivity.surgeryId = surgeryProd.surgeryId;
                this.currentSurgeryOrderActivity.surgeryNameString = surgeryProd.surgeryName;
                this.currentSurgeryOrderActivity.productCategory = [];
                this.currentSurgeryOrderActivity.products = [];
                operationDetailCode = 'updatesurgery';
                let category = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories;
                if (category.length == 1) {
                  let applicableProducts = [];
                  this.currentSurgeryOrderActivity.productCategory.push({
                    productCategoryId: category[0].productCategoryId,
                    productCategoryName: category[0].productCategoryName
                  })
                  applicableProducts = applicableProducts.concat(category[0].products);
                  _.remove(this.currentSurgeryOrderActivity.products, (prod) => {
                    return !applicableProducts.some(o => o.productId == prod.productId && o.productCategoryId == prod.productCategoryID);
                  });
                }
                await this._updateSurgeryOrderActivity(operationDetailCode).catch((error) => {
                  console.log(error);
                });
                this.setProductsandCategories();
              }
              break;
            case 'productcategory':
              this.currentSurgeryOrderActivity.productCategory = [];
              let applicableProducts = [];
              data.selectedItems.forEach(item => {
                let category = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories
                  .find(c => c.productCategoryId == item.id)
                if (category) {
                  this.currentSurgeryOrderActivity.productCategory.push({
                    productCategoryId: category.productCategoryId,
                    productCategoryName: category.productCategoryName
                  })
                  applicableProducts = applicableProducts.concat(category.products);
                }
              });
              _.remove(this.currentSurgeryOrderActivity.products, (prod) => {
                return !applicableProducts.some(o => o.productId == prod.productId && o.productCategoryId == prod.productCategoryID);
              });
              operationDetailCode = 'updateproductcategory';
              this._updateSurgeryOrderActivity(operationDetailCode);
              this.setProductsandCategories();
              break;
            case 'competitorproducts':
              let alreadySelectedCompProducts = this.currentSurgeryOrderActivity.competitorProducts;
              this.currentSurgeryOrderActivity.competitorProducts = [];
              data.selectedItems.forEach(item => {
                let foundCompProduct = this.allCompetitorProducts.find(prod => prod.ID == item.id);
                if (foundCompProduct) {
                  let alreadySelectedCompProd = alreadySelectedCompProducts.find(p => p.productId == item.id);
                  if (alreadySelectedCompProd) {
                    this.currentSurgeryOrderActivity.competitorProducts.push(alreadySelectedCompProd);
                  }
                  else {
                    this.currentSurgeryOrderActivity.competitorProducts.push({
                      productId: foundCompProduct.ID,
                      productName: foundCompProduct.name,
                    });
                  }
                }
              });
              this.getDetailsHeader();
              this.initFooterViewButtons();
              operationDetailCode = 'updatecompetitorproducts';
              this._updateSurgeryOrderActivity(operationDetailCode);
              break
            case 'product':
              let alreadySelectedProducts = this.currentSurgeryOrderActivity.products;

              if (this.hideProcedureAndPorductCategory) {
                let products = this.brandService.brands;
                this.currentSurgeryOrderActivity.products = [];

                data.selectedItems.forEach(item => {
                  let foundProduct = products.find(prod => prod.ID == item.id);
                  if (foundProduct) {
                    let alreadySelectedProd = alreadySelectedProducts.find(p => p.productId == item.id && p.productCategoryID == item.parentID);
                    if (alreadySelectedProd) {
                      this.currentSurgeryOrderActivity.products.push(alreadySelectedProd);
                    }
                    else {
                      this.currentSurgeryOrderActivity.products.push({
                        productId: foundProduct.ID,
                        productName: foundProduct.name,
                        quantity: this.hideQunatityField ? 0 : 1,
                        uomid: foundProduct.defaultuomid,
                        isDeleted: false,
                        productCategoryID: "",
                        productCategoryName: ""
                      });
                    }
                  }
                });
              } else {
                let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                  .find(p => p.surgeryId == this.currentSurgeryOrderActivity.surgeryId)
                  .productCategories.filter(c => this.currentSurgeryOrderActivity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
                let selectedProdCategoriesProducts = [];
                selectedProdCategories.forEach(pc => {
                  selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
                })
                this.currentSurgeryOrderActivity.products = [];
                data.selectedItems.forEach(item => {
                  let foundProduct = selectedProdCategoriesProducts.find(prod => prod.productId == item.id && prod.productCategoryId == item.parentID);
                  if (foundProduct) {
                    let alreadySelectedProd = alreadySelectedProducts.find(p => p.productId == item.id && p.productCategoryID == item.parentID);
                    if (alreadySelectedProd) {
                      this.currentSurgeryOrderActivity.products.push(alreadySelectedProd);
                    }
                    else {
                      this.currentSurgeryOrderActivity.products.push({
                        productId: foundProduct.productId,
                        productName: foundProduct.productName,
                        quantity: this.hideQunatityField ? 0 : 1,
                        uomid: foundProduct.uomid,
                        isDeleted: false,
                        productCategoryID: foundProduct.productCategoryId,
                        productCategoryName: foundProduct.productCategoryName
                      });
                    }
                  }
                });
              }
              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
              if (this.secondaryInfoService.isProcedureLogSecInfoConfigured && !this.currentSurgeryOrderActivity.indskr_subjectedited) {
                const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
                this.currentSurgeryOrderActivity.subject = subject;
              }
              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

              operationDetailCode = 'updateproducts';
              await this._updateSurgeryOrderActivity(operationDetailCode).catch((error) => {
                console.log(error);
              });
              this.getDetailsHeader();
              this.initFooterViewButtons();
          }

        }
        else if (data.selectedItems.length == 0) {
          let previousContractId;
          switch (fieldName) {
            case 'surgery':
              this.currentSurgeryOrderActivity.surgeryId = undefined;
              this.currentSurgeryOrderActivity.surgeryNameString = '';
              this.currentSurgeryOrderActivity.productCategory = [];
              this.currentSurgeryOrderActivity.products = [];
              operationDetailCode = 'updatesurgery';
              this._updateSurgeryOrderActivity(operationDetailCode).catch((error) => {
                console.log(error);
              });
              break;
            case 'productcategory':
              this.currentSurgeryOrderActivity.productCategory = [];
              this.currentSurgeryOrderActivity.products = [];
              operationDetailCode = 'updateproductcategory';
              this._updateSurgeryOrderActivity(operationDetailCode);
              break;
            case 'product':
              this.currentSurgeryOrderActivity.products = [];
              this.getDetailsHeader();

              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
              if (this.secondaryInfoService.isProcedureLogSecInfoConfigured && !this.currentSurgeryOrderActivity.indskr_subjectedited) {
                const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
                this.currentSurgeryOrderActivity.subject = subject;
              }
              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

              operationDetailCode = 'updateproducts';
              this._updateSurgeryOrderActivity(operationDetailCode).catch((error) => {
                console.log(error);
              });
              break;
            case 'competitorproducts':
              this.currentSurgeryOrderActivity.competitorProducts = [];
              this.getDetailsHeader();
              operationDetailCode = 'updatecompetitorproducts';
              this._updateSurgeryOrderActivity(operationDetailCode);
              break;
          }
        }
        this.initAllDataModels();
      }
    }
  }

  getProductsSelectionData(selectedProdCategories) {
    let data = [];
    selectedProdCategories.forEach(pro => {
      data.push({
        id: pro.productCategoryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: false,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: false,
        primaryTextRight: pro.productCategoryName,
        showArrow: false,
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      });
      pro.products.forEach(prod => {
        let isSelected = false;
        if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products
          && this.currentSurgeryOrderActivity.products.some(sopro => (sopro.productId === prod.productId && sopro.productCategoryID === prod.productCategoryId))) {
          isSelected = true;
        }
        data.push({
          id: prod.productId,
          parentID: prod.productCategoryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: prod.productName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.currentSurgeryOrderActivity.ownerId
        });
      })
    })
    return data;
  }

  getBrandProductsSelectionData() {
    let data = [];
    this.brandService.brands.filter(prod => prod.producttype === 100000002).sort((a, b): number => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    }).forEach(prod => {
      let isSelected = false;
      if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products
        && this.currentSurgeryOrderActivity.products.some(sopro => (sopro.productId === prod.ID))) {
        isSelected = true;
      }
      data.push({
        id: prod.ID,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: prod.name,
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      });
    })
    return data;
  }

  getAllProducts() {
    let data = [];
    let products = _.sortBy(this.brandService?.brands.filter(b => !b.productApplicability.length || b.productApplicability.includes('100000000')), [function (o) { return o.name.toLowerCase(); }]);
    products.forEach(prod => {
      let isSelected = false;
      if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products
        && this.currentSurgeryOrderActivity.products.some(sopro => (sopro.productId === prod.ID))) {
        isSelected = true;
      }
      data.push({
        id: prod.ID,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: prod.name,
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      });
    })
    return data;
  }

  getSurgeriesSelectionData() {
    let data: Array<MainCardViewDataModel> = [];
    let masterList = _.cloneDeep(this.surgeryOrderDataService.productHierarchies);
    masterList = masterList.sort((a, b): number => {
      if (a.surgeryName > b.surgeryName) return 1;
      if (a.surgeryName < b.surgeryName) return -1;
      return 0;
    });
    for (let i = 0; i < masterList.length; i++) {
      const item = masterList[i];
      let isSelected = item.surgeryId == this.currentSurgeryOrderActivity.surgeryId;
      let obj = {
        id: item.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.surgeryName,
        showArrow: false,
        childItems: [],
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      };

      if (item.subProducts) {
        for (let j = 0; j < item.subProducts.length; j++) {
          const subProduct = item.subProducts[j];
          let childSubProduct: any = getProductFromList(subProduct.productId, item.surgeryId, this.currentSurgeryOrderActivity.surgeryId, this.isReadOnlyMode, this.currentSurgeryOrderActivity.ownerId);
          if (childSubProduct) {
            obj.childItems.push(childSubProduct);
          }
        }
      }
      data.push(obj);
    }

    function getProductFromList(toBeAddedProductId: string, parentID: string, selectedId: string, isReadOnlyMode: boolean, ownerId: string) {
      const foundSurgeryIdx = masterList.findIndex(a => a.surgeryId == toBeAddedProductId);
      let foundSurgery = masterList[foundSurgeryIdx];
      let prodIdxInTree = data.findIndex(p => p.id == toBeAddedProductId);
      if (prodIdxInTree >= 0) {
        let tobeaddedproduct = data[prodIdxInTree];
        tobeaddedproduct.parentId = parentID;
        data.splice(prodIdxInTree, 1);
        return tobeaddedproduct;
      }
      if (foundSurgery) {
        let isSelected = foundSurgery.surgeryId == selectedId;
        let obj = {
          id: foundSurgery.surgeryId,
          parentID: parentID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: foundSurgery.surgeryName,
          showArrow: false,
          childItems: [],
          arrowType: '',
          eventOwnerId: ownerId,
        };
        let subProducts = foundSurgery.subProducts;
        masterList.splice(foundSurgeryIdx, 1);
        if (subProducts) {
          for (let j = 0; j < foundSurgery.subProducts.length; j++) {
            const subProduct = foundSurgery.subProducts[j];
            let childSubProduct: any = getProductFromList(subProduct.productId, foundSurgery.surgeryId, selectedId, isReadOnlyMode, ownerId);
            if (childSubProduct) {
              obj.childItems.push(childSubProduct);
            }
          }
        }
        return obj;
      } else {
        return null;
      }
    }

    if (this.currentSurgeryOrderActivity.surgeryId && !this.surgeryOrderDataService.productHierarchies.some(pro => this.currentSurgeryOrderActivity.surgeryId == pro.surgeryId)) {
      console.log(this.currentSurgeryOrderActivity);
      data.push({
        id: this.currentSurgeryOrderActivity.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: true,
        endIconType: 'indegene-selectors-checkmark-icon',
        endIconCssClass: 'checkmark-icon',
        primaryTextRight: this.currentSurgeryOrderActivity.surgeryNameString + '(Inactive)',
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      })
    }
    return data;
  }

  private _validateQuantityEntered(id, value): boolean {
    try {
      value = parseInt(value);
    } catch (error) {

    }
    if (value === null || value === undefined || isNaN(value) || value < 1) {
      value = 1;
    }
    return value >= 1;
  }

  public async handleQuantityEntered(id, event, target, data) {
    if (event) {
      const product = this.currentSurgeryOrderActivity.products.find(p => p.productId === id && p.productCategoryID == data.parentID);
      if (parseInt(event.value) && parseInt(event.value) > 0) {
        product.quantity = parseInt(event.value);
        product.isEmpty = false;
      } else {
        if (product.quantity == 0) {
          product.isEmpty = true;
        }
      }
    }
    this._CD.detectChanges();
    this.initFooterViewButtons();
    this.getDetailsHeader();
    let operationDetailCode = 'updatequantities';
    this._updateSurgeryOrderActivity(operationDetailCode);
    this.getSelectedProductsFormField();
  }

  public async handleButtonClick(buttonId: string) {
    if (!buttonId) return;

    if (buttonId == 'close') {
      this.onClosePage();
      return;
    }

    if (buttonId === 'assignOwnersurgeryactivity') {
      this.openUsersList();
      return;
    }

    if (buttonId === 'markcompleteagain') {
      this.handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId);
      return;
    }

    if (buttonId == 'tagassets') {
      this._handleAddAssets();
      return;
    }

    if (buttonId == 'save_note') {
      this.handleSaveNote();
      return;
    }

    if (buttonId == 'notes-attachment') {
      this.handleNoteAttachment();
      return;
    }
    if (buttonId == 'add-opportunities') {
      this._handleAddMultipleOpportunities();
      return;
    }

    if ((buttonId == 'scrapsurgeryorderactivity' || buttonId == 'scraporcancelSurgeryOrderActivity') && !this.isScrapButtonEnabled) return;
    if (buttonId == 'markcomplete' && !this.isCompleteButtonEnabled) return;
    if (buttonId == 'reopensurgeryactivity' && !this.isReopenButtonEnabled) return;

    if (this.currentSurgeryOrderActivity.indskr_procedurecontract && (buttonId == 'scrapsurgeryorderactivity' || buttonId == 'scraporcancelSurgeryOrderActivity')) {
      this.notificationService.notify(this.translate.instant('PROCEDURE_LOG_SCRAP_NOTIFICATION'), 'procedure log', 'top', ToastStyle.INFO);
      return;
    }

    const isCancelEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelprocedurelogwithreason'];
    const { role } = await this.surgeryOrderDataService.handleHeaderFooterBtnClick(buttonId, this.currentSurgeryOrderActivity, this.backgroundUploadInProgress);

    if (isCancelEnabled && (buttonId === 'scrapsurgeryorderactivity' || buttonId === 'scraporcancelSurgeryOrderActivity') && role === 'cancel') {
      this.showCancelReasonPopover('');
      return;
    }

    if (role === 'ok') {
      this.handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId);
    }
  }

  private _handleAddAssets() {
    const assets = [];
    if (this.currentSurgeryOrderActivity.assets.length) {
      this.currentSurgeryOrderActivity.assets.forEach(asset => {
        let customerAsset = this.fieldMaterialManagementService.customerAssets.find(e => e.msdyn_customerassetid == asset.msdyn_customerassetid);
        if (asset) {
          assets.push(customerAsset);
        } else {
          assets.push(new CustomerAsset(asset));
        }
      })
    }

    const opportuntiesSelectionViewData: AssetSelectionViewDataModel = {
      viewMode: !this.isScrapButtonEnabled || this.backgroundUploadInProgress ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
      selectedAssets: assets,
      procedureLogDate: { fromDate: this.currentSurgeryOrderActivity.scheduledStart, endDate: this.currentSurgeryOrderActivity.scheduledEnd },
      callbackFromSelectionComponent: (event: string, selectedAssets: CustomerAsset[], data: any) => this._handledAssetsSelection(event, selectedAssets, data),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }


      this.navCtrl.push(FieldMaterialManagementPageComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: opportuntiesSelectionViewData,
      }, { progressAnimation: false });
    } else {
      let from = this.isAssetBooking ? 'procedure_log_asset_booking' : 'procedure_log';
      this.navService.pushWithPageTracking(FieldMaterialManagementPageComponent, PageName.FieldMaterialManagementPageComponent,
        { viewData: opportuntiesSelectionViewData, from: from }, PageName.FieldMaterialManagementPageComponent);
    }
  }

  private async isAssetAlreadyInUse(selectedAssetIds: string[]): Promise<any> {
    let startDate = this.currentSurgeryOrderActivity.scheduledStart.getTime();
    let endDate = this.currentSurgeryOrderActivity.scheduledEnd.getTime();

    if (this.device.isOffline) {
      let uniqueAssetIds = [];
      let assetsAlreadyInUse = [];
      let procedureLogWithAssets = this.activityService.getProcedureActivties(startDate, endDate).filter((item) => item.assets.length > 0 && item.ID !== this.currentSurgeryOrderActivity.ID);
      procedureLogWithAssets.forEach(item => {
        uniqueAssetIds = [...uniqueAssetIds, ...item.assets.map((a) => a.msdyn_customerassetid)]
      })
      uniqueAssetIds = _.uniq(uniqueAssetIds);
      assetsAlreadyInUse = selectedAssetIds.filter(assetIds => uniqueAssetIds.includes(assetIds));
      return assetsAlreadyInUse;
    } else {
      const assetInUseIds = await this.surgeryOrderDataService.validateAssetInUse(selectedAssetIds, startDate, endDate, this.currentSurgeryOrderActivity.ID);
      return assetInUseIds;
    }
  }

  private async _handledAssetsSelection(event, selectedAssets: CustomerAsset[], data: any) {
    if (event && event == 'DONEBUTTONCLICK') {

      let prevSelectedAssets = [];
      if (this.currentSurgeryOrderActivity.assets.length) {
        this.currentSurgeryOrderActivity.assets.forEach(asset => {
          let foundAsset = this.fieldMaterialManagementService.getAssetById(asset.msdyn_customerassetid);
          if (foundAsset) {
            prevSelectedAssets.push(foundAsset);
          } else {
            prevSelectedAssets.push(new CustomerAsset(asset));
          }
        })
      }

      if (!this.isAssetBooking) {
        const selectedAssetIds = selectedAssets.map((selectedAsset) => selectedAsset.msdyn_customerassetid).filter((selectedAssetsId) => !prevSelectedAssets.find(e => e.msdyn_customerassetid == selectedAssetsId));
        if (selectedAssetIds.length) {
          const assetInUseIds = await this.isAssetAlreadyInUse(selectedAssetIds);
          if (assetInUseIds.length > 0) {
            this.notifications.notify(this.translate.instant('ASSET_IN_USE'), 'Order Details', 'top', ToastStyle.INFO);
            selectedAssets = selectedAssets.filter((selectedAsset) => !assetInUseIds.includes(selectedAsset.msdyn_customerassetid));
          }
        }
      }

      selectedAssets.forEach(a => {
        let removedAsset = prevSelectedAssets.find(e => e.msdyn_customerassetid == a.msdyn_customerassetid);
        if (!removedAsset) {
          let surgeryAsset = {
            msdyn_customerassetid: a.msdyn_customerassetid,
            msdyn_name: a.msdyn_name,
            salesorderid: this.currentSurgeryOrderActivity.ID,
            ownerid: this.authService.user.xSystemUserID,
            ownerName: this.authService.user.displayName,
            createdon: new Date().getTime().toString()
          }
          this.currentSurgeryOrderActivity.assets.push(surgeryAsset);
        } else {
          let index = this.currentSurgeryOrderActivity.assets.findIndex(e => e.msdyn_customerassetid == removedAsset.msdyn_customerassetid);
          if (index >= 0) {
            this.currentSurgeryOrderActivity.assets.splice(index, 1);
          }
        }
      });
      this.currentSurgeryOrderActivity.assets = _.uniqBy(this.currentSurgeryOrderActivity.assets, 'msdyn_customerassetid');
      if (this.isAssetBooking) this.createProcedureLogAssetsForAssetBooking();
      this.updateSurgeryAssets();
    }
  }

  async updateSurgeryAssets() {
    const action = {
      onDynamics: this.isAssetBooking ? false : !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'assetsUpdate',
        message: 'assetsUpdate',
      }
    };
    await this.uiService.displayLoader();
    let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
    this.currentSurgeryOrderActivity.pendingPushToDynamics = this.isAssetBooking ? false : true;
    await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime(), hasOfflineChanges);
    await this.uiService.dismissLoader();
  }

  async updateProcedureLogAssetsForAssetBooking(payload) {
    let assetBookings = [];
    assetBookings.push(payload);
    this.surgeryOrderDataService.createUpdateAssetBooking(assetBookings).then(() => {
      let operationDetailCode = 'updateAssetBooking';
      this._updateSurgeryOrderActivity(operationDetailCode);
    })
  }

  async createProcedureLogAssetsForAssetBooking() {
    if (this.device.isOffline) return;
    await this.uiService.displayLoader();
    let assetBookings = [];
    let payload = {
      "indskr_name": `Asset Booking - ${this.currentSurgeryOrderActivity.subject}`,
      "indskr_procedurelog": this.currentSurgeryOrderActivity.ID,
      "indskr_requestor": this.authService.user.xSystemUserID,
      "ownerid": this.authService.user.xSystemUserID,
      "indskr_businessunit": this.authService.user.xBusinessUnitId,
      "indskr_account": this.currentSurgeryOrderActivity.accountId,
      "indskr_fromdate": this.currentSurgeryOrderActivity.scheduledStart.getTime(),
      "indskr_todate": this.currentSurgeryOrderActivity.scheduledEnd.getTime(),
      "statuscode": "1",
      "assets": []
    }
    if (this.currentSurgeryOrderActivity.assets && this.currentSurgeryOrderActivity.assets.length > 0) {
      payload.assets.push(...this.currentSurgeryOrderActivity.assets.map(asset => asset.msdyn_customerassetid));
    }
    assetBookings.push(payload);
    await this.surgeryOrderDataService.createUpdateAssetBooking(assetBookings).then(async (response) => {
      if (response && response[0]['indskr_assetbookingid']) {
        let pl = {
          "statuscode": "548910000",
          "indskr_assetbookingid": response[0]['indskr_assetbookingid']
        }
        this.currentSurgeryOrderActivity.indskr_assetbookingid = response[0]['indskr_assetbookingid'];
        await this.updateProcedureLogAssetsForAssetBooking(pl);
      }
      this.getAssetsHeader();
      await this.uiService.dismissLoader();
    });
  }

  public onSectionHeaderControlClick(id: string) {
    this.handleButtonClick(id);
    // this.handleNoteAttachment();
  }

  private handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  private async handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId: string) {
    if (this.backgroundUploadInProgress) return;
    if (this.currentSurgeryOrderActivity.indskr_procedurecontract && buttonId === 'scrapsurgeryorderactivity') return;

    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    const loader = await this.loadingController.create({});
    loader.present();
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    this.surgeryOrderDataService.updateOrderActivityStatus(action, this.currentSurgeryOrderActivity).then((succ) => {
      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          //Check from where activity is deleted
          this.activityService.publishActivityEvent({ action: "Delete", activity: this.currentSurgeryOrderActivity });
          const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
          this.events.publish('surgeryOrderActivityDeleted', this.currentSurgeryOrderActivity, isLayovered);
          if (this.isAssetBooking && !_.isEmpty(this.currentSurgeryOrderActivity.indskr_assetbookingid)) {
            let payload = {
              "indskr_assetbookingid": this.currentSurgeryOrderActivity.indskr_assetbookingid,
              "statuscode": "548910005"
            }
            this.currentSurgeryOrderActivity.assets = [];
            this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
            this.updateProcedureLogAssetsForAssetBooking(payload);
          }
          if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
            this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.isInMeetingProcedureLogFlow = true;
          }
          if (this.isInMeetingProcedureLogFlow) {
            this.onClosePage();
          } else {
            if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
              this.onClosePage();
              this.activityService.selectedActivity = null;
              this.activityService.selectedActivityAtHome = null;
              this.events.publish('refreshAgenda');
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
              this.onClosePage();
            }
            else if (this.navService.getActiveChildNavName() === ChildNavNames.OpportunityManagementNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.MarketingPlanNavigation) {
              this.onClosePage();
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
            this.uiService.showRightPane = false;
          }
          break;
        }
        case 'markcomplete': {
          this.currentSurgeryOrderActivity.setYearMonthWeek();
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            this.events.publish('refreshAgenda');
          }
          else {
            this.uiService.agendaRefreshRequired = true;
          }
          this.initAllDataModels();
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
          this.events.publish('surgeryOrderActivityCompleted', this.currentSurgeryOrderActivity);
          this.initSurveyData();
          break;
        }
        case 'reopensurgeryactivity': {
          if (this.layoverViewPushedFrom === 'InMeetingProcedure' || this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
            this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.initAllDataModels();
          } else if (this.layoverViewPushedFrom && (this.layoverViewPushedFrom === 'AccountTimeline' || this.layoverViewPushedFrom === 'ContactTimeline' || this.from === 'OpportunityManagementTool')) {
            this.initAllDataModels();
          }
          this.initSurveyData();
          break;
        }
        default:
          break;
      }
      loader.dismiss();

    }).catch(async err => {
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notifications.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
      }

      if (err && err.errorCode && err.errorCode === 'PROCEDURECONTRACTNOTSIGNED') {
        this.openNotificationModal('PROCERDURE_CONTRACT_NO_SIGNATURE');
      }

      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          //Check from where activity is deleted
          break;
        }
        case 'markcomplete': {
          this.currentSurgeryOrderActivity.state = 0;
          this.currentSurgeryOrderActivity.status = 1;
          break;
        }
        case 'reopensurgeryactivity': {
          this.currentSurgeryOrderActivity.state = 1;
          this.currentSurgeryOrderActivity.status = 548910001;
          break;
        }
      }
      loader.dismiss();
    });
  }

  private onClosePage() {
    const currentPage: PageName = this.navService.getCurrentPageName();
    this.surgeryOrderDataService.isInMeetingProcedureLogFlow = false;
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts);
        this.activityService.selectedActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'ContactTimeline') {
        this.activityService.selectedActivity = null;
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.modalCtrl.dismiss();
        if (currentPage === PageName.ActivitiesPageComponent) {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.events.publish("initTimePickerLimitedMinTime");
          this.footerService.initButtons(FooterViews.Activities);
        }
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.uiService.activeView = 'Meeting';
        this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
        this.events.publish("initTimePickerLimitedMinTime");
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromContactTool') {
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromAccountTool') {
        this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
      else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.layoverViewPushedFrom == 'AccountPlanOpportunities') {
        this.footerService.initButtons('opportunityDetails');
        this.navService.popChildNavPageWithPageTracking();
        return;
      }else if(this.layoverViewPushedFrom === 'ProcedureLogFromProcedureContract'){
        this.footerService.initButtons(FooterViews.PROCEDURE_CONTRACT);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
    }
    if (this.device.shouldBeMobileView) {
      if (this.agendaFooterService.actSegment === "week") {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = '';
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
        this.activityService.didOpenDetailPageFromAgendaPage = false;
        return;
      } else if (this.agendaFooterService.actSegment === "agenda") {
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          this.activityService.selectedActivity = undefined;
        }
      }
    }

    if (this.navService.getCurrentMasterPageName() === PageName.MarketingPlanManagementPageComponent) {
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
      return;
    }

    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NothingSelectedView).then(() => {
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
      this.uiService.showRightPane = false;
    });
  }

  private async _updateSurgeryOrderActivity(operationDetailCode: string) {
    let action: OperationDetail = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: operationDetailCode,
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };
    if (operationDetailCode == 'updateAssetBooking') action.onDynamics = false;
    const newLastUpdatedTime = new Date().getTime();
    let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
    this.currentSurgeryOrderActivity.pendingPushToDynamics = operationDetailCode == 'updateAssetBooking' ? false : true;
    this.currentSurgeryOrderActivity.modifiedOn = new Date();
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
    }
    await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], newLastUpdatedTime, hasOfflineChanges).then((succ) => {
      this.getStatusFormField();
    }).catch((err) => {

      if (err && err.errorDetails && err.errorDetails.error) {
        const errorMessage = err.errorDetails.error.errorMessage;
        if (errorMessage == "PROCEDURECONTRACTLIMITEXCEED") {
          this.notificationService.notify(this.translate.instant('PROCEDURE_CONTRACT_EXCEEDED'), 'procedure log', 'top', ToastStyle.DANGER);
          this.removeContractInfoFromSurgeryOrder();
          this.uiService.dismissLoader();
          throw err;
        }
      } else {
        this.getStatusFormField();
      }

    })
    if (operationDetailCode == 'updatesurgery') {
      this._surveyTemplateLoaded = false;
      await this._initProcedureLogSurveyJsAttributes();
    } else if (operationDetailCode == 'updateSurveyResponse') {
      this._surveyTemplateLoaded = false;
      this.loadSurveyTemplate();
    }
    if (!this.device.isOffline) {
      this.uiService.dismissLoader();
    }
  }

  async openNotificationModal(message) {
    // const procedureContract = this.procedureContractService.findProcedureContractById(this.currentSurgeryOrderActivity.indskr_procedurecontract);

    const modal = await this.modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('SIGNATURE_PENDING'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant(message)
      }
    });

    if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
      modal.componentProps.buttons = [{ label: this.translate.instant('CONFIRM_PROCEDURE_LOG_COMPLETE'), role: 'ok' }]
    }

    await modal.present();

    await modal.onDidDismiss().then(({ data }) => {
      if (data && data.role === 'ok') {
        this.handleButtonClick('markcompleteagain');
      }
    });

  }

  private initProcedureNotesModel() {
    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id: 'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : (!this.isSaveNotesEnabled || this.isReadOnlyMode),
          isVisible: !this.isReadOnlyMode,
        }
      ]
    };
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  private initProcedureNotesAndAttachModel() {
    this.notesAndAttachHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id: 'notes-attachment',
          text: this.translate.instant('ATTACH'),
          isVisible: true,
          isDisabled: ((this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) || (this.isCovisitor && this.coVisitorAccess == CovisitorAccess.READ_ACCESS)) || this.device.isOffline || this.isReadOnlyMode
        },
        {
          id: 'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : (!this.isSaveNotesEnabled || this.isReadOnlyMode),
          isVisible: !this.isReadOnlyMode,
        }
      ]
    };
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  private initProcedureContractModel() {
    let buttons = [];
    buttons.push({
      id: "open-contract",
      text: this.translate.instant('OPEN')
    })
    this.procedureContractSectionHeader = {
      id: 'follow-up-actions-header',
      title: this.translate.instant('PROCEDURE_CONTRACTS') + '*',
      controls: buttons
    };
  }

  private openContractPage(): void {
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderDetailsComponent,
      {
        contractDetails: ''
      });
  }

  private shouldDisableFollowUpButton(): boolean {
    const isMeetingExist = this.applicableMeetings.length == 1;
    const hasRestrictedAccess = this.isCovisitor && this.covisitorAccess == CovisitorAccess.READ_ACCESS;
    const statusCondition = this.currentSurgeryOrderActivity.status === 548910001 || this.currentSurgeryOrderActivity.status === 548910005;
    if (isMeetingExist || statusCondition) return true;
    if (this.isOwner) return false;
    if (this.isCoowner) return true;
    if (hasRestrictedAccess) return true;
    return false;
  }

  private initProcedureActivityModel() {

    let buttons = [];
    buttons.push({
      id: "pluse-icon",
      iconClass: 'pluse-icon',
      tooltip: 'New Activity',
      isDisabled: this.shouldDisableFollowUpButton()
    });

    this.followUpActivityHeader = {
      id: 'follow-up-actions-header',
      title: this.translate.instant('FOLLOW_UP_ACTIONS_AND_MEETINGS_CAPS'),
      controls: buttons
    };

    this.applicableMeetings = this.activityService.activities.filter((activity) => activity instanceof AppointmentActivity && activity.ID == this.currentSurgeryOrderActivity.appointmentId) as AppointmentActivity[];

    this.allModelActivities = [];
    this.applicableMeetings.forEach((f: AppointmentActivity) => {
      let currentUserIsCoowner = this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.xSystemUserID);
      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft: f.getStatusString,
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: currentUserIsCoowner ? false : true,
        showEndIcon: currentUserIsCoowner ? false : true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });

  }

  async goToActivityDetails(id) {

    let currentUserIsCoowner = this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.xSystemUserID);
    if (currentUserIsCoowner) {
      return;
    }
    const index = this.activityService.activities.findIndex(o => o.ID == id);
    let activity;
    if (index >= 0) {
      activity = this.activityService.activities[index];
      this.activityService.selectedActivity = this.activityService.activities[index];
    } else {
      return;
    }
    if (!this.device.isOffline && !id.includes('offline')) {
      this.uiService.displayLoader();
      if (activity instanceof AppointmentActivity) {
        await this.activityDataService.updateActivityDetails(activity as AppointmentActivity);
      }
      this.uiService.dismissLoader();
    }
    let fromIdentifier: string = 'ProcedureLogFrom'
    let masterPage = this.navService.getCurrentMasterPageName();
    if (activity instanceof AppointmentActivity) {
      this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent,
        masterPage, { from: fromIdentifier, activity: activity }).then(succ => {
          this.events.publish("detectChangesOnActivityDetails");
        })
    }

  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      // case 'open-contract':
      //   this.openContractPage();
      //   break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public handlePlusButton(): void {
    this.trackingService.tracking('Select+button', TrackingEventNames.SURGERY_ORDER)
    let fromIdentifier: string = 'ProcedureLogFrom'
    let masterPage = this.navService.getCurrentMasterPageName();
    let surgeryOrderData = this.currentSurgeryOrderActivity
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent,
      masterPage, { from: fromIdentifier, surgeryOrder: surgeryOrderData });
  }

  private async handleSaveNote() {
    if (this.isSaveNotesEnabled && !this.backgroundUploadInProgress) {
      this.isSaveNotesEnabled = false;
      this.currentSurgeryOrderActivity.procedureNotes.push(new IONote({
        annotationid: "offline_" + new Date().getTime().toString(),
        notetext: this.tempNoteText ? this.tempNoteText : "",
        ownerid: this.authService.user.systemUserID,
        ownerName: this.authService.user.displayName,
        createdon: new Date().getTime().toString(),
        deleted: false,
        documentbody: (this.base64str ? this.base64str : null),
        filename: this.attachmentFile ? this.attachmentFile.name : null,
        mimetype: this.attachmentFile ? this.attachmentFile.type : null,
        filesize: this.attachmentFile ? this.attachmentFile.size : null,
        isdocument: (this.base64str ? true : false)
        // "objectid_appointment@odata.bind": "/appointments(" +this.activityService.selectedActivity.ID+ ")",      
      }))

      let operationDetailCode = 'updatenotes';
      await this._updateSurgeryOrderActivity(operationDetailCode);
      this.removeMeetingNoteAttachment(null);
      this._sortProcedureNotes();
      this.tempNoteText = '';
      this.isSaveNotesEnabled = false;
      this.initProcedureNotesModel();
      this.initMeetingNotesSectionHeader();
    }
  }

  private async handleEditSubject(event) {
    if (!this.isReadOnlyMode && this.authService.user.buConfigs['indskr_procedurelogsubjecteditable']) {
      if (event.target.value == null || event.target.value === '') {
        event.target.value = this.currentSurgeryOrderActivity.subject;
      }
      if (event && event.target.value && event.target.value !== this.currentSurgeryOrderActivity.subject) {
        this.currentSurgeryOrderActivity.subject = event.target.value;
        this.currentSurgeryOrderActivity.indskr_subjectedited = true;
        this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
        this._updateSurgeryOrderActivity('updateactivitysubject');
        this.getSubjectFormField();
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currEventStr = this.noProcedureDay ? 'NO_PROCEDURE_START_END_TIME_SELECTION' : 'GENERAL_START_AND_END_TIME_SELECTION';
    if (!this.isReadOnlyMode) {
      if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: currEventStr,
              startDateTimeValue: this.currentSurgeryOrderActivity.scheduledStart,
              endDateTimeValue: this.currentSurgeryOrderActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then(async (data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
            if (this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0) {
              if (!_.isEqual(this.currentSurgeryOrderActivity.scheduledStart, new Date(data.data.startTime))) {
                //display alert popover for clearing assets
                let isDateChangeConfirmed = await this.confirmDateChangeWhenAssetsInUse();
                if (isDateChangeConfirmed && this.currentSurgeryOrderActivity.indskr_assetbookingid) {
                  let payload = {
                    "statuscode": "548910005",
                    "indskr_assetbookingid": this.currentSurgeryOrderActivity.indskr_assetbookingid
                  }
                  this.currentSurgeryOrderActivity.assets = [];
                  this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
                  this.updateProcedureLogAssetsForAssetBooking(payload);
                }
                else {
                  return;
                }
              }
            }
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
            this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);

            if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
              if (this.device.isOffline && this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT) {
                this.handleDateChangeOffline(this.currentSurgeryOrderActivity.scheduledStart, new Date(this.startTime));
              } else {
                await this.removeContractWhenCriteriaNoMet();
              }
            }

            this.currentSurgeryOrderActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();
            if (this.currentSurgeryOrderActivity.assets && !this.isAssetBooking) {
              const selectedAssetIds = this.currentSurgeryOrderActivity.assets.map((item) => item.msdyn_customerassetid);
              if (_.isEmpty(selectedAssetIds)) return;
              const assetInUseIds = await this.isAssetAlreadyInUse(selectedAssetIds);
              if (assetInUseIds.length > 0) {
                this.notifications.notify(this.translate.instant('ASSET_IN_USE'), 'Order Details', 'top', ToastStyle.INFO);
                this.currentSurgeryOrderActivity.assets = this.currentSurgeryOrderActivity.assets.filter((selectedAsset) => !assetInUseIds.includes(selectedAsset.msdyn_customerassetid));
                this.updateSurgeryAssets();
              }
            }
          }
        });
        popover.present();
      }
    }
  }

  private _sortProcedureNotes() {
    if (this.currentSurgeryOrderActivity.procedureNotes && this.currentSurgeryOrderActivity.procedureNotes.length > 1) {
      this.currentSurgeryOrderActivity.procedureNotes = this.currentSurgeryOrderActivity.procedureNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  public notesChanged(ev): void {
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
    } else {
      this.tempNoteText = '';
    }
    if (this.tempNoteText.length > 0) {
      this.isSaveNotesEnabled = true;
    } else {
      this.isSaveNotesEnabled = false;
    }
    this.initProcedureNotesModel();
    this.initMeetingNotesSectionHeader();
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authService.user.systemUserID) && note.noteId != 'description-note' && !this.isReadOnlyMode && !this.backgroundUploadInProgress;
  }

  public async updateNote(ev) {
    if (this.backgroundUploadInProgress) return;
    if (ev && ev.action) {
      let idx = this.currentSurgeryOrderActivity.procedureNotes.findIndex(note => note.noteId == ev.noteId);
      if (ev.action == 'DELETE' && idx >= 0) {
        this.currentSurgeryOrderActivity.procedureNotes[idx].isDeleted = true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        this.currentSurgeryOrderActivity.procedureNotes[idx].noteText = ev.updatedText;
        this.currentSurgeryOrderActivity.procedureNotes[idx].updated = true;
      }
      // Update Service
      let operationDetailCode = 'updatenotes';
      await this._updateSurgeryOrderActivity(operationDetailCode);
      this._sortProcedureNotes();
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    if (!this.isReadOnlyMode) {
      if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: 'GENERAL_START_AND_END_TIME_SELECTION',
              startDateTimeValue: this.currentSurgeryOrderActivity.scheduledStart,
              endDateTimeValue: this.currentSurgeryOrderActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then(async (data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
            if (this.isAssetBooking && this.currentSurgeryOrderActivity.assets.length > 0) {
              if (!_.isEqual(this.currentSurgeryOrderActivity.scheduledEnd, new Date(data.data.endTime))) {
                //display alert popover for clearing assets
                let isDateChangeConfirmed = await this.confirmDateChangeWhenAssetsInUse();
                if (isDateChangeConfirmed && this.currentSurgeryOrderActivity.indskr_assetbookingid) {
                  let payload = {
                    "statuscode": "548910005",
                    "indskr_assetbookingid": this.currentSurgeryOrderActivity.indskr_assetbookingid
                  }
                  this.currentSurgeryOrderActivity.assets = [];
                  this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
                  this.updateProcedureLogAssetsForAssetBooking(payload);
                }
                else {
                  return;
                }
              }
            }
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
            this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
            this.currentSurgeryOrderActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();
          }
        });
        popover.present();
      }
    }
  }

  private handleStartDateTimeValue() {
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.startTime = this.prevStartTime;
    } else {
      this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
      this.endTime = format(addMinutes(this.startTime, 30));
      this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
      this.prevStartTime = this.startTime;
      this._updateDateTimeValue();
    }
  }

  private handleEndDateTimeValue() {
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.endTime = this.prevEndTime;
    } else {
      this.prevEndTime = this.endTime;
      this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
      this._updateDateTimeValue();
    }
  }

  private async _updateDateTimeValue() {
    await this._updateSurgeryOrderActivity('updatetime').catch((error) => {
      console.log(error);
    });
    this.getDetailsHeader();
    this.initFooterViewButtons();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      this.events.publish('refreshAgenda');
    }
    this.events.publish('surgeryOrderActivityTimeIsUpdated', this.currentSurgeryOrderActivity);
  }

  // Validate the Products against the retired Bundled Products

  private _updateMandatoryField(field: PageName) {
    if (!this.noProcedureDay) {
      if (field == PageName.ContactPageComponent) {
        this.getCustomerFormField();
      } else if (field == PageName.AccountPageComponent) {
        this.getAccountFormField();
      } else if (field == PageName.MainToolTemplateComponent) {
        this.getSurgeryFormField();
        this.getProductCategoryFormField();
        this.getProductFormField();
      }
    }
  }

  private async _initProcedureLogSurveyJsAttributes(hardFetch: boolean = false) {
    if (!this.device.isOffline && (!this._surveyJsAttributesFetched || hardFetch)) {
      let foundTemplate;
      if (this.hideProcedureAndPorductCategory) {
        let procedureLogTypeTemplates = this.surveyService.surveyForms.filter(a => a.templateType == MobileAppTemplateType.ProcedureLogType);
        if (!_.isEmpty(procedureLogTypeTemplates)) {
          procedureLogTypeTemplates = _.orderBy(procedureLogTypeTemplates, ['modiefiedOn'], ['desc']);
          foundTemplate = procedureLogTypeTemplates[0];
        }
      } else {
        if (this.currentSurgeryOrderActivity.surgeryId) {
          const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
          if (selectedSurgery && selectedSurgery.surveyTemplateId) {
            this._hasTagAssetsSurveyJS = selectedSurgery.hasTagAssets;
            foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedSurgery.surveyTemplateId);
          }
        }
      }
      if (foundTemplate) {
        this.uiService.displayLoader();
        await this.surgeryOrderDataService.fetchSurveyJSAtrributesForProcedure(this.currentSurgeryOrderActivity, foundTemplate).then(() => {
          this.uiService.dismissLoader();
        });
        this._surveyJsAttributesFetched = true;
      }
    }
    this.initSurveyData();
  }

  private initSurveyData() {
    let hasTemplate: boolean = false;
    if (this.hideProcedureAndPorductCategory) {
      const procedureLogTypeTemplates = this.surveyService.surveyForms.filter(a => a.templateType == MobileAppTemplateType.ProcedureLogType);
      if (!_.isEmpty(procedureLogTypeTemplates)) {
        hasTemplate = true;
      } else {
        this.isSurveyConfigured = false;
      }
    } else {
      if (this.currentSurgeryOrderActivity.surgeryId) {
        const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
        if (selectedSurgery && selectedSurgery.surveyTemplateId) {
          hasTemplate = true;
          this._hasTagAssetsSurveyJS = selectedSurgery.hasTagAssets;
        } else {
          this.isSurveyConfigured = false;
        }
      } else {
        this.isSurveyConfigured = false;
      }
    }
    if (hasTemplate) {
      // Load Survey Response Data
      this.loadSurveyResults();
      this.loadSurveyTemplate();
      this.isSurveyConfigured = true;
      this.surveyService.surveyActiveFor = 'ProcedureLog';
      this.procedureSurveyHeaderModel = {
        id: 'survey-detail-header',
        title: this.translate.instant('ADDITIONAL_INFORMATION'),
        controls: []
      }
    }
  }

  private loadSurveyResults() {
    if (this.currentSurgeryOrderActivity.surveyResponseData) {
      if (!_.isEqual(this.currentSurgeryOrderActivity.surveyResponseData, this.surveyService.surveyStaticResults)) {
        this.surveyService.surveyStaticResults = this.currentSurgeryOrderActivity.surveyResponseData;
      }
    } else {
      this.surveyService.surveyStaticResults = '';
    }
    if (this.currentSurgeryOrderActivity.surveyResponseLookupData && this.currentSurgeryOrderActivity.surveyResponseLookupData.length > 0) {
      if (!_.isEqual(this.currentSurgeryOrderActivity.surveyResponseLookupData, this.surveyLookupResults)) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = this.currentSurgeryOrderActivity.surveyResponseLookupData;
      }
    } else {
      if (this.surveyLookupResults.length != 0) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
      }
    }
  }

  private loadSurveyTemplate() {
    if (!this._surveyTemplateLoaded) {
      let foundTemplate;
      if (this.hideProcedureAndPorductCategory) {
        let procedureLogTypeTemplates = this.surveyService.surveyForms.filter(a => a.templateType == MobileAppTemplateType.ProcedureLogType);
        if (!_.isEmpty(procedureLogTypeTemplates)) {
          procedureLogTypeTemplates = _.orderBy(procedureLogTypeTemplates, ['modiefiedOn'], ['desc']);
          foundTemplate = procedureLogTypeTemplates[0];
        }
      } else {
        if (this.currentSurgeryOrderActivity.surgeryId) {
          const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
          if (selectedSurgery && selectedSurgery.surveyTemplateId) {
            foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedSurgery.surveyTemplateId);
          }
        }
      }

      if (foundTemplate) {
        this.surveyTemplate = _.cloneDeep(foundTemplate.surveyConfiguration);
        try {
          this.isReadOnlyMode = !this.isOwner && !this.isCoowner &&
            (this.isCovisitor && (this.covisitorAccess == CovisitorAccess.READ_ACCESS || this.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS)) || this.currentSurgeryOrderActivity.status === 548910005 || this.currentSurgeryOrderActivity.status === 548910001;
          if (this.isReadOnlyMode) {
            const element = this.surveyService.markSurveyElementsReadonly(this.surveyTemplate.pages[0].elements);
          }
        } catch (error) {
          console.log('Error while marking fields readonly' + error);
        }
      }
      this.events.publish('loadSurveyTemplate', this.surveyTemplate);
      if (this._surveyJsAttributesFetched) {
        this._surveyTemplateLoaded = true;
      }
    }

    if (this.surveyTemplate) this.validateSurveyForReview(this.surveyService.surveyStaticResults);
  }

  private validateSurveyForReview(surveyData) {
    this.isSurveyValid = true;
    this.surveyTemplate.pages[0].elements.forEach((question) => {
      if (question.isRequired) {
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(question.name) ? true : false;
        if (question.type == 'panel' && question.elements && question.elements.length > 0) {
          this.validateSurveyElementsForReview(question.elements, surveyData);
        }
      }
    })
  }

  private validateSurveyElementsForReview(elements, surveyData) {
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(elements[i].name) ? true : false;
        if (elements[i].type == 'panel' && elements[i].elements && elements[i].elements.length > 0) {
          this.validateSurveyElementsForReview(elements[i].elements, surveyData)
        }
      }
    }
  }

  public async updateSurveyResult(surveyResponse) {
    this.currentSurgeryOrderActivity.surveyResponse = surveyResponse;
  }

  public async updateSurveyData(surveyData) {
    let foundProperties = false;
    for (var propt in surveyData) {
      foundProperties = true;
      if (this.currentSurgeryOrderActivity.surveyResponseData && this.currentSurgeryOrderActivity.surveyResponseData.hasOwnProperty(propt)) {
        if (this.currentSurgeryOrderActivity.surveyResponseData[propt] != surveyData[propt]) {
          this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
        }
      } else {
        this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
      }
    }
    if(!foundProperties){
      for (var spropt in this.currentSurgeryOrderActivity.surveyResponseData) {
        this.updateSurveyQuestion({ name: spropt, value: null });
      }
    }
    this.surveyService.surveyStaticResults = this.currentSurgeryOrderActivity.surveyResponseData = surveyData;
    this.validateSurveyForReview(surveyData);
    this.initSurveyData();
    this.updateFooterViewButtons();
  }

  public async updateSurveyQuestion(question) {
    try {
      if (question && question.name && this.surveyTemplate && this.surveyTemplate.pages && this.surveyTemplate.pages[0]) {
        const element = this.surveyService.getElementFromSurveyTemplateElements(this.surveyTemplate.pages[0].elements, question);
        if (element && element.type && element.metadata) {
          if (element.type == "lookup" && question.value) {
            let idx;
            // if (!this.currentSurgeryOrderActivity.surveyResponseDTO.hasOwnProperty('lookupfields')) {
              this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'] = [];
            // }
            idx = this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'].findIndex(a => a.name == element.metadata.logicalName);
            if (idx >= 0) {
              if (question.value != this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'][idx].id) {
                this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'] = {
                  name: element.metadata.logicalName,
                  entity: element.metadata.target.setName,
                  id: question.value,
                };
                this._updateSurgeryOrderActivity('updateSurveyResponse');
              }
            } else {
              this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'] = {
                name: element.metadata.logicalName,
                entity: element.metadata.target.setName,
                id: question.value,
              };
              this._updateSurgeryOrderActivity('updateSurveyResponse');
            }
          } else {
            if (!this.currentSurgeryOrderActivity.surveyResponseDTO || (question.value != this.currentSurgeryOrderActivity.surveyResponseDTO[element.metadata.logicalName])) {
              this.currentSurgeryOrderActivity.surveyResponseDTO = { [element.metadata.logicalName] : question.value }

              // this.currentSurgeryOrderActivity.surveyResponseDTO[element.metadata.logicalName] = question.value;
              this._updateSurgeryOrderActivity('updateSurveyResponse')
            }
          }
        }
      }
    } catch (error) {

    }
  }

  async handleActivityOutcomeField(myEvent) {
    if (_.isArray(this.activityOutcomeOptions) && !_.isEmpty(this.activityOutcomeOptions)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'activity_outcome_type',
        isMultipleSelectionEnabled: false,
        data: this.activityOutcomeOptions.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_activityoutcomeid,
            isSelected: type.indskr_activityoutcomeid == this.currentSurgeryOrderActivity.activityOutcomeId,
          }
        }),
      };

      this.activityOutcomePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

      await this.activityOutcomePopover.present();

      await this.activityOutcomePopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        if (data && data.selectedItems && _.isArray(data.selectedItems) && data.selectedItems.length == 1) {
          this.currentSurgeryOrderActivity.activityOutcomeId = data.selectedItems[0].id;
          this.currentSurgeryOrderActivity.activityOutcomeString = data.selectedItems[0].title;
          this._updateSurgeryOrderActivity('updateactivityoutcome');
          this.initAllDataModels();
        }
        this.activityOutcomePopover = undefined;
      });
    }
  }

  private async openUsersList() {
    let data = [];
    if (this.procedureTrackerDataService.users.length) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userId != this.authService.user.xSystemUserID && user.userType && user.userType.includes(UserTypes.SALES_REP)).map(user => {
        return {
          id: user.userId,
          title: user.userFullName
        }
      })
    }

    data = _.orderBy(data, 'title');

    const userModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          confirmationCheckRequired: true,
          confirmationAlertData: {
            title: this.translate.instant('ARE_YOU_SURE'),
            message: this.translate.instant('ASSIGN_OWNER_WARNING'),
            confirmButtonText: this.translate.instant('ASSIGN'),
            cancelButtonText: this.translate.instant('CANCEL')
          },
          needcustomMessage: true
        }
      },
      backdropDismiss: false
    });
    userModal.present();
    userModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {

        } else {
          let currentOwnerId = this.currentSurgeryOrderActivity.ownerId;
          try {
            let operationDetailCode = 'updateOwner';
            let subject = this.currentSurgeryOrderActivity.subject;
            switch (this.currentSurgeryOrderActivity.customers.length) {
              case 0:
                subject = 'Procedure Log';
                break;
              case 1:
                subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname} - ${data.selectedItems[0].title}`;
                break;
              default:
                if (this.currentSurgeryOrderActivity.customers.length > 1) {
                  this.currentSurgeryOrderActivity.customers.sort((contactA, contactB): number => {
                    let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                    let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                    if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                    if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                    return 0;
                  });
                  subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname} + ${this.currentSurgeryOrderActivity.customers.length - 1} - ${data.selectedItems[0].title}`;
                }
            }

            if(!this.currentSurgeryOrderActivity.indskr_subjectedited){
              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
              if (this.secondaryInfoService.isProcedureLogSecInfoConfigured) {
                subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
                this.currentSurgeryOrderActivity.subject = subject;
              }
              // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

              this.currentSurgeryOrderActivity.subject = subject;
            }
            
            this.currentSurgeryOrderActivity.ownerId = data.selectedItems[0].id;
            await this._updateSurgeryOrderActivity(operationDetailCode);

            let currentUserIsCoowner = this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.xSystemUserID);
            if (currentUserIsCoowner) return;

            if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }

            this.activityService.removeActivity(this.activityService.selectedActivity, true);
            if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
            else this.uiService.agendaRefreshRequired = true;
            if (this.uiService.activitiesPageTab === 'day') {
              this.events.publish('activityDeleted');
            }
            if (this.activityService.multiOptionActivityFilter && this.activityService.multiOptionActivityFilter.some(a => a.categoryPath == "Users" && (a.value == this.currentSurgeryOrderActivity.ownerId || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId == a.value)))) {
              // Dont remove the activity from calendar
            } else {
              this.activityService.publishActivityEvent({ action: "Delete", activity: this.activityService.selectedActivity });
            }
          } catch (error) {
            // if owner updation fails
            this.currentSurgeryOrderActivity.ownerId = currentOwnerId;

            if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }
          }
        }
      }
    })
  }

  private async openMarketingPlans() {
    if (this.isReadOnlyMode && _.isEmpty(this.currentSurgeryOrderActivity.marketingBusinessPlanId)) return;
    let marketingPlans = this.marketingPlansManagementOfflineService.filterMarketingBusinessPlanByTime(this.marketingPlansManagementOfflineService.marketingPlans);

    let selectedMarketingPlan: any = {
      indskr_brandplanid: (this.activityService.selectedActivity as SurgeryOrderActivity).marketingBusinessPlanId,
      indskr_name: (this.activityService.selectedActivity as SurgeryOrderActivity).marketingBusinessPlanName
    }

    if (!_.isEmpty(marketingPlans)) {
      const marketingPlansSelectionViewData: MarketingPlanSelectionViewDataModel = {
        isReadOnlyMode: this.isReadOnlyMode,
        selectedMarketingPlans: this.currentSurgeryOrderActivity.marketingBusinessPlanId ? [new MarketingPlan(selectedMarketingPlan)] : [],
        disableDoneButtonInOffline: false,
        positionfilter: true,
        viewMode: this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
        callbackFromSelectionComponent: (event: string, newSelectedMarketingPlans: MarketingPlan[], data: any) => this._handledMarketingPlansSelection(event, newSelectedMarketingPlans, data),
      };
      this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { viewData: marketingPlansSelectionViewData, from: PageName.ActivitiesPageComponent }, PageName.MarketingPlanManagementPageComponent);
    }
  }

  _handledMarketingPlansSelection(event, newSelectedMarketingPlans, data) {
    if (newSelectedMarketingPlans.length > 0) {
      this.currentSurgeryOrderActivity.marketingBusinessPlanId = newSelectedMarketingPlans[0].ID;
      this.currentSurgeryOrderActivity.marketingBusinessPlanName = newSelectedMarketingPlans[0].brandPlanName;
    } else {
      this.currentSurgeryOrderActivity.marketingBusinessPlanId = "";
      this.currentSurgeryOrderActivity.marketingBusinessPlanName = "";
    }

    let operationDetailCode = 'updateMarketBusinessPlan';
    this._updateSurgeryOrderActivity(operationDetailCode);
    this.getMarketingBusinessPlanField();
  }

  private openCoachingPlans(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('COACHING_PLANS'),
      dividerTitle: this.translate.instant('ALL_COACHING_PLANS'),
      isSearchEnabled: !this.isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !this.isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: '',
      hideAllItemsList: false,
      isListSelectionEnabled: !this.isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCoachingPlanComponentEvent(data, eventTarget, refData),
      searchHandler: (text: string) => this._handleCoachingPlanComponentSearch(text),
      data: this.allCoachingPlans.map(c => {
        let isSelected = false;
        if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.indskr_coachingplan && this.currentSurgeryOrderActivity.indskr_coachingplan == c.indskr_coachingplanid) {
          isSelected = true;
        }
        return {
          id: c.indskr_coachingplanid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: c.indskr_name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: (this.activityService.selectedActivity as SurgeryOrderActivity).ownerId
        };
      }),
    };
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true, isGroupedView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: true }, PageName.MainToolTemplateComponent);
    }
  }

  private async _handleCoachingPlanComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      this._handledCoachingPlanSelection(eventTarget, data, refData)
    }
  }

  async _handledCoachingPlanSelection(event, selected, data) {
    if (selected.selectedItems.length > 0) {
      this.currentSurgeryOrderActivity.indskr_coachingplan = selected.selectedItems[0].id;
      this.currentSurgeryOrderActivity.indskr_coachingplanname = selected.selectedItems[0].primaryTextRight;
    } else {
      this.currentSurgeryOrderActivity.indskr_coachingplan = "";
      this.currentSurgeryOrderActivity.indskr_coachingplanname = "";
    }

    let operationDetailCode = 'updateCoachingPlan';
    this._updateSurgeryOrderActivity(operationDetailCode);
    this.getCoachingPlanField();
  }

  private _handleCoachingPlanComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.allCoachingPlans && this.allCoachingPlans.filter(pro => {
        return pro.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.indskr_coachingplanid);
    } else {
      ids = this.allCoachingPlans && this.allCoachingPlans.map(pro => pro.indskr_coachingplanid);
    }
    return ids;
  }

  async segmentChanged(selectedTab: string) {
    this.surgeryOrderDetailSegment = selectedTab;
  }

  private handleCompetitorProductSelect(): void {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('COMPETITOR_PRODUCTS'),
      dividerTitle: this.translate.instant('ALL_COMPETITOR_PRODUCTS'),
      isSearchEnabled: !this.isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !this.isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('COMPETITOR_PRODUCTS'),
      hideAllItemsList: false,
      isListSelectionEnabled: !this.isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'competitorproducts'),
      searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'competitorproducts'),
      data: this.allCompetitorProducts.sort((a, b): number => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }).map(c => {
        let isSelected = false;
        if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.competitorProducts && this.currentSurgeryOrderActivity.competitorProducts.some(oco => (oco.productId === c.ID))) {
          isSelected = true;
        }
        return {
          id: c.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: c.name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.currentSurgeryOrderActivity.ownerId
        };
      }),
    };
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true, isGroupedView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: true }, PageName.MainToolTemplateComponent);
    }
  }

  private setProductToSurgeryOrder(product) {
    this.currentSurgeryOrderActivity.products.push({
      productId: product.productId,
      productName: product.productName,
      quantity: 1,
      uomid: product.uomid,
      isDeleted: false,
      productCategoryID: product.productCategoryId,
      productCategoryName: product.productCategoryName
    });
  }

  private async addProductToProcedureLog(selectedProductId) {
    const alreadySelectedProducts = this.currentSurgeryOrderActivity.products;
    const selectedProdCategoriesids = this.selectedProductCateogries.map((selectedProdCategory) => selectedProdCategory.productCategoryId);

    let foundProduct = this.productsMappedToselectedProductCateogries.find(prod => prod.productNumber == selectedProductId);
    if (foundProduct) {
      if (selectedProdCategoriesids.includes(foundProduct.productCategoryId)) {
        let alreadySelectedProd = alreadySelectedProducts.find(p => p.productId == foundProduct.productId);
        if (alreadySelectedProd) {
          let options = {
            mainImgSrc: 'assets/imgs/round-grey-tick.svg',
            message: this.translate.instant('PRODUCT_ADDED_ALREADY'),
            subText: this.translate.instant('PRODUCT_ID') + ` : ${foundProduct.productNumber}`,
            list: [{
              itemAvatarSrc: 'assets/imgs/tools_customer_allocations.svg',
              itemText: `${foundProduct.productName}`
            }],
          }
          const response = await this.openProductAlert(options);
          if (response && response.data) {
            this.scanProduct();
          }
          return;
        } else {
          this.setProductToSurgeryOrder(foundProduct);
        }
        await this._updateSurgeryOrderActivity('updateproducts');
        this.openSuccessModal(foundProduct);
      } else {
        let options = {
          mainImgSrc: 'assets/imgs/round-red-close.svg',
          message: this.translate.instant('PRODUCT_NOT_FOUND'),
          subText: this.translate.instant('PLEASE_CONTACT_SYSTEM_ADMIN'),
        }

        const response = await this.openProductAlert(options);
        if (response && response.data) {
          this.scanProduct();
        }
        return;

      }
    } else {
      let options = {
        mainImgSrc: 'assets/imgs/round-red-close.svg',
        message: this.translate.instant('PRODUCT_NOT_FOUND'),
        subText: this.translate.instant('PLEASE_CONTACT_SYSTEM_ADMIN'),
      }
      const response = await this.openProductAlert(options);
      if (response && response.data) {
        this.scanProduct();
      }
      return;
    }
    this.getDetailsHeader();
    this.initFooterViewButtons();
  }

  async openSuccessModal(product) {
    let options = {
      mainImgSrc: 'assets/imgs/round-blue-tick.svg',
      message: this.translate.instant('PRODUCT_ADDED'),
      subText: this.translate.instant('PRODUCT_ID') + `: ${product.productNumber}`,
      list: [{
        itemAvatarSrc: 'assets/imgs/tools_customer_allocations.svg',
        itemText: `${product.productName}`
      }],
    }
    const response = await this.openProductAlert(options);
    if (response && response.data) {
      this.scanProduct();
      return;
    }
  }

  async openProductAlert(options: any) {
    const modal = await this.modalCtrl.create({
      component: OmniAlertComponent,
      cssClass: 'omni-alert-modal',
      componentProps: {
        inputOptions: {
          ...options,
          cancelBtnText: this.translate.instant('CLOSE'),
          confirmBtnText: this.translate.instant('SCAN_BARCODE')
        }
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then((data) => {
      return data;
    });

  }

  private async handleProcedureTypesField(myEvent) {
    const procedureTypes = this.surgeryOrderDataService.procedureTypes;
    if (procedureTypes && procedureTypes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-types',
        isMultipleSelectionEnabled: false,
        data: procedureTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_proceduretypeid,
            isSelected: type.indskr_proceduretypeid == this.currentSurgeryOrderActivity.indskr_proceduretype,
          }
        }),
      };

      const procedureTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
      });

      await procedureTypePopover.present();

      await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.currentSurgeryOrderActivity.indskr_proceduretype === data.selectedItems[0].id) return;
        this.currentSurgeryOrderActivity.indskr_proceduretype = data.selectedItems[0].id;
        this.secondaryInfoService.proceduretypeTitle = data.selectedItems[0].title;

        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
        if (this.secondaryInfoService.isProcedureLogSecInfoConfigured  && !this.currentSurgeryOrderActivity.indskr_subjectedited) {
          const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
          this.currentSurgeryOrderActivity.subject = subject;
        }
        // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

        if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
          // this.removeContractWhenCriteriaNoMet();
          await this.removeContractInfoFromSurgeryOrder()
        }

        this.currentSurgeryOrderActivity.indskr_proceduresubtype = null;
        this.currentSurgeryOrderActivity.indskr_proceduresubtypename = "";

        await this._updateSurgeryOrderActivity('updateProcedureType').catch((error) => {
          console.log(error);
        });
        this.initAllDataModels();
      });
    }
  }

  addContractInfoInSurgeryOrder(procedureContract: ProcedureContract) {
    if (!procedureContract) return;
    this.currentSurgeryOrderActivity.indskr_procedurecontract = procedureContract.indskr_procedurecontractid;
    this.currentSurgeryOrderActivity.contractStartDate = procedureContract.indskr_startdate;
    this.currentSurgeryOrderActivity.contractEndDate = procedureContract.indskr_enddate;
    this.currentSurgeryOrderActivity.noofassistanceavailed = procedureContract.indskr_noofassistanceavailed;
    this.currentSurgeryOrderActivity.maximumnoofassistance = procedureContract.indskr_maximumnoofassistance;
    this.currentSurgeryOrderActivity.contractStatus = procedureContract.statuscode;
    if (this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow) {
      this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
    }
    this.getProcedureContractFormField();
  }

  async removeContractInfoFromSurgeryOrder() {
    if (this.device.isOffline) {
      if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT) {
        this.contractService.decrementProcedureUsedCount(this.currentSurgeryOrderActivity.indskr_procedurecontract);
      } else {
        this.handlePaidContractCountInOffline(this.currentSurgeryOrderActivity.indskr_procedurecontract);
      }
    }

    this.contractService.contractTempid = this.currentSurgeryOrderActivity.indskr_procedurecontract;
    this.currentSurgeryOrderActivity.indskr_procedurecontract = "";
    this.currentSurgeryOrderActivity.contractStartDate = null;
    this.currentSurgeryOrderActivity.contractEndDate = null;
    this.currentSurgeryOrderActivity.noofassistanceavailed = 0;
    this.currentSurgeryOrderActivity.maximumnoofassistance = 0;
    if (this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow) {
      this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
    }

    this.getProcedureContractFormField();

    return;
  }

  public openProcedureContract() {
    if (this.device.isOffline) return;

    const canCheckForProcedureContract = this.contractService.areProcedureContractFieldsFilled(this.currentSurgeryOrderActivity);
    if (!canCheckForProcedureContract) return false;
    const contractType = this.surgeryOrderDataService.findContractTypeByProcedureTypeId(this.currentSurgeryOrderActivity.indskr_proceduretype);

    this.contractService.setProcedureContractsToList(this.currentSurgeryOrderActivity, contractType, this.isProcedureSubtypeAvailable);

    const procedureContractViewData = {
      selectedProcedureContract: this.currentSurgeryOrderActivity.indskr_procedurecontract ? this.currentSurgeryOrderActivity.indskr_procedurecontract : null,
      callbackFromSelectionComponent: (event: string, selectedProcedureContract: any, data: any) => this.handleProcedureContractSelection(event, selectedProcedureContract, data),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

      this.navCtrl.push(SurgeryOrderContractComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: procedureContractViewData,
      }, { progressAnimation: false });
    } else {
      this.navService.pushWithPageTracking(
        SurgeryOrderContractComponent, PageName.SurgeryOrderContractComponent, {
        viewData: procedureContractViewData,
        from: PageName.ActivitiesPageComponent
      },
        PageName.SurgeryOrderDetailsComponent
      );
    }
  }

  async handleProcedureContractSelection(event, selectedProcedureContract, data) {
    if (event === 'DONEBUTTONCLICK') {
      if (selectedProcedureContract) {
        this.currentSurgeryOrderActivity.indskr_procedurecontract = selectedProcedureContract.id;
        // if (selectedProcedureContract.removePostSignature) {
        //   const proceduerContract = this.contractService.findProcedureContractById(selectedProcedureContract.id);
        //   proceduerContract.indskr_postsurgerysignature = "";
        //   proceduerContract.indskr_postsurgerysignaturecaptured = false;
        //   this.contractService.updateProcedureContract(proceduerContract.indskr_procedurecontractid, {
        //     indskr_postsurgerysignaturecaptured: false,
        //     indskr_postsurgerysignature: ""
        //   });
        //   this.contractService.UpdateProcedureContractOnline({
        //     indskr_postsurgerysignaturecaptured: false,
        //     indskr_postsurgerysignature: ""
        //   }, proceduerContract.indskr_procedurecontractid);
        // }

        await this._updateSurgeryOrderActivity('updateProcedureContract').then(() => {
          const proceduerContract = this.contractService.findProcedureContractById(selectedProcedureContract.id)
          this.addContractInfoInSurgeryOrder(proceduerContract);
        });
      } else {
        this.contractService.contractTempid = this.currentSurgeryOrderActivity.indskr_procedurecontract;
        this.currentSurgeryOrderActivity.indskr_procedurecontract = "";
        await this._updateSurgeryOrderActivity('updateProcedureContract').then((a) => {
          this.removeContractInfoFromSurgeryOrder();
        });
      }
    }
  }

  private async removeContractWhenCriteriaNoMet() {
    const contractInfo = this.contractService.findProcedureContractById(this.currentSurgeryOrderActivity.indskr_procedurecontract);
    const contractType = this.surgeryOrderDataService.findContractTypeByProcedureTypeId(this.currentSurgeryOrderActivity.indskr_proceduretype);
    if (!contractType) return;
    const contractMeetCriteria = this.contractService.isContractMeetCriteria(
      contractInfo, this.currentSurgeryOrderActivity,
      contractType, this.isProcedureSubtypeAvailable);
    if (!contractMeetCriteria) await this.removeContractInfoFromSurgeryOrder();
    return;
  }

  private async handleProcedureSubTypesField(myEvent) {
    const procedureSubTypes = this.surgeryOrderDataService.procedureSubTypes.sort((a, b) => {
      const orderA = Number(a.indskr_order);
      const orderB = Number(b.indskr_order);

      if (orderA < orderB) return -1;
      if (orderA > orderB) return 1;
      return a.indskr_name.localeCompare(b.indskr_name);
    });
    if (procedureSubTypes && procedureSubTypes.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-sub-types',
        isMultipleSelectionEnabled: false,
        data: procedureSubTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_proceduresubtypeid,
            isSelected: type.indskr_proceduresubtypeid == this.currentSurgeryOrderActivity.indskr_proceduresubtype,
          }
        }),
      };

      let procedureSubTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

      await procedureSubTypePopover.present();

      await procedureSubTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.currentSurgeryOrderActivity.indskr_proceduresubtype === data.selectedItems[0].id) return;
        this.currentSurgeryOrderActivity.indskr_proceduresubtype = data.selectedItems[0].id;
        this.currentSurgeryOrderActivity.indskr_proceduresubtypename = data.selectedItems[0].title;

        if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
          // this.removeContractWhenCriteriaNoMet();
          await this.removeContractInfoFromSurgeryOrder()
        }

        await this._updateSurgeryOrderActivity('updateProcedureSubType').then(() => {
        }).catch((error) => {

        });
        this.initAllDataModels();
      });
    }
  }

  handlePaidContractCountInOffline(contractId) {
    const otherSurgeries = this.surgeryOrderDataService.findSurgeryOrderByDate(this.currentSurgeryOrderActivity.startDate);
    if (otherSurgeries.length == 1) {
      if (this.procedureContractType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
        this.contractService.decrementProcedureUsedCount(contractId);
      } else {
        this.contractService.decrementProcedureUsedCount(contractId);
        const month = new Date(this.currentSurgeryOrderActivity.startDate).getMonth();
        const year = new Date(this.currentSurgeryOrderActivity.startDate).getFullYear();
        const childContract = this.contractService.getChildContractByMonthAndYear(month, year, contractId);
        this.contractService.decrementProcedureUsedCount(childContract.indskr_procedurecontractid);
      }
    }
  }

  // this is for paid multiple contract type.
  handleDateChangeOffline(currentStartDate, newDate) {
    const currentDateSurgeries = this.surgeryOrderDataService.findSurgeryOrderByDate(currentStartDate);
    const newDateSurgeries = this.surgeryOrderDataService.findSurgeryOrderByDate(newDate);
    if (currentDateSurgeries.length > 1 && newDateSurgeries.length > 1) return;
    if (currentDateSurgeries.length == 1 && newDateSurgeries.length == 0) return;
    if (currentDateSurgeries.length == 1 && newDateSurgeries.length > 1) this.contractService.decrementProcedureUsedCount(this.currentSurgeryOrderActivity.indskr_procedurecontract);
    if (currentDateSurgeries.length > 1 && newDateSurgeries.length == 0) this.contractService.incrementProcedureUsedCount(this.currentSurgeryOrderActivity.indskr_procedurecontract);
  }

  public async showCancelReasonPopover(myEvent) {
    try {
      const response = await this.surgeryOrderDataService.showCancelSurgeryOrderPopover(myEvent);
      if (response && response.role && response.role == 'ok') {

        this.uiService.displayLoader();
        this.currentSurgeryOrderActivity.indskr_reasonforcancellation = response.inputs.value;
        this.currentSurgeryOrderActivity.cancellationreason = response.inputs.name;
        if (response.selectedOtherItem) {
          this.currentSurgeryOrderActivity.indskr_cancellationreasonother = response.selectedOtherItem;
        }
        if (this.isAssetBooking && !_.isEmpty(this.currentSurgeryOrderActivity.indskr_assetbookingid)) {
          let payload = {
            "indskr_assetbookingid": this.currentSurgeryOrderActivity.indskr_assetbookingid,
            "statuscode": "548910005"
          }
          this.currentSurgeryOrderActivity.assets = [];
          this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
          this.updateProcedureLogAssetsForAssetBooking(payload);
        }
        await this.surgeryOrderDataService.updateOrderActivityStatus({
          onDynamics: !this.device.isOffline,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: {
            code: 'cancelsurgeryorder',
            message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
          }
        }, this.currentSurgeryOrderActivity);
        if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
        else this.uiService.agendaRefreshRequired = true;

        if (this.authService.user.buSettings &&
          !this.authService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar']) {
          this.handleCancellation();
        }

        let isLayovered = (this.layoverViewPushedFrom === 'PresentationMeetingComponent' || this.layoverViewPushedFrom === 'InMeetingProcedure');
        if (isLayovered) {
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
          this.getDetailsHeader();
          this.initAllDataModels();
        }
        this.uiService.dismissLoader();
      }
    } catch (error) {
      console.log('Error white showing cancel popover', error);
    }
  }

  handleCancellation() {
    const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
    this.events.publish('surgeryOrderActivityDeleted', this.currentSurgeryOrderActivity, isLayovered);
    if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
      this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
      this.isInMeetingProcedureLogFlow = true;
    }
    if (this.isInMeetingProcedureLogFlow) {
      this.onClosePage();
    } else {
      if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.onClosePage();
        this.activityService.selectedActivity = null;
        this.activityService.selectedActivityAtHome = null;
        this.events.publish('refreshAgenda');
      } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
        this.onClosePage();
      } else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
        this.onClosePage();
      }
      else if (this.navService.getActiveChildNavName() === ChildNavNames.OpportunityManagementNavigation) {
        this.onClosePage();
      }
      else {
        this.uiService.agendaRefreshRequired = true;
      }
      this.uiService.showRightPane = false;
    }
  }

  public async loadImageFromDevice(event) {
    if (this.device.isOffline) return;
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if ((file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            // await this.saveAttachmentInDynamics();
            // this.initNotesSectionHeader();
            // } 
            // else if(this.isMeetingAttachmentEnabled) {
            this.meetingNoteAttachmentTitle = file.name;
            this.isMeetingAttachmentAdded = true;
            this.isSaveNotesEnabled = true;
            this.initMeetingNotesSectionHeader();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Account Info', 'top', ToastStyle.INFO);
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Account Info', 'top', ToastStyle.INFO);
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public removeAttachment(event) {
    if (this.device.isOffline || this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) return;
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  public removeMeetingNoteAttachment(event) {
    if (this.device.isOffline || this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID || this.activityService.selectedActivity.isCompleted
      || this.backgroundUploadInProgress || !this.isMeetingAttachmentEnabled) return;
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.meetingNoteAttachmentTitle = '';
    this.isMeetingAttachmentAdded = false;
    this.initMeetingNotesSectionHeader();
    this.attachmentFile = null;
    this.base64str = null;
  }

  public async downloadAttachment(event) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('ATTACHMENT_CAN_BE_DOWNLOADED_ONLY_WHEN_ONLINE_MSG'), 'Notes Attachment Down', 'top', ToastStyle.INFO);
      return;
    }
    let response = await this.dynamics.retrieveAll('annotations', [],
      `annotationid eq  ${(this.activityService.selectedActivity as AppointmentActivity).associatedAttachments[0].annotationId}`)
      .then(res => res.value).catch(() => []);
    if (response) {
      if (response[0].filename) {
        if (response[0].documentbody) {
          try {
            if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
              this.fileService.downloadeBase64DataFileAndOpenInNativeApp(response[0].filename, response[0].documentbody, response[0].mimetype);
            } else {
              const linkSource = response[0].documentbody;
              const downloadLink = document.createElement("a");
              const fileName = response[0].filename;
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
              downloadLink.remove();
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          await this.uiService.displayLoader();
          try {
            let data = await this.fileService.getDocumentBody(response[0].annotationid);
            if (data['documentbody']) {
              if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
                this.fileService.downloadeBase64DataFileAndOpenInNativeApp(response[0].filename, response[0].documentbody, response[0].mimetype);
              } else {
                const linkSource = data['documentbody'];
                const downloadLink = document.createElement("a");
                const fileName = response[0].filename;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                downloadLink.remove();
              }
            }
            await this.uiService.dismissLoader();
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }

  private initMeetingNotesSectionHeader() {
    let isNotesAttachmentDisabled = this.device.isOffline || this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress;
    let isNotesSaveDisabled = this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || !this.isSaveNotesEnabled || (_.isEmpty(this.tempNoteText) && !this.isMeetingAttachmentAdded);
    if ((this.coVisitorAccess === CovisitorAccess.MINIMUM_ACCESS || this.coVisitorAccess === CovisitorAccess.FULL_ACCESS) && this.isCovisitor) {
      isNotesAttachmentDisabled = isNotesAttachmentDisabled ? isNotesAttachmentDisabled : false;
      isNotesSaveDisabled = this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress || !this.isSaveNotesEnabled || (_.isEmpty(this.tempNoteText) && !this.isMeetingAttachmentAdded);
    } else {
      // isNotesAttachmentDisabled = isNotesAttachmentDisabled ? isNotesAttachmentDisabled : this.enableReadonlyViewForMeetings || this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID;
      isNotesSaveDisabled = isNotesSaveDisabled ? isNotesSaveDisabled : this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID;
    }
    this.notesAndAttachHeaderModel = {
      id: 'notes-and-attachments',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id: 'notes-attachment',
          text: this.translate.instant('ATTACH'),
          isVisible: true,
          isDisabled: (this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) || (this.isCovisitor && this.coVisitorAccess == CovisitorAccess.READ_ACCESS) || this.device.isOffline
        },
        {
          id: 'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : (!this.isSaveNotesEnabled || this.isReadOnlyMode),
          isVisible: true//!this.isReadOnlyMode,
        }
      ]
    }
  }

  get nonDeletedNotes() {
    return this.currentSurgeryOrderActivity?.procedureNotes?.filter(note => note.isDeleted == false)
  }

  selectOpportunity(selected) {
    const contactIds = this.currentSurgeryOrderActivity.customers.map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentSurgeryOrderActivity.accountId || contactIds.includes(item.contactID) || this._checkIfOppHasTheseContacts(contactIds, item)));
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentSurgeryOrderActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection: true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data, true),
    };
    let clickedOpp = selected;
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData, clickedOpportunity: clickedOpp }, PageName.OpportunitySelectionPage);

  }

  ngOnDestroy() {
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.unsubscribe('reloadSurveyTemplate');
    this.noProcedureDay = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.dateFormatSubscription.unsubscribe();

    this.secondaryInfoService.proceduretypeTitle = '';
    this.secondaryInfoService.accounttypeString = '';
  }
}
