<ion-header>
  <ion-toolbar class="left-header-toolbar" [ngClass]="{'toolbar-android': isAndroid}">
    <ion-buttons class="start-slot" slot="start">
      <ion-menu-toggle>
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
      </ion-menu-toggle>
    </ion-buttons>
    <div class="header-title">{{'CALL_PLAN' | translate}}</div>
  </ion-toolbar>

  <ng-container *ngIf="backgroundUploadInProgress || syncInProgress || localDbDataLoadInProgress">
    <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
    <div class="in-progress">
      <ion-spinner></ion-spinner>
      <span class="secondary-text">{{ syncMessage }}</span>
    </div>
  </ng-container>
</ion-header>

<ion-content>
  <div class="section-header summary-header" *ngIf="displayType == 'A'">
    <div class="section-header-text">
      {{'CALL_PLAN_DASH_SUMMARY' | translate}}
    </div>
    <ng-container *ngTemplateOutlet="reportTimeFrameSegment"></ng-container>
  </div>
  <div class="segment-wrapper" *ngIf="displayType == 'B'">
    <ion-segment class="android-style-segment" [value]="currentSegment" (ionChange)="onSegmentChange($event)" mode="md">
      <ion-segment-button value="accounts">
        <ion-label>{{'CALL_PLAN_DASH_DETAILS_ACCOUNTS' | translate}}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="customers">
        <ion-label>{{'CALL_PLAN_DASH_DETAILS_CUSTOMERS' | translate}}</ion-label>
      </ion-segment-button>
    </ion-segment>
    <ng-container *ngTemplateOutlet="reportTimeFrameSegment"></ng-container>
  </div>
  <div class="summary-section-wrapper">
    <div class="section-row one-liner-kpi-wrapper round-border">
      <div class="flex-align-center one-liner-kpi">
        <ion-icon src="assets/imgs/kpi-bullet-blue.svg"></ion-icon>
        <div class="text">{{ (currentSegment === 'accounts' ? 'TARGET_ACCOUNTS' : 'TARGET_CUSTOMERS') | translate}}</div>
        <span *ngIf="!isSummaryLoading"
          class="one-liner-kpi-value"
        >
          {{ currentSegment === 'accounts' ? targetAccountsData.primaryValue : targetCustomersData.primaryValue}}
        </span>
        <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:20px"></ion-skeleton-text>
      </div>
      <div class="flex-align-center one-liner-kpi" *ngIf="displayType === 'B' && currentSegment === 'accounts'">
        <ion-icon src="assets/imgs/kpi-bullet-blue.svg"></ion-icon>
        <div class="text flex-align-center">
          {{'QUARTERLY_WORKLOAD_PERCENTAGE_LABEL' | translate}}
          <ion-icon src="assets/imgs/tip.svg" (click)="infoClicked(quarterlyWorkloadData.infoText, $event)"></ion-icon>
        </div>
        <span class="one-liner-kpi-value"
          [ngStyle]="{'color': quarterlyWorkloadData.primaryValueColorCode}"
          *ngIf="!isSummaryLoading"
        >
          {{quarterlyWorkloadData.primaryValue}}
        </span>
        <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:20px"></ion-skeleton-text>
      </div>
      <div class="flex-align-center one-liner-kpi" *ngIf="displayType === 'A' || (displayType === 'B' && currentSegment === 'customers')">
        <ion-icon src="assets/imgs/kpi-bullet-blue.svg"></ion-icon>
        <div class="text flex-align-center">
          {{'RPC' | translate}}
          <ion-icon src="assets/imgs/tip.svg" (click)="infoClicked(rpcData.infoText, $event)"></ion-icon>
        </div>
        <span class="one-liner-kpi-value"
          [ngStyle]="{'color': rpcData.primaryValueColorCode}"
          *ngIf="!isSummaryLoading"
        >
          {{rpcData.primaryValue}}
        </span>
        <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:20px"></ion-skeleton-text>
      </div>
    </div>
    <div class="section-row section-row-wrap" *ngIf="!(displayType === 'B' && currentSegment === 'customers')">
      <ng-container *ngTemplateOutlet="chartKpiCard; context: {$implicit: totalCallAchData}"></ng-container>
      <ng-container *ngTemplateOutlet="chartKpiCard; context: {$implicit: cpaABData}"></ng-container>
    </div>
    <div class="section-row section-row-wrap" [ngClass]="{'two-col': displayType === 'A', 'three-col': displayType === 'B'}">
      <ng-container *ngIf="!(displayType === 'B' && currentSegment === 'accounts')">
        <ng-container *ngTemplateOutlet="plainKpiCard; context: {$implicit: displayType === 'A' ? weComPushData : remoteEdaData}"></ng-container>
        <ng-container *ngTemplateOutlet="plainKpiCard; context: {$implicit: displayType === 'A' ? weComPushCoverageData : weComPushData}"></ng-container>
        <ng-container *ngTemplateOutlet="plainKpiCard; context: {$implicit: displayType === 'A' ? remoteEdaData : weComPushCoverageData}"></ng-container>
      </ng-container>
      <ng-container *ngIf="displayType === 'A'">
        <ng-container *ngTemplateOutlet="plainKpiCard; context: {$implicit: quarterlyWorkloadData}"></ng-container>
      </ng-container>
    </div>
    <div class="misc-data-wrapper">
      <div class="misc-data-row">
        <div class="flex-align-center misc-data">
          {{'VISITING_DAYS_THIS_MONTH_LABEL' | translate}}
          <span *ngIf="!isSummaryLoading">{{visitingDaysThisMonth}}</span>
          <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:16px;margin-left:2px;"></ion-skeleton-text>
        </div>
        <div class="misc-data">|</div>
        <div class="flex-align-center misc-data">
          {{'TIME_PROGRESS_LABEL' | translate}}
          <span *ngIf="!isSummaryLoading">{{timeProgress}}</span>
          <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:18px;margin-left:2px;"></ion-skeleton-text>
        </div>
      </div>
      <div class="misc-data-row">
        <div class="flex-align-center misc-data">
          {{'LAST_UPDATED_LABEL' | translate}}
          <span *ngIf="!isSummaryLoading">{{lastUpdatedDateTime}}</span>
          <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:48px;margin-left:2px;"></ion-skeleton-text>
        </div>
      </div>
    </div>
  </div>
  <div class="section-header">
    <div class="section-header-text">
      {{'CALL_PLAN_DASH_DETAILS' | translate}}
    </div>
    <div class="cpa-legend" *ngIf="(displayType === 'A' && customerCpaIndicatorColor) || (displayType === 'B' && (currentSegment === 'accounts' && accountCpaIndicatorColor) || (currentSegment === 'customers' && customerCpaIndicatorColor))" [style.color]="displayType === 'A' || currentSegment === 'customers' ? customerCpaIndicatorColor : accountCpaIndicatorColor">
      <div class="cpa-legend-text">
        {{'CPA' | translate}}
      </div>
      <ion-icon name="ellipse"></ion-icon>
    </div>
  </div>
  <div class="detail-section-wrapper">
    <table>
        <thead>
          <tr>
            <th id="th-customer" [ngClass]="{'wider': currentSegment === 'accounts'}">{{(currentSegment === 'accounts' ? 'CALL_PLAN_DASH_DETAILS_ACCOUNT' : 'CUSTOMER') | translate}}</th>
            <th id="th-segment">{{'CALL_PLAN_DASH_DETAILS_SEGMENT' | translate}}</th>
            <th id="th-total-call"><div style="white-space: pre-line;">{{ 'CALL_PLAN_DASH_DETAILS_TOTAL_CALL_WITH_ACT_GOAL' | translate}}</div></th>
            <th id="th-remote-eda" *ngIf="currentSegment !== 'accounts'">{{'CALL_PLAN_DASH_DETAILS_REMOTE_EDA' | translate}}</th>
            <th id="th-wecom-push" *ngIf="currentSegment !== 'accounts'">{{'CALL_PLAN_DASH_DETAILS_WECOM_PUSH' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngIf="!isDetailLoading && detailTableData.length > 0 && (currentReportPeriod === 'QTD' || (currentReportPeriod === 'MTD' && currentSelectedReportMonthIdxIdx === currentMonthIdxIdx))">
            <ng-container *ngFor="let parentCallPlan of detailTableData; let i = index; trackBy: trackByIndex">
              <tr [ngClass]="{'expanded-cp': isChildVisible(i)}">
                <!-- Parent row -->
                <td class="td-customer">
                  <div [ngClass]="callPlanSummaryIconStyle" class="icon-container">
                    <ion-icon class="left-icon" *ngIf="parentCallPlan?.children?.length > 0" [name]="isChildVisible(i) ? 'chevron-down-outline' : 'chevron-up-outline' "
                      (click)="toggleChildVisibility(i)" slot="start">
                    </ion-icon>
                    <ion-icon class="right-icon" *ngIf="parentCallPlan.cpaIndicator"
                      [style.color]="currentSegment === 'accounts' ? accountCpaIndicatorColor : customerCpaIndicatorColor"
                      name="ellipse"></ion-icon>
                  </div>
                  {{ parentCallPlan.fullName }}
                </td>
                <td>{{ parentCallPlan.segment }}</td>
                <td>{{ parentCallPlan.totalCall }}</td>
                <td *ngIf="currentSegment !== 'accounts'">{{ parentCallPlan.remoteEDA }}</td>
                <td *ngIf="currentSegment !== 'accounts'">{{ parentCallPlan.weComPush }}</td>
              </tr>
          
              <!-- Child rows (if any) -->
              <ng-container *ngIf="parentCallPlan.children && isChildVisible(i)">
                <tr class="child-cp" *ngFor="let child of parentCallPlan.children; let j = index; trackBy: trackByIndex">
                  <td class="td-customer">
                    <div class="child-icon-container" [ngClass]="{'cpa': currentSegment == 'accounts' ? accountCpaIndicatorColor : customerCpaIndicatorColor}"></div>
                    {{ child.productName }}
                  </td>
                  <td>{{ child.segment }}</td>
                  <td>{{ child.totalCall }}</td>
                  <td *ngIf="currentSegment !== 'accounts'">{{ child.remoteEDA }}</td>
                  <td *ngIf="currentSegment !== 'accounts'">{{ child.weComPush }}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="isDetailLoading">
            <tr>
              <td><ion-skeleton-text class="skeleton-text-animated" style="width:84px;height:100%;"></ion-skeleton-text></td>
              <td><div class="flex-center"><ion-skeleton-text class="skeleton-text-animated" style="width:20px;height:100%;"></ion-skeleton-text></div></td>
              <td><div class="flex-center"><ion-skeleton-text class="skeleton-text-animated" style="width:20px;height:100%;"></ion-skeleton-text></div></td>
              <td *ngIf="currentSegment !== 'accounts'"><div class="flex-center"><ion-skeleton-text class="skeleton-text-animated" style="width:20px;height:100%;"></ion-skeleton-text></div></td>
              <td *ngIf="currentSegment !== 'accounts'"><div class="flex-center"><ion-skeleton-text class="skeleton-text-animated" style="width:20px;height:100%;"></ion-skeleton-text></div></td>
            </tr>
          </ng-container>
        </tbody>
    </table>
    <ng-container *ngIf="!isDetailLoading && ((currentSelectedReportMonthIdxIdx === currentMonthIdxIdx && currentReportPeriod === 'MTD') || currentReportPeriod === 'QTD') && (!detailTableData || detailTableData.length === 0)">
      <div class="no-content-text">{{'NO_CONTENT' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="!isDetailLoading && currentSelectedReportMonthIdxIdx !== currentMonthIdxIdx && currentReportPeriod === 'MTD'">
      <div class="no-content-text">{{'CALL_PLAN_DASHBOARD_DISCLAIMER_FOR_NO_DATA' | translate}}</div>
    </ng-container>
  </div>
</ion-content>


<ng-template #chartKpiCard let-chartKpiCardData>
  <div class="flex-align-center round-border chart-kpi-wrapper kpi-card-wrapper">
    <div class="chart-wrapper">
      <ind-gauge-chart
        [percentage]="chartKpiCardData ? chartKpiCardData.ringChartPercentage : 0"
        [color]="chartKpiCardData ? chartKpiCardData.ringChartColorCode : null"
        [diameter]="chartKpiCardData && chartKpiCardData.columnSize === 3 ? threeColoumnGaugeChartDiameter : gaugeChartDiameter"
      ></ind-gauge-chart>
      <ng-container
        *ngTemplateOutlet="chartKpiValueLabel; context: {$implicit: {
          primaryValue: chartKpiCardData ? chartKpiCardData.primaryValue : '',
          secondaryValue: chartKpiCardData ? chartKpiCardData.secondaryValue : '',
          secondaryValuePrefixIcon: chartKpiCardData ? chartKpiCardData.secondaryValuePrefixIcon : '',
          tertiaryValue: chartKpiCardData ? chartKpiCardData.tertiaryValue : '',
          tertiaryValuePrefixIcon: chartKpiCardData ? chartKpiCardData.tertiaryValuePrefixIcon : ''
        }}"
      ></ng-container>
    </div>
    <ng-container
      *ngTemplateOutlet="kpiNameLabel; context: {$implicit: {name: chartKpiCardData ? chartKpiCardData.name : '', infoText: chartKpiCardData ? chartKpiCardData.infoText : ''}}"
    ></ng-container>
  </div>
</ng-template>

<ng-template #plainKpiCard let-plainKpiCardData>
  <div class="flex-align-center round-border plain-kpi-wrapper kpi-card-wrapper">
    <ng-container
      *ngTemplateOutlet="plainKpiValueLabel; context: {$implicit: {
        primaryValue: plainKpiCardData ? plainKpiCardData.primaryValue : '',
        primaryValueColorCode: plainKpiCardData ? plainKpiCardData.primaryValueColorCode : '',
        secondaryValue: plainKpiCardData ? plainKpiCardData.secondaryValue : '',
        secondaryValuePrefixIcon: plainKpiCardData ? plainKpiCardData.secondaryValuePrefixIcon : ''
      }}"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="kpiNameLabel; context: {$implicit: {name: plainKpiCardData ? plainKpiCardData.name : '', infoText: plainKpiCardData ? plainKpiCardData.infoText : ''}}"
    ></ng-container>
  </div>
</ng-template>

<ng-template #chartKpiValueLabel let-kpiValueData>
  <div class="kpi-value-label">
    <div class="primary-value-label">
      <div class="text" *ngIf="!isSummaryLoading">{{kpiValueData.primaryValue}}</div>
      <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:44px;height:100%;"></ion-skeleton-text>
    </div>
    <div class="secondary-value-label" *ngIf="!isSummaryLoading && kpiValueData.secondaryValue">
      <ion-icon
        *ngIf="kpiValueData.secondaryValuePrefixIcon"
        [name]="kpiValueData.secondaryValuePrefixIcon"
        [class]="'label-icon ' + kpiValueData.secondaryValuePrefixIcon"
      ></ion-icon>
      <div class="text">{{kpiValueData.secondaryValue}}</div>
    </div>
    <div class="tertiary-value-label" *ngIf="!isSummaryLoading && kpiValueData.tertiaryValue">
      <ion-icon
        *ngIf="kpiValueData.tertiaryValuePrefixIcon"
        [name]="kpiValueData.tertiaryValuePrefixIcon"
        [class]="'label-icon ' + kpiValueData.tertiaryValuePrefixIcon"
      ></ion-icon>
      <div class="text">{{kpiValueData.tertiaryValue}}</div>
    </div>
  </div>
</ng-template>

<ng-template #plainKpiValueLabel let-kpiValueData>
  <div class="kpi-value-label">
    <div class="value-row" *ngIf="!isSummaryLoading">
      <div class="primary-value-label">
        <div class="text" [ngStyle]="{'color': kpiValueData.primaryValueColorCode}">{{kpiValueData.primaryValue}}</div>
      </div>
      <div class="secondary-value-label" *ngIf="kpiValueData.secondaryValue">
        <ion-icon
          *ngIf="kpiValueData.secondaryValuePrefixIcon"
          [name]="kpiValueData.secondaryValuePrefixIcon"
          [class]="'label-icon ' + kpiValueData.secondaryValuePrefixIcon"
        ></ion-icon>
        <div class="text">{{kpiValueData.secondaryValue}}</div>
      </div>
    </div>
    <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:44px;height:100%;"></ion-skeleton-text>
  </div>
</ng-template>

<ng-template #kpiNameLabel let-kpiNameData>
  <div class="kpi-name-label">
    <div class="text" *ngIf="!isSummaryLoading">{{kpiNameData.name}}</div>
    <ion-skeleton-text *ngIf="isSummaryLoading" class="skeleton-text-animated" style="width:60px;"></ion-skeleton-text>
    <ion-icon src="assets/imgs/tip.svg" *ngIf="kpiNameData.infoText" (click)="infoClicked(kpiNameData.infoText, $event)"></ion-icon>
  </div>
</ng-template>

<ng-template #reportTimeFrameSegment>
  <div class="ios-style-segment-wrapper">
    <ion-segment class="ios-style-segment" [value]="currentReportPeriod" (ionChange)="onReportPeriodChange($event)">
      <ion-segment-button [ngClass]="{'wider-button': currentReportPeriod === 'MTD'}" (click)="onMtdPeriodSelectionClick()" value="MTD">
        <ion-label
        >{{currentSelectedReportMonthIdxIdx === currentMonthIdxIdx ? 'MTD' : currentSelectedReportMonthShortFormLabel}}
        <ion-icon *ngIf="currentReportPeriod === 'MTD'" name="chevron-down-outline"></ion-icon>
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="QTD">
        <ion-label>QTD</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
</ng-template>