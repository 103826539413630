import { EntityNames } from './../data-services/delta/delta.service';
import { Domain } from "../classes/domain/domain.class";
import { User, childPosition, FeatureActionsMap, childUser } from '../classes/authentication/user.class';
import { DiskService } from "./disk/disk.service";
import { Injectable, Injector } from "@angular/core";
import { UserConfig } from '../classes/authentication/user-config.class';
import { ConfiguredFields } from "../classes/authentication/configured.field.class";
import { LaunchDarklyProvider } from '../providers/launch-darkly/launch-darkly';
import { DeviceService } from "./device/device.service";
import { DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, DB_SYNC_STATE_KEYS } from "../config/pouch-db.config";
import { NotificationService } from "./notification/notification.service";
import {CustomerReplaceText, Utility} from "../utility/util";
import { BehaviorSubject, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { ResourceState } from "../store/application.state";
import * as ResourceAction from '../store/io-file-service/actions/resource.actions';
import {OkIntegration} from "@omni/classes/onekey/ok-integration.class";
import { EventsService } from './events/events.service';
import { decrypt, encrypt } from './crypto';
import { DEFAULT_AUTO_DELTA_SYNC_TIME_IN_HOURS } from '@omni/config/shared.config';

export const NUM_BUCKETS_PER_POSITION = 10


export enum FeatureActionChangeActions {
  OFF,
  ON,
  TURNED_ON,
  TURNED_OFF
}

type HasFeatureActionProps = {
  featureAction: FeatureActionsMap;
  checkLDOnly?: boolean;
  checkExternalFlag?: boolean;
  skipLDCheck?: boolean;
};

/**
 * Offline data store for our access tokens and domain information
 *
 * @export
 * @class AuthenticationService
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly DAYS_IN_FUTURE = 45;
  private readonly DAYS_PAST = 45;

  public _user: User;
  private _lastUserState: string;
  public userConfig: UserConfig;
  public notesAssistantConfig: NotesAssistantConfig

  public activeDomain: Domain;
  childPositions: Array<childPosition> = [];
  private _translate:TranslateService
  encryptedAccess: string;
  impersonatedUser: childUser
  shouldTrySyncingDuringAppLaunch: boolean = false;
  shouldFullSync = false;
  shouldContentFullSync: boolean = false;
  individualFullSyncList: EntityNames[] = [];
  private _offlineDataUploadRetryCount = 0;
  readonly DEFAULT_OFFLINE_DURATION = this.DAYS_PAST;
  private _globalCustomerTxtUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly globalCustomerTxtUpdatedObservable: Observable<boolean> = this._globalCustomerTxtUpdated$.asObservable();
  private _globalCustomersTxtUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly globalCustomersTxtUpdatedObservable: Observable<boolean> = this._globalCustomersTxtUpdated$.asObservable();
  public okIntegrationSettings: OkIntegration;

  public showSyncBanner = false;

  /**
   * Creates an instance of AuthenticationService.
   * Loads domain details from DB if required
   * @param {DiskService} diskService
   * @memberof AuthenticationService
   */
  constructor(
    private diskService: DiskService,
    private launchDarklyProvider: LaunchDarklyProvider,
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    private _injector: Injector,
    private store: Store<ResourceState>,
    private events: EventsService,
    ) {

  }
  private get translate() {
    if (!this._translate) {
      this._translate = this._injector.get(TranslateService);
    }
    return this._translate;
  }

  public hasFeatureAction(featureAction: FeatureActionsMap): boolean;
  public hasFeatureAction(featureAction: FeatureActionsMap, checkLDOnly: boolean): boolean;
  public hasFeatureAction(featureAction: FeatureActionsMap, checkLDOnly: boolean, checkExternalFlag: boolean): boolean;
  public hasFeatureAction(featureActionProps: HasFeatureActionProps): boolean;
  public hasFeatureAction(featureActionProps: HasFeatureActionProps | FeatureActionsMap, checkLDOnly = false, checkExternalFlag = false) {
    featureActionProps = !(featureActionProps as HasFeatureActionProps).featureAction ? {
      featureAction : featureActionProps as FeatureActionsMap,
      checkLDOnly,
      checkExternalFlag
    } as HasFeatureActionProps : featureActionProps as HasFeatureActionProps;
    if (this.deviceService.deploymentTarget === 'ios') {
      if ([
        // FeatureActionsMap.VIDEO_BUTTON,
        // FeatureActionsMap.MEETING_SCREENSHARE,
        // FeatureActionsMap.LIVETIME_SCREENSHARE,
        // FeatureActionsMap.MEETING_VOIP
      ].indexOf(featureActionProps.featureAction) >= 0) {
        return false;
      }
    }
    if (featureActionProps.featureAction === FeatureActionsMap.FORCE_OFFLINE_ACTIVITIES_PUSH) {
    return this.launchDarklyProvider.isFeatureEnabled(featureActionProps.featureAction);
    }

    if (this.deviceService._isDeviceOfflineFromLogin && this.deviceService.isOffline && !featureActionProps.checkLDOnly) {
      featureActionProps.skipLDCheck = true;
    }

    switch (featureActionProps.featureAction) {
      case FeatureActionsMap.BASELINE:
      case FeatureActionsMap.THERAPEUTIC_AREA:
      case FeatureActionsMap.CONTENT_MATCHING:
      case FeatureActionsMap.ALLOCATION_TRANSFER:
        featureActionProps.skipLDCheck = true;
        break;
      case FeatureActionsMap.GOOGLE_ANALYTCS:
        featureActionProps.checkLDOnly = true;
        break;
    }
    if(this.user && this.user.featureActions.indexOf(featureActionProps.featureAction) > - 1){
      featureActionProps.skipLDCheck = true;
    }
    featureActionProps.skipLDCheck = !featureActionProps.skipLDCheck ? localStorage.getItem("region") === "China" : featureActionProps.skipLDCheck;
    let ldFeatureEnabled = featureActionProps.skipLDCheck || !!this.launchDarklyProvider.isFeatureEnabled(featureActionProps.featureAction);
    if (!featureActionProps.checkLDOnly && ldFeatureEnabled) {
        ldFeatureEnabled = this.user && this.user.featureActions.indexOf(featureActionProps.featureAction) > - 1
    }  
    return ldFeatureEnabled;
  }


  public get_num_positions() {
    return this.user.childPositions.length + this.user.positions.length
  }

  public get_num_buckets(){
    return this.get_num_positions() * NUM_BUCKETS_PER_POSITION
  }


  public mapLoginResponse(response: object) {
    //User
    this.userConfig = new UserConfig(response);
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
  }

  get lastUserState(): string {
    return this._lastUserState ? this._lastUserState : '';
  }

  set lastUserState(state: string) {
    this._lastUserState = state;
  }

  public async getOfflineUser(): Promise<User> {
    try {
      let user = await this.diskService.retrieve(DB_KEY_PREFIXES.USER, true);
      if (!user) return undefined;

      this._user = User.fromStoredObject(user.user);
      this.setGlobalCustomerText();
      this.setGlobalCustomersText();

      return user.user;
    } catch (error) {
      console.log('error while getting offline user:', error);
      return;
    }
  }

  public async saveDomainDetails(raw: object) {
    try {
      await this.diskService.updateOrInsert(DB_KEY_PREFIXES.DOMAINS, doc => ({ raw: raw }))
        .catch(error => {
          console.error('saveDomainDetails: ', error);
        });
    } catch (savingError) {
      console.error("Error saving domain details", raw, savingError);
    }
  }

  get UserID(): string {
    return this._user.userID
  }


  mapUser(response: any) {
    this.user = new User(response);
    return this.user;
  }

  async saveUser(doNotSave: boolean) {
    this.setGlobalCustomerText();
    this.setGlobalCustomersText();
    
    if (doNotSave) {
      return this.user;
    }

    try {
      const newDoc = {
        user: this.user,
        lastModified: new Date().getTime()
      };
      await this.diskService.updateOrInsert(DB_KEY_PREFIXES.USER, doc => newDoc);
    } catch (error) {
      console.error('saving user failed on DB', error);
    }

   
    return this.user;
  }

  async saveOfflineLoginInfo(username: string, offlineConfiguration) {
    try {
      if (!this.deviceService.isNativeApp || !offlineConfiguration) return false;
      // If hybrid app, encrypt and save to secure storage
      const { ivString, encryptedString } = await encrypt(JSON.stringify(offlineConfiguration), username);
      //We save the IV for unlocking the loginConfig
      await this.diskService.saveToSecureStorage(username + '-encBuffer', encryptedString);
      await this.diskService.saveToSecureStorage(username, ivString);
      return true;
    }
    catch (ex) {
      console.error(ex);
    }
    return false;
  }
  
  public setGlobalCustomerText() {
    if (this.hasFeatureAction(FeatureActionsMap.CONTACT_UPDATE)) {
      Utility.globalCustomerText = CustomerReplaceText.GLOBAL_STAKEHOLDER_REPLACE_TEXT;//this.translate.instant();
    } else {
      Utility.globalCustomerText = CustomerReplaceText.GLOBAL_CUSTOMER_REPLACE_TEXT;//this.translate.instant();
    }
    this._globalCustomerTxtUpdated$.next(true);
  }

  public setGlobalCustomersText() {
    if (this.hasFeatureAction(FeatureActionsMap.CONTACT_UPDATE)) {
      Utility.globalCustomersText = CustomerReplaceText.GLOBAL_STAKEHOLDERS_REPLACE_TEXT;//this.translate.instant();
    } else {
      Utility.globalCustomersText = CustomerReplaceText.GLOBAL_CUSTOMERS_REPLACE_TEXT;//this.translate.instant();
    }
    this._globalCustomersTxtUpdated$.next(true);
  }

  async loadLastSavedUserData(): Promise<{ lastUser: any, lastModified: any }> {
    const doc = await this.diskService.retrieve(DB_KEY_PREFIXES.USER, true);
    const lastUser = doc && doc.user ? doc.user : undefined;
    if (lastUser && lastUser.offlineDataDuration === -1) {
      lastUser.offlineDataDuration = 0;
    }
    const lastModified = doc && doc.lastModified? doc.lastModified : undefined;
    return { lastUser, lastModified };
  }

  async initialDBEncryptionCheck() {
    if (this.diskService.isDBEncryptionEnabled === null) {
      // Set temp DB encryption flag setup during the app launch
      // In case "getUser" fails, this flag will keep DB encryption if was enabled before.
      const wasEncryptionEnabled = await this.diskService.loadDBEncryptionState();
      if (wasEncryptionEnabled) {
        if (this.user && this.user.mail) {
          this.diskService.setIsDBEncryptionEnabled(true);
          await this.diskService.initDBEncryptionKey(this.user.mail);
        } else {
          console.error('getUserData: DB encrypted but user info not avail');
        }
      }
    }
  }

  async userDataChangeDetection(response, doNotSkipThisFn: boolean, didOfflineDataUploadFail: boolean, lastSavedUserData: { lastUser: any, lastModified: any }) {
    this.individualFullSyncList.length = 0;
    if (!doNotSkipThisFn) {
      return response;
    }
    // Check if full sync required and set the flag
    let doesItRequireFullSync = await this._detectEncryptionFeatureActionChange(response['featureActions'], didOfflineDataUploadFail, response['mail']);
    doesItRequireFullSync = !doesItRequireFullSync ? await this._detectOfflineDataDurationChange(lastSavedUserData.lastUser, response['offlineDataDuration']) : doesItRequireFullSync;
    doesItRequireFullSync = !doesItRequireFullSync ? await this._detectPrimaryPositionChange(lastSavedUserData.lastUser, response['positions']) : doesItRequireFullSync;
    doesItRequireFullSync = !doesItRequireFullSync ? await this._detectBusinessUnitChange(lastSavedUserData.lastUser, response['businessUnitId']) : doesItRequireFullSync;
    doesItRequireFullSync = !doesItRequireFullSync ? await this._detectFeatureActionChanges(lastSavedUserData.lastUser, response['featureActions']) : doesItRequireFullSync;
    doesItRequireFullSync = !doesItRequireFullSync ? await this._detectBuSettingChanges(lastSavedUserData.lastUser, response['buSettings']) : doesItRequireFullSync;

    // Check if individual entity full sync is required
    // Detect Event configuration field change
    if (lastSavedUserData?.lastUser && Array.isArray(response?.appConfigFields)) {
      const eventConfigFieldsResponse = response.appConfigFields.filter(c => c.indskr_entitylogicalname === 'msevtmgt_event');
      // Only consider if new response has any config.
      // No need to full sync again in case all configs are removed.
      if (eventConfigFieldsResponse.length > 0) {
        const localConfigField = lastSavedUserData.lastUser.configuredFields.filter(c => c.entityName === 'msevtmgt_event');
        if (Array.isArray(localConfigField) && localConfigField.length > 0) {
          // Need to diff to decide.
          // Only need to check if any new one got added.
          // Can ignore removed ones.
          let shouldFullSync = false;
          for (let i = 0; i < eventConfigFieldsResponse.length; i++) {
            const res = eventConfigFieldsResponse[i];
            const idx = localConfigField.findIndex(c => c.fieldName === res.indskr_name);
            if (idx < 0) {
              shouldFullSync = true;
              break;
            }
          }
          if ( shouldFullSync) {
            this.individualFullSyncList.push(EntityNames.eventsTool);
          }
        } else {
          // local doesn't have any and new has. Need to full sync.
          this.individualFullSyncList.push(EntityNames.eventsTool);
        }
      }
    }
    // Check if the user language setting has been changed from Dynamics
    let languageChanged = await this._detectLanguageChange(lastSavedUserData.lastUser, response['localeId'], doesItRequireFullSync);
    if(languageChanged) {
      doesItRequireFullSync = true;
      this.events.publish('detectLanguageChange', response['localeId']);
    }

    // Added as per https://indegene.atlassian.net/browse/OMNI-8043  Post child user position change when manager syncs no notification shown for triggering initial sync Call ing teh extracted Method to show the notoification.

    if (!doesItRequireFullSync) { // this is checked because if already this is true, will be different methods to detect the change and notify.
    if (response['childPositionUpdated']) {
       await this.showIntialSyncNotification();
    }
  }
    doesItRequireFullSync = !doesItRequireFullSync? (lastSavedUserData.lastUser, response['childPositionUpdated']?response['childPositionUpdated']:doesItRequireFullSync) : doesItRequireFullSync;

    doesItRequireFullSync = !doesItRequireFullSync? (lastSavedUserData.lastUser, response['usersUpdated']?response['usersUpdated']:doesItRequireFullSync) : doesItRequireFullSync;
    this.shouldFullSync = doesItRequireFullSync;
    if(response['positionGroupUpdated'] != null)
      this.shouldContentFullSync = response['positionGroupUpdated'];
    return response;
  }
  private async _detectEncryptionFeatureActionChange(rawFeatureActions: any[], didOfflineDataUploadFail: boolean, userEmail: string): Promise<boolean> {
    let resetFlag = false;

    // Encryption is Mobile App only feature
    if (Array.isArray(rawFeatureActions)) {
      const isDBEncryptionEnabled = rawFeatureActions.some(f => f.indskr_name === FeatureActionsMap.DATABASE_ENCRYPTION);

      // Set global readonly variable
      this.diskService.setIsDBEncryptionEnabled(isDBEncryptionEnabled);

      // Change detection
      const wasEncryptionEnabled = await this.diskService.loadDBEncryptionState();
      if (wasEncryptionEnabled !== null) {
        if (!wasEncryptionEnabled && isDBEncryptionEnabled) {
          // Encryption enabled and DB already has un-encrypted data
          // Push Offline data, clear DB, and then sync
          // If offline upload fails, it will keep retrying 3 times.
          resetFlag = true;
          if (!didOfflineDataUploadFail) {
            // No offline data remain in the disk. Let's clear DB and sync
            await this.diskService.saveDBEncryptionState(isDBEncryptionEnabled);
            await this.diskService.resetDb();
            this.notificationService.notify('DB Encryption feature has been turned on. Encrypting Database.', '', 'top', undefined, 6000);
          } else if (this._offlineDataUploadRetryCount >= 3) {
            // Offline upload failed more than 3 times.
            // Proceed without encryption until next sync.
            // Not updating encryption state in DB so that change can be caught in next sync.
            this.diskService.setIsDBEncryptionEnabled(false);
            this._offlineDataUploadRetryCount = 0;
            resetFlag = false;
          } else {
            this._offlineDataUploadRetryCount++;
          }
        } else if (wasEncryptionEnabled && !isDBEncryptionEnabled) {
          // Encryption was enabled but disabled now
          // Keep it encrypted
          this.diskService.setIsDBEncryptionEnabled(true);
        }
      } else {
        // First app launch and is enabled.
        await this.diskService.saveDBEncryptionState(isDBEncryptionEnabled);
      }

      await this.diskService.initDBEncryptionKey(userEmail);
    }

    return resetFlag;
  }
  private async _detectOfflineDataDurationChange(lastUser, offlineDataDuration: number): Promise<boolean> {
    if (lastUser) {
      if ((!isNaN(offlineDataDuration) && !isNaN(lastUser.offlineDataDuration)
          && lastUser.offlineDataDuration !== offlineDataDuration)) {
        this.notificationService.notify(this.translate.instant('OFFLINE_DURATION_CHANGE_DETECTED'), '', 'top', undefined, 6000);
        await this.diskService.resetDb();
        return true;
      }
    }
    return false;
  }
  private async _detectPrimaryPositionChange(lastUser, positions: any[]): Promise<boolean> {
    if (positions && Array.isArray(positions) && lastUser && lastUser['positions'] && Array.isArray(lastUser['positions'])) {
      let resetFlag = false;

      const currentActivePositionId = (positions.find(p => p.primary))['positionId'];
      const prevActivePositionId = (lastUser['positions'].find(p => p.primary))['ID'];
      if (currentActivePositionId !== prevActivePositionId) {
        resetFlag = true;
      }

      if (!resetFlag) {
        // Check for any position that has been removed
        for (let i = 0; i < lastUser['positions'].length; i++) {
          const position = lastUser['positions'][i];
          if (!positions.some(p => p.positionId === position.ID)) {
            resetFlag = true;
            break;
          }
        }
      }

      if (!resetFlag) {
        // Check for any position that has been added
        for (let i = 0; i < positions.length; i++) {
          const position = positions[i];
          if (!lastUser['positions'].some(p => p.ID === position.positionId)) {
            resetFlag = true;
            break;
          }
        }
      }

      if (resetFlag) {
        return await this.showIntialSyncNotification();
      }
    }
    return false;
  }
  private async showIntialSyncNotification() {
    this.notificationService.notify(this.translate.instant('POSITION_CHANGE_HAS_BEEN_DETECTED'), '', 'top', undefined, 6000);
    await this.diskService.resetDb();
    return true;
  }

  private async _detectBusinessUnitChange(lastUser, businessUnitId: string) {
    let resetFlag = false;
    if (lastUser && lastUser['businessUnitId'] != businessUnitId) {
      resetFlag = true;
    }
    if (resetFlag) {
      this.notificationService.notify('Business unit change has been detected. Full sync will be triggered and this might take a little longer.', '', 'top', undefined, 6000);
      await this.diskService.resetDb();
      return true;
    }
    return false
  }
  private async _detectFeatureActionChanges(lastUser, rawFeatureActions): Promise<boolean> {
    if (rawFeatureActions && Array.isArray(rawFeatureActions) && lastUser && lastUser['featureActions'] && Array.isArray(lastUser['featureActions'])) {

      // Baseline
      const baselineCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.BASELINE, rawFeatureActions);
      if (baselineCD === FeatureActionChangeActions.TURNED_OFF ||
          baselineCD === FeatureActionChangeActions.TURNED_ON) {
        this.notificationService.notify('Feature action change has been detected. Full sync will be triggered and this might take a little longer.', '', 'top', undefined, 6000);
        // Delete DB
        await this.diskService.resetDb();
        return true;
      }

      const timeOffCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.TIME_OFF_TOOL, rawFeatureActions);
      const allocationCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.ALLOCATION_TOOL, rawFeatureActions);
      const emailCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.MESSAGE_ACTIVITY, rawFeatureActions);
      const eventRegCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.EVENT_REGISTRATION, rawFeatureActions);
      const resourceCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.RESOURCE_TOOL, rawFeatureActions);
      const marketScanCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.CUSTOMER_SCAN_TOOL, rawFeatureActions);
      const surgeryOrderCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.PROCEDURE_LOG, rawFeatureActions);
      const salesOrderCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.ORDER_MANAGEMENT, rawFeatureActions);
      const opportunityOrdersCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.OPPORTUNITY_ORDERS, rawFeatureActions);
      const accountApprovalVisibilityCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED, rawFeatureActions);
      const pharmacovigilanceCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.PHARMACOVIGILANCE_TOOL, rawFeatureActions);
      const pharmacovigilanceInfoBtnsCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.PHARMACOVIGILANCE_INFORMATION_BUTTONS, rawFeatureActions);
      const setBookingsCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.KIT_BOOKING, rawFeatureActions);
      const shortCallCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.SHORT_CALL_LAUNCHER, rawFeatureActions);
      const accountCallPlanCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.ACCOUNT_CALL_PLAN, rawFeatureActions);

      if (timeOffCD === FeatureActionChangeActions.TURNED_ON ||
          allocationCD === FeatureActionChangeActions.TURNED_ON ||
          emailCD === FeatureActionChangeActions.TURNED_ON) {
        // Delete Activities & time off docs from DB
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVITIES);
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TIMEOFFS);
        await this.diskService.remove(DB_KEY_PREFIXES.TIMEOFF_REASONS);
        await this.diskService.remove(DB_KEY_PREFIXES.MY_TIMEOFF_POSITIONS);
        await this.diskService.remove(DB_KEY_PREFIXES.MY_TIMEOFF_USERS);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ACTIVITY);
      }
      if (timeOffCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TIMEOFFS);
        await this.diskService.remove(DB_KEY_PREFIXES.TIMEOFF_REASONS);
        await this.diskService.remove(DB_KEY_PREFIXES.MY_TIMEOFF_POSITIONS);
        await this.diskService.remove(DB_KEY_PREFIXES.MY_TIMEOFF_USERS);
      }
      if (allocationCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SAMPLE_ACTIVITIES)
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ALLOCATION_SHIPMENTS);
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_SAMPLE_ALLOCATIONS);
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_LOTS);
      }
      if (emailCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_ACTIVITIES)
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_EMAIL_ACTIVITIES);
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_EMAIL_TEMPLATES);
      }
      if(salesOrderCD === FeatureActionChangeActions.TURNED_OFF || opportunityOrdersCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ORDER_ACTIVITIES);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ORDER_ACTIVITIES);
      }
      if(surgeryOrderCD === FeatureActionChangeActions.TURNED_OFF){
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SURGERY_ORDER_ACTIVITIES);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_SURGERY_ORDER_ACTIVITIES);
      }
      if(eventRegCD === FeatureActionChangeActions.TURNED_OFF){
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_EVENTS);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT_EVENT)
      }
      if(setBookingsCD === FeatureActionChangeActions.TURNED_OFF ) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SET_BOOKING_ACTIVITIES);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_SET_BOOKING_ACTIVITIES);
      }

      const callPlanCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.CALL_PLANS, rawFeatureActions);
      const callPlanStyleACD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.CALL_PLAN_HOME_SCREEN_STYLE_A, rawFeatureActions);
      const callPlanStyleBCD = this._featureActionChangeDetection(lastUser, FeatureActionsMap.CALL_PLAN_HOME_SCREEN_STYLE_B, rawFeatureActions);
      if (
        (
          callPlanCD === FeatureActionChangeActions.TURNED_OFF
          || callPlanStyleACD === FeatureActionChangeActions.TURNED_ON
          || callPlanStyleACD === FeatureActionChangeActions.TURNED_OFF
          || callPlanStyleBCD === FeatureActionChangeActions.TURNED_ON
          || callPlanStyleBCD === FeatureActionChangeActions.TURNED_OFF
        )
      ) {
        // Delete Call plan docs from DB
        await this.diskService.remove(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
        await this.diskService.remove(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_MY_CALLPLAN);
      }

      if (
        callPlanStyleACD === FeatureActionChangeActions.TURNED_OFF
        || callPlanStyleBCD === FeatureActionChangeActions.TURNED_OFF
      ) {
        await this.diskService.remove(DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS);
      }

      if (this._featureActionChangeDetection(lastUser, FeatureActionsMap.COACHING_TOOL, rawFeatureActions) === FeatureActionChangeActions.TURNED_OFF) {
        // Delete Coaching related docs from DB
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_COACHING_RELATED_DATA);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_MY_COACHING);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_TEAM_COACHING);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_COACHING_TEMPLATES);
      }
      if (this._featureActionChangeDetection(lastUser, FeatureActionsMap.SCHEDULER, rawFeatureActions) === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SCHEDULER_RELATED_DATA);
      }
      if (this._featureActionChangeDetection(lastUser, FeatureActionsMap.CONSENT_TOOL, rawFeatureActions) === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_CONSENTS);
      }
      if (resourceCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_OFFLINE_RESOURCES);
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_RESOURCES);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_RESOURCE);
        this.store.dispatch(new ResourceAction.deleteAlldownloadedResources());
      }
      if (marketScanCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MARKET_SCAN);
        await this.diskService.remove(DB_KEY_PREFIXES.CUSTOMER_SEGMENTATION);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_MARKET_SCAN);
      }
      if (accountApprovalVisibilityCD === FeatureActionChangeActions.TURNED_ON || accountApprovalVisibilityCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNTS);
        await this.diskService.remove(DB_KEY_PREFIXES.ACCOUNT);
        await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT);
      }
      if (pharmacovigilanceCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.remove(DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS);
      }
      if (pharmacovigilanceInfoBtnsCD === FeatureActionChangeActions.TURNED_OFF) {
        await this.diskService.remove(DB_KEY_PREFIXES.PHARMACOVIGILANCE_REPORTS_INFO_BTNS_DATA);
      }
      if (
        this.deviceService.isNativeCordova
        && this.deviceService.isMobileDevicePortrait
      ) {
        if (shortCallCD === FeatureActionChangeActions.TURNED_ON || shortCallCD === FeatureActionChangeActions.TURNED_OFF) {
          
          await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACTS);
          await this.diskService.remove(DB_KEY_PREFIXES.CONTACT);
          await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT);
          if (shortCallCD === FeatureActionChangeActions.TURNED_OFF) {
            // Unlock device lock
            this.deviceService.unlockDeviceOrientation();
          }
        }
      }
      if (accountCallPlanCD === FeatureActionChangeActions.TURNED_ON || accountCallPlanCD === FeatureActionChangeActions.TURNED_OFF) {
        //In both cases, the db is removed because call plan data is Contact and Account combined. Then do full sync.
        await this.diskService.remove(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
        await this.diskService.remove(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
      }
    }
    return false;
  }
  private async _detectBuSettingChanges(lastUser, rawBuSettings): Promise<boolean> {
    if (!lastUser || !lastUser['buSettings'] || !rawBuSettings) {
      console.warn('_booleanBuSettingChangeDetection: BU setting not available: ', lastUser, rawBuSettings);
      return false;
    }
    const callPlanDashApprovedDataFilterStyleACD = this._booleanBuSettingChangeDetection(lastUser['buSettings'], rawBuSettings, 'indskr_displayonlyapprovedcallplansinstylea');
    const callPlanDashApprovedDataFilterStyleBCD = this._booleanBuSettingChangeDetection(lastUser['buSettings'], rawBuSettings, 'indskr_displayonlyapprovedcallplansinstyleb');
    if (
      callPlanDashApprovedDataFilterStyleACD === FeatureActionChangeActions.TURNED_ON
      || callPlanDashApprovedDataFilterStyleACD === FeatureActionChangeActions.TURNED_OFF
      || callPlanDashApprovedDataFilterStyleBCD === FeatureActionChangeActions.TURNED_ON
      || callPlanDashApprovedDataFilterStyleBCD === FeatureActionChangeActions.TURNED_OFF
    ) {
      await this.diskService.remove(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
      await this.diskService.remove(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
      await this.diskService.resetSyncState(DB_SYNC_STATE_KEYS.SYNC_MY_CALLPLAN);
      await this.diskService.remove(DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS);
    }

    return false;
  }
  private _prevCurChangeDetection(prev: boolean, cur: boolean): FeatureActionChangeActions {
    if (prev && cur) {
      return FeatureActionChangeActions.ON;
    } else if (!prev && !cur) {
      return FeatureActionChangeActions.OFF;
    } else if (!prev && cur) {
      return FeatureActionChangeActions.TURNED_ON;
    } else {
      return FeatureActionChangeActions.TURNED_OFF;
    }
  }
  private _featureActionChangeDetection(lastUser, featureAction: FeatureActionsMap, rawFeatureActions: any[]): FeatureActionChangeActions {
    const prev = lastUser['featureActions'].includes(featureAction);
    const cur = rawFeatureActions.some(f => f.indskr_name === featureAction);

    return this._prevCurChangeDetection(prev, cur);
  }
  private _booleanBuSettingChangeDetection(lastUserBuSettings, rawBuSettings, booleanBuSetting: string): FeatureActionChangeActions {
    if (!booleanBuSetting) {
      console.error('_booleanBuSettingChangeDetection: BU setting attribute not available: ', lastUserBuSettings, rawBuSettings, booleanBuSetting);
      return null;
    }
    const prev = !!(lastUserBuSettings[booleanBuSetting] === true);
    const cur = !!(rawBuSettings[booleanBuSetting] === true);

    return this._prevCurChangeDetection(prev, cur);
  }
  private async _detectLanguageChange(lastUser, localeID, doesItRequireFullSync): Promise<boolean> {
    const localLangCode = this._getLocaleIdfromCode(localStorage.getItem('selectedLanguage'));
    if (lastUser) {
      if ((!isNaN(localeID) && !isNaN(lastUser.localeId)
          && lastUser.localeId !== localeID)) {
        if(!doesItRequireFullSync) this.notificationService.notify(this.translate.instant('LANGUAGE_CHANGE_HAS_BEEN_DETECTED'), '', 'top', undefined, 6000);
        await this.diskService.resetDb();
        return true;
      }
    } else if(localLangCode) {
      if (!isNaN(localeID) && localLangCode !== localeID) {
        if(!doesItRequireFullSync) this.notificationService.notify(this.translate.instant('LANGUAGE_CHANGE_HAS_BEEN_DETECTED'), '', 'top', undefined, 6000);
        await this.diskService.resetDb();
        return true;
      }
    }
    return false;
  }

  //Crypto fns
  convertArrayBufferViewtoString(buffer) {
    var str = "";
    for (var iii = 0; iii < buffer.byteLength; iii++) {
      str += String.fromCharCode(buffer[iii]);
    }

    return str;
  }

  convertStringToArrayBufferView(str) {
    var bytes = new Uint8Array(str.length);
    for (var iii = 0; iii < str.length; iii++) {
      bytes[iii] = str.charCodeAt(iii);
    }

    return bytes;
  }
  pushChildPositionsToCurrentUSer(rawData) {
    this._user.childPositions = [];
    if (Array.isArray(rawData) && rawData.length > 0) {
      rawData.map(o => {
        this._user.childPositions.push(new childPosition(o))
        this.childPositions.push(new childPosition(o))
      })
    }
  }
  getLoggedInUserPositions(){
    return this.user.positions.map((o)=>{
      return o.ID
    })
  }

  getConfiguredFieldList(entityName):Array<ConfiguredFields>{
    let configuredFields: Array<ConfiguredFields> = (this.user.configuredFields) ? this.user.configuredFields.filter(field => field['entityName'] === entityName) : [];
    let appConfigFields = [];

    if (configuredFields.length > 0) {
      configuredFields.forEach((field) => {
          appConfigFields.push(field['fieldName']);
      });
    }
    return appConfigFields;
  }
  //Update configered fileds of Account or contact

  updateConfiguredFieldValues = (rawJSON: object,entityName): Array<Object> => {

      let objectCongFields = [];
      let configuredFields: Array<ConfiguredFields> = (this.user.configuredFields) ? this.user.configuredFields.filter(field => field['entityName'] === entityName) : [];
      configuredFields.forEach(value => {
        // let fieldValue = (value.filedtype === 'Lookup') ? rawJSON['_' + value.fieldName + '_value@OData.Community.Display.V1.FormattedValue'] : (rawJSON[value.fieldName] || '');
        let fieldValue;
        switch (value.fieldType) {
          case 'Lookup':
            if (typeof(rawJSON[value.fieldName])=='object') {
              let obj = rawJSON[value.fieldName];
              if (obj) fieldValue = (obj['Name']) ? obj['Name'] : '';
            } else if (rawJSON[value.fieldName + '@OData.Community.Display.V1.FormattedValue']) {
              fieldValue = rawJSON[value.fieldName + '@OData.Community.Display.V1.FormattedValue'];
            } else if (rawJSON['_' + value.fieldName + '_value@OData.Community.Display.V1.FormattedValue']) {
              fieldValue = rawJSON['_' + value.fieldName + '_value@OData.Community.Display.V1.FormattedValue'];
            } else {
              fieldValue = (rawJSON[value.fieldName]) ? rawJSON[value.fieldName] : '';
            }
            break;
          case 'Boolean':
            fieldValue = (rawJSON[value.fieldName]) ? 'Yes' : 'No';
            break;
          case 'DateTime':
            fieldValue = (rawJSON[value.fieldName]) ? isNaN(Number(rawJSON[value.fieldName])) ? new Date(rawJSON[value.fieldName]).toLocaleDateString() : new Date(parseInt(rawJSON[value.fieldName])).toLocaleDateString() : '';
            break;
          case 'Decimal':
            fieldValue = rawJSON[value.fieldName];
            break;
          case 'Picklist':
            fieldValue = (value.fieldType === 'Picklist') ? rawJSON[value.fieldName + '@OData.Community.Display.V1.FormattedValue'] : rawJSON[value.fieldName];
            break;
          case 'Owner':
            fieldValue = rawJSON[value.responseField];
            break;
          case 'State':
            fieldValue = rawJSON[value.responseField];
            break;
          default:
            fieldValue = rawJSON[value.fieldName];
        }
        if (fieldValue) {
          let obj = {
            'fieldName': value.fieldLabel,
            'fieldValue': fieldValue,
            'fieldOrder': value.fieldSequence,
            'fieldType':value.fieldType
          }
          objectCongFields.push(obj);
        }
      });
      objectCongFields = objectCongFields.sort((a,b)=>{
        if(a.fieldOrder>b.fieldOrder) return 1;
        else if(a.fieldOrder<b.fieldOrder) return -1;
        else{
          return a.fieldName<b.fieldName ? -1: 1
        }
      })
      return objectCongFields;
  }

  getTodayInUTCMillisecond(): number {
    return this._getUtcTimestampWithDayOffset(0);
  }

  getPastOfflineDataLimitDateInUTCMillisecond(): number {
    const past = (this.user && this.user.offlineDataDuration >= 0) ? this.user.offlineDataDuration : this.DAYS_PAST;
    return this._getUtcTimestampWithDayOffset(past, '-');
  }

  getFromToDateRangeInUTCMiliSec(pastInDays: number = undefined, futureInDays: number = 183): { from: string, to: string } {
    const pastOffset = pastInDays !== undefined ? pastInDays : (this.user && this.user.offlineDataDuration >= 0) ? this.user.offlineDataDuration : this.DAYS_PAST;
    const fromTimestamp = this._getUtcTimestampWithDayOffset(pastOffset, '-');

    const futureOffset = futureInDays !== undefined ? futureInDays : (this.user && this.user.offlineDataDuration >= 0) ? this.user.offlineDataDuration :this.DAYS_IN_FUTURE;
    const toTimestamp = this._getUtcTimestampWithDayOffset(futureOffset, '+');

    return { from: fromTimestamp.toString(), to: toTimestamp.toString() };
  }

  getFromToDateRangeForMultiCalendarInUTCMiliSec(): { from: string, to: string } {
    const pastOffset = (this.user && this.user.buConfigs['indskr_multicalendardataduration'] && this.user.buConfigs['indskr_multicalendardataduration'] >= 0) ? this.user.buConfigs['indskr_multicalendardataduration'] : this.DAYS_PAST;
    const fromTimestamp = this._getUtcTimestampWithDayOffset(pastOffset, '-');

    const futureOffset = (this.user && this.user.buConfigs['indskr_multicalendardataduration'] && this.user.buConfigs['indskr_multicalendardataduration'] >= 0) ? this.user.buConfigs['indskr_multicalendardataduration'] :this.DAYS_IN_FUTURE;
    const toTimestamp = this._getUtcTimestampWithDayOffset(futureOffset, '+');

    return { from: fromTimestamp.toString(), to: toTimestamp.toString() };
  }

  private _getUtcTimestampWithDayOffset(offsetInDays: number, operation = '-'): number {
    const today = new Date();
    let day;
    if (operation === '-') {
      day = today.getDate() - offsetInDays;
    } else if (operation === '+') {
      day = today.getDate() + offsetInDays;
    } else {
      console.error('_getUtcTimestampWithDayOffset: Invalid operation: ', operation);
      return undefined;
    }
    return Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      day
    );
  }

  private _getLocaleIdfromCode(langCode:string){
    switch (langCode) {
      case 'nl':
        return 1043;
      case 'en' :
        return 1033;
      case 'fr' :
        return 1036;
      case 'de' :
        return 1031;
      case 'ja' :
        return 1041;
      case 'tr' :
        return 1055;
      case 'pt' :
        return 2070;
      case 'it' :
        return 1040;
      case 'es' :
        return 3082;
      case 'zh' :
        return 2052;
      default :
        return 1033;
    }
  }

  public aggregateTeams(rawData:Array<any>):Array<any> {
    let result:Array<any> = [];
    if(!rawData || (rawData && rawData.length <= 0)) return result;
    for(var i=0;i<rawData.length;i++){
      const item = rawData[i];
      if(!item) continue;
      const idx  = result.findIndex(a=> a.hasOwnProperty('teamid') && item.hasOwnProperty('teamid') && a.teamid == item.teamid);
      if(idx >= 0){
        if(item['mb.systemuserid']){
          let member = {
            systemuserid: item['mb.systemuserid'],
            fullname: item['mb.fullname'],
          };
          result[idx].members.push(member);
        }
      } else if(item.hasOwnProperty('teamid') && item.teamid) {
        let memberObj = {
          isdefault: item['isdefault'],
          name: item['name'],
          ownerid: item['ownerid'],
          teamid: item['teamid'],
          excludeFromNote: item['indskr_excludefromomninotes'],
          members: []
        }
        if(item['mb.systemuserid']) {
          let member = {
            systemuserid: item['mb.systemuserid'],
            fullname: item['mb.fullname'],
          };
          memberObj.members.push(member);
        }
        result.push(memberObj);
      }
    }
    return result;
  }

  public setSyncBanner(){
    const timeSinceLastSyncInHours = this.deviceService.getTimeSinceLastSyncInHours();
    const syncReminderTimerConfig = this._user?.ioConfigurations?.find((config) => config.configName == 'SyncReminderTimerInHours');
    const syncReminderTimerInHours = !isNaN(parseInt(syncReminderTimerConfig?.configValue)) ? parseInt(syncReminderTimerConfig.configValue) : null;
    const isAutoDeltaSyncDisabled = this._user?.ioConfigurations?.some((config) => config.configName == 'DisableAutoDeltaSync' && config.configValue === "true");

    if (isAutoDeltaSyncDisabled && syncReminderTimerInHours !== null) {
      this.showSyncBanner = timeSinceLastSyncInHours >= syncReminderTimerInHours;
    }
  }
}

export interface NotesAssistantConfig{
  speechSDKSubscriptionID: string,
  speechRegion: string,
  speechLanguage: string,
  speechCID: string,
  LUISAppID: string,
  LUISSubscriptionKey: string,
  LUISRegion: string,
}
