<ion-item-divider sticky='true' class="keyControlsArea" *ngIf="viewData && !viewData.isHidden" color="light">
  <!-- <span *ngFor="let button of viewData.controls" [ngStyle]="{'display': (button.isHidden ? 'none' : 'block')}"
        ion-text class="controls-area-btn" color="primary" slot="end"
        [ngClass]="(button.isDisabled)?'closed-status':'open-status'" (click)="onButtonClick(button,$event)">{{button.text}}</span> -->
  <!-- <div class="key-container">
    <div class="key-container-left">
      <ion-button [disabled]='button.isDisabled' *ngFor="let button of viewData.controls | filterBy:{leftAligned:true}"
        [ngStyle]="{'display': (button.isHidden ? 'none' : 'block')}" slot="start" icon-only fill="clear"
        (click)="onButtonClick(button,$event)" id="{{button.id}}">
        {{button.text}}
        <img *ngIf="button.showImage" class="sortIcon" src="{{button.imageSRC}}" width="16px">
      </ion-button>
    </div>
    <div class="key-container-right">
      <ion-button [disabled]='button.isDisabled' *ngFor="let button of viewData.controls | filterBy:{leftAligned:false}"
        [ngStyle]="{'display': (button.isHidden ? 'none' : 'block')}" slot="end" icon-only fill="clear"
        (click)="onButtonClick(button,$event)" id="{{button.id}}">
        {{button.text}}
        <img *ngIf="button.showImage" class="sortIcon" src="{{button.imageSRC}}" width="16px">
      </ion-button>
    </div>
  </div> -->
  <!--  -->
  <div class="key-container"  #searchResults>
    <ion-button [disabled]='button.isDisabled' *ngFor="let button of viewData.controls"
      [ngStyle]="{'display': (button.isHidden ? 'none' : 'block')}" slot="start" icon-only fill="clear"
      (click)="onButtonClick(button,$event)" id="{{button.id}}">
      {{button.text}}
      <img *ngIf="button.showImage" class="sortIcon" src="{{button.imageSRC}}" width="16px">
      <!--          <ion-icon *ngIf="button.showIcon" class="filterIcon" slot="end" name="{{button.iconName}}"></ion-icon>-->
    </ion-button>
  </div>
  <ion-button *ngIf="scrollEnabled" slot="start" fill="clear" [disabled]="disbaledLeftArrow" (click)="scrollLeft()">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-button>
  <ion-button *ngIf="scrollEnabled" slot="end" fill="clear" [disabled]="disbledRightArrow" (click)="scrollRight()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>

</ion-item-divider>
