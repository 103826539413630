import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerAssessment, InternalSurvey } from "@omni/classes/customer-assessment/customer-assessment.class";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { FooterService } from "@omni/services/footer/footer.service";
import { UIService } from "@omni/services/ui/ui.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { AssessmentTemplate, SurveyCategory, SurveyStatus, SurveyToolTab, SurveyType } from "@omni/classes/customer-assessment/assessment-template.class";
import { LoadingController, ModalController } from '@ionic/angular';
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { DeviceService } from "@omni/services/device/device.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from "@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal";
import { PageName } from "@omni/services/navigation/navigation.service";


@Component({
  selector: 'survey-history',
  templateUrl: 'survey-history.html',
  styleUrls:['survey-history.scss']
})
export class SurveyHistoryComponent {
  surveyDetailsPageTitle: IndPageTitleViewDataModel;
  surveySectionHeaderData: IndSectionHeaderViewDataModel;
  selectedTemplate: AssessmentTemplate;
  private IcurrentSurveySubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  public groupedSubmittedSurveysToDisplay = [];
  public groupedSavedSurveysToDisplay = [];
  private groupedSumbittedSurveys = [];
  private groupedSavedSurveys = [];
  public timelineSubmittedSurveys: Array<any> = [];
  public timelineSavedSurveys: Array<any> = [];
  private actListLength: number = 0;
  private actSavedListLength: number = 0;
  public totalSubmittedListLength: number = 0;
  public totalSavedListLength: number = 0;
  public submittedSurveyListSectionHeader: IndSectionHeaderViewDataModel;
  public savedSurveyListSectionHeader: IndSectionHeaderViewDataModel;
  public filteredTimelineSubmittedSurveys: Array<any> = [];
  public filteredTimelineSavedSurveys: Array<any> = [];
  public searchPlaceholder: string;
  public searchInput:string = '';
  public filterButtonBadgeCount:number = 0;
  public surveyTimelineFilterFormView: IndFilterMenuModalDataModel[] = [];
  public selectedFilters: any= [];
  public isTimelineFiltered = false;
  
  constructor(
    public footerService: FooterService,
    private uiService: UIService,
    private customerSurveyService: CustomerSurveyService,
    public translate: TranslateService,
    public customerAssessService: CustomerAssessService,
    private loadingCtrl: LoadingController,
    public contactService: ContactOfflineService,
    private device: DeviceService,
    private notificationService: NotificationService,
    private accountService: AccountOfflineService,
    public modalCtrl: ModalController,
  ) {  }

  ngOnInit() {
    this.IcurrentSurveySubscription = this.customerSurveyService.currentSurveyObserver.pipe(takeUntil(this.ngDestroy$)).subscribe((value: AssessmentTemplate) => {
      if (value) {
        this.selectedTemplate = value;
      }
    });
    this.searchPlaceholder = this.translate.instant('SEARCH_ACTIVITIES');
    this._initList();
  }

  ngOnDestroy() {
    if (this.IcurrentSurveySubscription)
      this.IcurrentSurveySubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public doInfinite(eventDetails, event) {
    let startIndex = this.actListLength;
    this.actListLength = this._calListLength(this.groupedSumbittedSurveys, this.actListLength);
    this.groupedSubmittedSurveysToDisplay.push(...this._sliceActivities(startIndex, this.actListLength));
    event.target.complete();
  }

  private _sliceActivities(startIndex: number, count: number) {
    return this.groupedSumbittedSurveys.length < count ? this.groupedSumbittedSurveys.slice(startIndex) : this.groupedSumbittedSurveys.slice(startIndex, count)
  }

  private _calListLength(groupedSurveys, activityIndex) {
    let listLength = 0;
    for(let idx=activityIndex; idx < groupedSurveys.length; idx++){
      if(groupedSurveys[idx].list) listLength += groupedSurveys[idx].list.length;
      if(listLength>30) {
        return idx+1;
      }
    }
    return groupedSurveys.length;
  }

  get isInternalSurvey() {
    return this.selectedTemplate.indskr_surveytype === SurveyType.INTERNAL
  }

  private async _initList() {
    this.timelineSubmittedSurveys = [];
    this.timelineSavedSurveys = [];
    this.groupedSavedSurveysToDisplay = [];
    this.groupedSubmittedSurveysToDisplay = [];
    this.groupedSumbittedSurveys = [];
    this.groupedSavedSurveys = [];
    const entity: string = this.selectedTemplate.indskr_entity;
    const templateId: string = this.selectedTemplate.indskr_assessmenttemplateid;
    let loader =await this.loadingCtrl.create();

    loader.present();
    try {
      //Saved surveys
      let allSavedSurveysFromDB: any = [];
      if(this.isInternalSurvey) {
        let allInternalSurveysFromDB = await this.customerAssessService.getAllInternalSurveysFromDB();
        if(!_.isEmpty(allInternalSurveysFromDB)) {
          allSavedSurveysFromDB = allInternalSurveysFromDB.filter(survey=>survey.surveyStatus == SurveyStatus.SAVED && survey['ca.indskr_template'] == templateId);
        }
      }else {
        // let allExternalSurveysFromDB= await this.customerAssessService.getCustomerSurveyFromDB(templateId);
        let allExternalSurveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, entity, false);
        allSavedSurveysFromDB = allExternalSurveysByTemplateId?.filter(survey=>survey.surveyStatus == SurveyStatus.SAVED);
      }
      if(!_.isEmpty(allSavedSurveysFromDB)) {
        this.timelineSavedSurveys = allSavedSurveysFromDB;
        if(!_.isEmpty(this.timelineSavedSurveys)) {
    
          this.groupedSavedSurveys = await this.customerAssessService.getGroupedSurveysByMonth(this.timelineSavedSurveys,this.isInternalSurvey, true)
          
          if(!_.isEmpty(this.groupedSavedSurveys)) {
            this.groupedSavedSurveys.forEach((survey) => {
              if(survey.list) this.totalSavedListLength += survey.list.length;
            });
            this.actSavedListLength = this._calListLength(this.groupedSavedSurveys, 0);
            this.groupedSavedSurveysToDisplay = this.totalSavedListLength > 30 ? this._sliceActivities(0, this.actSavedListLength) : this.groupedSavedSurveys;
          }
        }
      }
      //Submitted surveys
      const allSurveysFromDB = await this.customerAssessService.getSurveysByTemplateId(templateId, entity, this.isInternalSurvey);
      if(!_.isEmpty(allSurveysFromDB)) {
        const allSubmittedSurveys = allSurveysFromDB.filter(s=>s['surveyStatus'] != SurveyStatus.SAVED);
        this.timelineSubmittedSurveys = allSubmittedSurveys;
        if(!_.isEmpty(this.timelineSubmittedSurveys)) {
    
          this.groupedSumbittedSurveys = await this.customerAssessService.getGroupedSurveysByMonth(this.timelineSubmittedSurveys,this.isInternalSurvey, false)
          
          if(!_.isEmpty(this.groupedSumbittedSurveys)) {
            this.groupedSumbittedSurveys.forEach((survey) => {
              if(survey.list) this.totalSubmittedListLength += survey.list.length;
            });
            this.actListLength = this._calListLength(this.groupedSumbittedSurveys, 0);
            this.groupedSubmittedSurveysToDisplay = this.totalSubmittedListLength > 30 ? this._sliceActivities(0, this.actListLength) : this.groupedSumbittedSurveys;
          }
        }
      }
      loader.dismiss();
    }catch (error) {
      console.log("Error init survey list in activities tab ", error);
      loader.dismiss();
    }
    if(!this.isInternalSurvey) this._initFilterFormViews();
    this._initSurveySectionHeader();
  }

  public async _initFilteredList(savedList, submittedList) {
    this.totalSavedListLength = 0;
    this.totalSubmittedListLength = 0;
    if(!_.isEmpty(savedList)) {
      this.totalSavedListLength = 0;
      this.groupedSavedSurveys = await this.customerAssessService.getGroupedSurveysByMonth(savedList,this.isInternalSurvey, true)     
      if(!_.isEmpty(this.groupedSavedSurveys)) {
        this.groupedSavedSurveys.forEach((survey) => {
          if(survey.list) this.totalSavedListLength += survey.list.length;
        });
        this.actSavedListLength = this._calListLength(this.groupedSavedSurveys, 0);
        this.groupedSavedSurveysToDisplay = this.totalSavedListLength > 30 ? this._sliceActivities(0, this.actSavedListLength) : this.groupedSavedSurveys;
      }
    } else {
      this.groupedSavedSurveys = [];
      this.groupedSavedSurveysToDisplay = [];
    }
    if(!_.isEmpty(submittedList)) {
      this.groupedSumbittedSurveys = await this.customerAssessService.getGroupedSurveysByMonth(submittedList,this.isInternalSurvey, false)
      if(!_.isEmpty(this.groupedSumbittedSurveys)) {
        this.groupedSumbittedSurveys.forEach((survey) => {
          if(survey.list) this.totalSubmittedListLength += survey.list.length;
        });
        this.actListLength = this._calListLength(this.groupedSumbittedSurveys, 0);
        this.groupedSubmittedSurveysToDisplay = this.totalSubmittedListLength > 30 ? this._sliceActivities(0, this.actListLength) : this.groupedSumbittedSurveys;
      }
    } else {
      this.groupedSumbittedSurveys = [];
      this.groupedSubmittedSurveysToDisplay = [];
    }
    this._initSurveySectionHeader();
  }

  public async openSavedSurveyDetails(survey: any) {
    try {
      if (!this.isInternalSurvey) {
        if(this.device.isOffline) {
          this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
          return;
        }
        let loader =await this.loadingCtrl.create();
        try {
          loader.present();
          let surveyFor = survey['entity'] == 'contact' ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT; 
          const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, surveyFor);
          if(!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              const entityId: string = formattedSurvey.indskr_entityid;
              if(formattedSurvey.indskr_entity == SurveyCategory.CONTACT) {
                this.customerSurveyService.selectedContactForSurvey = this.contactService.getContactByID(entityId);
                this.customerSurveyService.selectedAccountForSurvey = null;
              } else {
                this.customerSurveyService.selectedContactForSurvey = null;
                this.customerSurveyService.selectedAccountForSurvey = this.accountService.getAccountById(entityId);
              }
              formattedSurvey['surveyStatus'] = survey.surveyStatus
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, SurveyToolTab.ACTIVITIES, true);
            }
          }
          loader.dismiss();
        } catch (error) {
          console.log("Error open survey details from surve-history ", error);
          loader.dismiss();
        }
        // const formattedSurvey: CustomerAssessment = survey.responses[0];
        // if (!_.isEmpty(formattedSurvey)) {
          // const contactId: string = formattedSurvey.indskr_entityid;
          // this.customerSurveyService.selectedContactForSurvey = this.contactService.getContactByID(contactId);
        //   this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, SurveyToolTab.ACTIVITIES);
        // }
      } else {
        let surveyResponses = this.customerAssessService.allInternalSurveys.filter(s => (s._indskr_usersurvey_value == survey.assessmentId || s.indskr_usersurvey == survey.assessmentId));
        const formattedSurvey: InternalSurvey = await this.customerAssessService.mapInternalSurveyResp(surveyResponses);
        if (!_.isEmpty(formattedSurvey)) {
          this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, SurveyToolTab.ACTIVITIES);
        }
      }
    } catch (error) {
      console.log("Error open survey details from surve-history ", error);
    }
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if(this.device.isOffline) {
      this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    let loader =await this.loadingCtrl.create();
    try {
      loader.present();
      let surveyFor = survey['entity'] == 'contact' ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT; 
      if (!this.isInternalSurvey) {
        const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, surveyFor);
        if(!_.isEmpty(responseData)) {
          const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
          if (!_.isEmpty(formattedSurvey)) {
            formattedSurvey['surveyStatus'] = survey.surveyStatus;
            this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, SurveyToolTab.ACTIVITIES);
          }
        }
      } else {
        const formattedSurvey: InternalSurvey = await this.customerAssessService.mapInternalSurveyResp(survey.responses);
        if (!_.isEmpty(formattedSurvey)) {
          this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, SurveyToolTab.ACTIVITIES);
        }
      }
      loader.dismiss();
    } catch (error) {
      console.log("Error open survey details from surve-history ", error);
      loader.dismiss();
    }
  }

  private _initSurveySectionHeader() {
    const savedNumOfList: number = this.totalSavedListLength;
    this.savedSurveyListSectionHeader = {
      id: 'saved_survey_list_section_header',
      title: `${this.translate.instant("SAVED")} (${savedNumOfList})`,
      controls: [],
    };

    const submittedNumOfList: number = this.totalSubmittedListLength;
    this.submittedSurveyListSectionHeader = {
      id: 'submitted_survey_list_section_header',
      title: `${this.translate.instant("SUBMITTED")} (${submittedNumOfList})`,
      controls: [],
    };
  }

  public handleSearch(){
    let savedList = this.isTimelineFiltered ? this.filteredTimelineSavedSurveys : this.timelineSavedSurveys;
    let submittedList = this.isTimelineFiltered ? this.filteredTimelineSubmittedSurveys : this.timelineSubmittedSurveys;
    if(this.searchInput && this.searchInput.length >= 2) {
      //Saved Survey
      if(!_.isEmpty(savedList)) {
        savedList = savedList.filter((survey) => {
          let flag:boolean = false;
          if(survey['indskr_name']) flag = survey['indskr_name'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          if(!flag && survey['_modifiedby_value_Formatted']) flag = survey['_modifiedby_value_Formatted'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          if(!flag && survey['_indskr_customer_value_Formatted']) flag = survey['_indskr_customer_value_Formatted'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          return flag;
        })
      }
      //Submitted Survey
      if(!_.isEmpty(submittedList)) {
        submittedList = submittedList.filter((survey) => {
          let flag:boolean = false;
          if(survey['indskr_name']) flag = survey['indskr_name'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          if(!flag && survey['_modifiedby_value_Formatted']) flag = survey['_modifiedby_value_Formatted'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          if(!flag && survey['_indskr_customer_value_Formatted']) flag = survey['_indskr_customer_value_Formatted'].toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1;
          return flag;
        })
      }
    }
    this._initFilteredList(savedList, submittedList);
  }

  public onFilterClick(ev) {
    // if(this.totalSubmittedListLength == 0 && this.totalSavedListLength == 0) return;
    this.openTimelineFilter(ev);
  }

  public async openTimelineFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.SurveyHistoryComponent,
          data: this.surveyTimelineFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
        },
      }
    });
    await modal.present().then(() => { });
    modal.onDidDismiss().then(async (res) => {
      //Show Result
      if (res && res.data && res.data.isDone) {
        if (!_.isEmpty(res.data.selectedItems)) {
          this.surveyTimelineFilterFormView = res.data.selectedItems;
          this.selectedFilters = res.data.selectedItemsAccordion;   
          this.filterButtonBadgeCount = this.selectedFilters.length;
          if(!_.isEmpty(this.selectedFilters)) {
            this._getFilteredActivities(this.selectedFilters);
            this.isTimelineFiltered = true;
          } else {
            this.totalSavedListLength = 0;
            this.totalSubmittedListLength = 0;
            this._initList();
            this.isTimelineFiltered = false;
          }     
        }
        console.log(res);
      }
    })
  }

  private _getFilteredActivities(selectedFilters) {
    this.filteredTimelineSavedSurveys = [];
    this.filteredTimelineSubmittedSurveys = [];
    let surveyedByFilters = selectedFilters.filter(filter => filter.type == 'surveyed-by');
    let entityFilters = selectedFilters.filter(filter => filter.type == 'contact' || filter.type == 'account');
    this.timelineSavedSurveys.forEach((saved) => {
      if(!_.isEmpty(surveyedByFilters)) {
        surveyedByFilters.forEach(filter => {
          if(saved['_modifiedby_value_Formatted'] == filter.id) {
            if(!_.isEmpty(entityFilters)) {
              if(entityFilters.some(entityFilter => saved.indskr_entityid == entityFilter.id)) this.filteredTimelineSavedSurveys.push(saved);
            }
            else this.filteredTimelineSavedSurveys.push(saved);          
          }
       })
      } else if(!_.isEmpty(entityFilters) && entityFilters.some(entityFilter => saved.indskr_entityid == entityFilter.id)) {
        this.filteredTimelineSavedSurveys.push(saved);
      }
    })
    this.timelineSubmittedSurveys.forEach((submitted) => {
      if(!_.isEmpty(surveyedByFilters)) {
        surveyedByFilters.forEach(filter => {
          if(submitted['_modifiedby_value_Formatted'] == filter.id) {
            if(!_.isEmpty(entityFilters)) {
              if(entityFilters.some(entityFilter => submitted.indskr_entityid == entityFilter.id)) this.filteredTimelineSubmittedSurveys.push(submitted);
            }
            else this.filteredTimelineSubmittedSurveys.push(submitted);          
          }
       })
      } else if(!_.isEmpty(entityFilters) && entityFilters.some(entityFilter => submitted.indskr_entityid == entityFilter.id)) {
        this.filteredTimelineSubmittedSurveys.push(submitted);
      }
    })
    this._initFilteredList(this.filteredTimelineSavedSurveys, this.filteredTimelineSubmittedSurveys);
  }

  private _initFilterFormViews() {
    this.surveyTimelineFilterFormView = [];
    //Saved Surveys
    if(!_.isEmpty(this.timelineSavedSurveys)) {
      this.timelineSavedSurveys.forEach(savedSurvey => {
        //surveyed-by
        let idx = this.surveyTimelineFilterFormView.findIndex((view) => view.id == 'surveyed-by');
        let survey = {
          group: this.translate.instant('SURVEYED_BY'),
          id: savedSurvey['_modifiedby_value_Formatted'].toString(),
          displayName: savedSurvey['_modifiedby_value_Formatted'].toString(),
          isSelected: false
        }
        if(idx>-1) {
          let optionIdx = this.surveyTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == savedSurvey['_modifiedby_value_Formatted'].toString());
          if(optionIdx<0) this.surveyTimelineFilterFormView[idx].options.push(survey);
        } else {
          let surveyedByView = {
            id : 'surveyed-by',
            displayName: this.translate.instant('SURVEYED_BY'),
            isMultiSelect: true,
            options: []
          }
          surveyedByView.options.push(survey);
          this.surveyTimelineFilterFormView.push(surveyedByView);
        }
        //account or contact
        if(!this.isInternalSurvey) {
          let surveyEntity = this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT ? SurveyCategory.ACCOUNT : SurveyCategory.CONTACT
          let idx = this.surveyTimelineFilterFormView.findIndex((view) => view.id == surveyEntity);
          let survey = {
            group: surveyEntity == SurveyCategory.ACCOUNT ? this.translate.instant('ACCOUNT') :this.translate.instant('CONTACT'),
            id: savedSurvey['indskr_entityid'].toString(),
            displayName: savedSurvey['_indskr_customer_value_Formatted'].toString(),
            isSelected: false
          }
          if(idx>-1) {
            let optionIdx = this.surveyTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == savedSurvey['indskr_entityid'].toString());
            if(optionIdx<0) this.surveyTimelineFilterFormView[idx].options.push(survey);
          } else {
            let surveyedByView = {
              id : surveyEntity,
              displayName: surveyEntity == SurveyCategory.ACCOUNT ? this.translate.instant('ACCOUNT') :this.translate.instant('CONTACT'),
              isMultiSelect: true,
              options: []
            }
            surveyedByView.options.push(survey);
            this.surveyTimelineFilterFormView.push(surveyedByView);
          }
        }
      })
    }
    // Submitted Surveys
    if(!_.isEmpty(this.timelineSubmittedSurveys)) {
      this.timelineSubmittedSurveys.forEach(submittedSurvey => {
        //surveyed-by
        let idx = this.surveyTimelineFilterFormView.findIndex((view) => view.id == 'surveyed-by');
        let survey = {
          group: this.translate.instant('SURVEYED_BY'),
          id: submittedSurvey['_modifiedby_value_Formatted'].toString(),
          displayName: submittedSurvey['_modifiedby_value_Formatted'].toString(),
          isSelected: false
        }
        if(idx>-1) {
          let optionIdx = this.surveyTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == submittedSurvey['_modifiedby_value_Formatted'].toString());
          if(optionIdx<0) this.surveyTimelineFilterFormView[idx].options.push(survey);
        } else {
          let surveyedByView = {
            id : 'surveyed-by',
            displayName: this.translate.instant('SURVEYED_BY'),
            isMultiSelect: true,
            options: []
          }
          surveyedByView.options.push(survey);
          this.surveyTimelineFilterFormView.push(surveyedByView);
        }
        //account or contact
        if(!this.isInternalSurvey) {
          let surveyEntity = this.selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT ? SurveyCategory.ACCOUNT : SurveyCategory.CONTACT
          let idx = this.surveyTimelineFilterFormView.findIndex((view) => view.id == surveyEntity);
          let survey = {
            group: surveyEntity == SurveyCategory.ACCOUNT ? this.translate.instant('ACCOUNT') :this.translate.instant('CONTACT'),
            id: submittedSurvey['indskr_entityid'].toString(),
            displayName: submittedSurvey['_indskr_customer_value_Formatted'].toString(),
            isSelected: false
          }
          if(idx>-1) {
            let optionIdx = this.surveyTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == submittedSurvey['indskr_entityid'].toString());
            if(optionIdx<0) this.surveyTimelineFilterFormView[idx].options.push(survey);
          } else {
            let surveyedByView = {
              id : surveyEntity,
              displayName: surveyEntity == SurveyCategory.ACCOUNT ? this.translate.instant('ACCOUNT') :this.translate.instant('CONTACT'),
              isMultiSelect: true,
              options: []
            }
            surveyedByView.options.push(survey);
            this.surveyTimelineFilterFormView.push(surveyedByView);
          }
        }
      })
    }
  }
}

