import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { Component, ViewChild, Input } from '@angular/core';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { DeviceService } from '../../../services/device/device.service';
import { IonNav, NavParams } from '@ionic/angular';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { TranslateService } from '@ngx-translate/core';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import _ from 'lodash';
import { OpportunityDetailsComponent } from '../opportunity-details/opportunity-details';
import { UIService } from '@omni/services/ui/ui.service';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the OpportunitySelectionMasterPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'opportunity-selection-master-page[base-page]',
  templateUrl: 'opportunity-selection-master-page.html',
  styleUrls:['opportunity-selection-master-page.scss']
})
export class OpportunitySelectionPageComponent {

  @Input() viewData:OpportunitySelectionViewDataModel;
  @Input() clickedOpportunity:Opportunity;

  public indHeaderLeftModel: IndHeaderLeftDataModel;
  public opportunitiesSearchText:string = '';
  public isSearching:boolean = false;
  public isReadOnlyMode:boolean = false;
  public isMultiSelection = false;
  public opportunitiesSelectionList:Array<MainCardViewDataModel> = [];
  public filteredItems:Array<MainCardViewDataModel> = [];
  public relatedItems:Array<MainCardViewDataModel> = [];
  public selectedItems:Array<MainCardViewDataModel> = [];
  public selectedOpportunitiesHeader:IndSectionHeaderViewDataModel;
  public filteredOpportunitiesHeader:IndSectionHeaderViewDataModel;
  public relatedOpportunitiesHeader:IndSectionHeaderViewDataModel;
  private _isDirty:boolean = false;
  public MIN_SEARCH_LENGTH = 1;

  @ViewChild('opportunityselectionrightpane', {static:true}) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    public device: DeviceService,
    private opportunityService: OpportunityManagementService,
    private translate: TranslateService,
    public footerService: FooterService,
    public uiService: UIService,
    private navParams: NavParams,
    public activityService: ActivityService,
    public tCtrl : IonNav
  ) {}

  ngOnInit() {   
    if(this.viewData){
      this.isReadOnlyMode = this.viewData.isReadOnlyMode;
      this.isMultiSelection = this.viewData.isMultiSelection;
      if(this.viewData.opportunitiesDataSet){
        this.filteredItems = this.opportunitiesSelectionList = this.viewData.opportunitiesDataSet.map(opportunity => {
          const isSelected = this.viewData.selectedOpportunities.some(a=> a.ID == opportunity.ID);
          return {
            id: opportunity.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: opportunity.opportunityName,
            isSelected: isSelected,
            showArrow: false,
            arrowType: '',
            clickHandler: (id: string, event, target, data) => this._mainItemClick(id, event, target, data),
          };
        });
      }
      if(this.viewData.relatedOpportunitiesData){
        this.relatedItems  = [];
        this.viewData.relatedOpportunitiesData.forEach(rec=> {
          let item = this.opportunitiesSelectionList.find(a=> a.id == rec.ID);
          if(item){
            this.relatedItems.push(item);
          }
        })
      }
      if(this.viewData.selectedOpportunities){
        this.selectedItems = this.viewData.selectedOpportunities.map(opportunity => {
          return {
            id: opportunity.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            primaryTextRight: opportunity.opportunityName,
            showArrow: false,
            arrowType: '',
            clickHandler: (id: string, event, target, data) => this._mainItemClick(id, event, target, data),
          };
        });
      }

      this._initPageView();
    }else{
      // viewData configuration is mandatory for the component to load
      this.closeSelectionPage();
    }

    this.device.isOfflineObservable.subscribe(isOffline => {
      this._initPageView();
    })
  }

  ionViewDidEnter() {
  }

  ngAfterViewInit(){
    this.navService.initRightPaneChildNav(this.navCtrl,ChildNavNames.OpportunitySelectionNavigation,
      PageName.OpportunitySelectionPage,false,{deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
     //for click opportunity on activity component
    //  if(this.clickedOpportunity){
    //   this.displayClickedOppor(this.clickedOpportunity);
    // }
  }

  public async closeSelectionPage() {
    if (this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingProcedurePushedAccountSelectionView') {
      // this.uiService.activeView = this.uiService.prevView;
      this.tCtrl.pop({ progressAnimation: false }).then(() => {
        // this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        // this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        // if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
          // this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
        // }
        
      if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
      }
      });

    } else {
      if (this.navService.getCurrentPageName() === PageName.SurgeryOrderContractComponent) {
        this.uiService.showRightPane = true;
        this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
        await this.navService.popWithPageTracking().then(() => {
          if (this.activityService.selectedActivityAtHome) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
            if (this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderContractComponent) 
            this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          }
        });
      } else if (this.navService.getCurrentPageName() === PageName.OpportunitySelectionPage) {
        this.navService.popChildNavCtrlFromStack(ChildNavNames.OpportunitySelectionNavigation);
        this.navService.popWithPageTracking();
      }
    }
    // this.navService.popChildNavCtrlFromStack(ChildNavNames.OpportunitySelectionNavigation);
    // this.navService.popWithPageTracking();
  }

  ngOnDestroy(){
  }

  private _initPageView() {
    this.indHeaderLeftModel = {
      id: 'opportunity-selection-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('OPPORTUNITIES'),
      controls: [
        {
          id: "close",
          imgSrc:'assets/imgs/header_cancel.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: false,
          align: "left",
        },
        {
          id: "done",
          imgSrc: 'assets/imgs/header_complete.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: !this._isDirty || (this.device.isOffline && this.viewData.disableDoneButtonInOffline),
          align: "right",
        }
      ],
    };

    this.selectedOpportunitiesHeader = {
      id: 'selected-opportunities-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };

    this.relatedOpportunitiesHeader = {
      id: 'related-opportunities-header',
      title: this.translate.instant('RELATED_OPPORTUNITIES') + ' (' + this.relatedItems.length + ')',
      controls: [],
    };

    this.filteredOpportunitiesHeader = {
      id: 'filtered-opportunities-header',
      title: (this.isSearching?this.translate.instant('AL_RESULTS'):this.translate.instant('OPPORTUNITIES')) + ' (' + this.filteredItems.length + ')',
      controls: [],
    };

  }

  public onPageTitleControlClick(id:string) {
    if(id==='close'){
      this.closeSelectionPage();
    }
    if(id==='done'){
      let newSelectedOpportunities = this.selectedItems.map(a=> {
        const opp = this.opportunityService.getOpportunityDetailsbyId(a.id);
        if(opp) return opp;
        else return new Opportunity({opportunityid: a.id, name: a.primaryTextRight});
      });
      this.viewData.callbackFromSelectionComponent("DONEBUTTONCLICK",newSelectedOpportunities,null);
      this.closeSelectionPage();
    }
  }

  public searchText(ev): void {
    let val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= this.MIN_SEARCH_LENGTH) {
        this.filteredItems = this.opportunitiesSelectionList.filter(e => e.primaryTextRight.toLowerCase().includes(val.toLowerCase().trim()));
        this.isSearching = true;
    } else {
        this.filteredItems = this.opportunitiesSelectionList;
        this.isSearching = false;
    }
    this._initPageView();
  }

  private _mainItemClick(id, event, target, data){
    if(id && target){
      if(target == 'mainItem'){
        let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(id);
        this.filteredItems.forEach(item => item.isSelected = item.id === id);
        if (!_.isEmpty(this.selectedItems)) this.selectedItems.forEach(item => item.isSelected = item.id === id);
        if (foundOpportunity) {
          this.navService.setChildNavRoot(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent, PageName.OpportunitySelectionPage, { from: 'OpportunitiesSelectionPage', opportunity: foundOpportunity });
          this.navService.setChildNavRightPaneView(true);
        }
      }else if(target == 'endIcon'){
        if(data && data.showEndIcon){
          switch (data.endIconType){
            case 'indegene-selectors-add-icon':
              this._handleAddOpportunity(id);
              break;
            case 'indegene-selectors-remove-icon':
              this._handleRemoveOpportunity(id);
              break;
          }
        }
      }
    }
  }

  private _handleAddOpportunity(id){  
    let foundItem;
    foundItem = this.opportunitiesSelectionList.find(a=> a.id == id);
    if(this.isMultiSelection){
      if(foundItem){
        let isAlreadyAddedIndex = this.selectedItems.findIndex(a=> a.id == id);
        if(isAlreadyAddedIndex < 0){
          foundItem.endIconType = 'indegene-selectors-checkmark-icon';
          foundItem.endIconCssClass = 'checkmark-icon';
  
          this.selectedItems.push({
            id: foundItem.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            primaryTextRight: foundItem.primaryTextRight,
            showArrow: false,
            arrowType: '',
            clickHandler: (id: string, event, target, data) => this._mainItemClick(id, event, target, data),
          });
        }
      }
    } else {
      foundItem.endIconType = 'indegene-selectors-checkmark-icon';
      foundItem.endIconCssClass = 'checkmark-icon';
      if(this.selectedItems.length > 0){
        this._handleRemoveOpportunity(this.selectedItems[0].id);
      }
      this.selectedItems = [{
        id: foundItem.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            primaryTextRight: foundItem.primaryTextRight,
            showArrow: false,
            arrowType: '',
            clickHandler: (id: string, event, target, data) => this._mainItemClick(id, event, target, data),
      }]
    }
    this._resetIsDirty();
    this._initPageView();
  }

  private _handleRemoveOpportunity(id){
    let foundItemIndex;
    foundItemIndex = this.selectedItems.findIndex(a=> a.id == id);
    if(foundItemIndex >= 0){
      this.selectedItems.splice(foundItemIndex,1);
      let listItem = this.opportunitiesSelectionList.find(a=> a.id == id);
      if(listItem){
        listItem.endIconType = 'indegene-selectors-add-icon';
        listItem.endIconCssClass = 'add-icon';
      }
      this._resetIsDirty();
      this._initPageView();
    }
  }

  private _resetIsDirty(){
   this._isDirty =  _.xor(this.viewData.selectedOpportunities.map(a=> a.ID),this.selectedItems.map(b=> b.id)).length !== 0;
  }

  private displayClickedOppor(clickedOppor){
    if (clickedOppor) {
      this.filteredItems.forEach(item => item.isSelected = item.id === clickedOppor.ID);
      this.navService.setChildNavRoot(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent, PageName.OpportunitySelectionPage, { from: 'OpportunitiesSelectionPage', opportunity: clickedOppor });
      this.navService.setChildNavRightPaneView(true);
    }
  }

}
