<div
  #pinchZoomRef
  class="pinch-zoom"
  [style.--canvas-width]="this.width + 'px'"
  [style.--canvas-height]="this.height + 'px'"
  [style.--canvas-ratio]="this.width / this.height"
  [style.--rendering-scale]="this.zoomService.renderingScale.getValue()"
  [style.--translate-x]="this.zoomService.renderingTranslate.getValue()[0] + 'px'"
  [style.--translate-y]="this.zoomService.renderingTranslate.getValue()[1] + 'px'"
  [style.--offset-top]="this.offset.top + 'px'"
  [style.--offset-right]="this.offset.right + 'px'"
  [style.--offset-bottom]="this.offset.bottom + 'px'"
  [style.--offset-left]="this.offset.left + 'px'"
  [style.--transition-duration]="this.offset.top + 'px'"
  [class.transition-enabled]="this.zoomService.transitionDuration + 's'"
>
  <div #canvasContainer class="canvas">
    <div #proxyLayer class="iframe-overlay"></div>
    <ng-template #canvasAnchor></ng-template>
  </div>
</div>
<div class="loader-layer" *ngIf="!isZoomInitDone">
  <ion-spinner></ion-spinner>
</div>
