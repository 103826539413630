export class Task {
  private _pending: Promise<void> | undefined = undefined;
  private _setCompleted: (() => void) | undefined = undefined;
  public async waitForCompletion() {
      if (this._pending) {
          await this._pending;
      }
      this._pending = undefined;
      this._setCompleted = undefined;
  }

  public async begin() {
      await this.waitForCompletion();

      this._pending = new Promise<void>((resolve) => {
          this._setCompleted = resolve;
      });
  }

   public async complete() {
      await new Promise(resolve => setTimeout(resolve,100));
      if (this._setCompleted) {
          this._setCompleted();
      }
  }

  public isRunning() {
      return !!this._pending;
  }
}