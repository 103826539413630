import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef,
  ViewContainerRef,
  EmbeddedViewRef,
  ElementRef,
  OnInit
} from '@angular/core';
import { ZoomService } from './ind-pinch-zoom.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'ind-pinch-zoom',
  templateUrl: './ind-pinch-zoom.html',
  styleUrls: ['./ind-pinch-zoom.scss'],
  providers: [ZoomService]
})
export class PinchZoomComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() widthHeight!: [number, number];
  @Input() resizeNotifier: Subject<void>;
  @Input() presChangeNotifier: Subject<void>;
  // @Input() width!: number;
  // @Input() height!: number;
  @Input() offset = { top: 0, right: 0, bottom: 0, left: 0 };
  @Input() maxScale: number = 4;
  @Input() minScale: number = 1;
  @Input() isResponsive: boolean = false;
  @Input() bounds: boolean = true;
  @Input() touch: boolean = true;
  @Input() loaded: boolean = false;

  @Output() dragGestureStart = new EventEmitter<void>();
  @Output() dragGestureEnd = new EventEmitter<void>();

  @ViewChild('pinchZoomRef') pinchZoomEl!: ElementRef;
  @ViewChild('proxyLayer') proxyLayerEl!: ElementRef;
  @ViewChild('canvasContainer', { read: ViewContainerRef, static: true }) canvasContainer!: ViewContainerRef;
  @ViewChild('canvasAnchor', { read: ViewContainerRef, static: true }) canvasAnchor!: ViewContainerRef;

  @ContentChild('canvasTemplate', { static: true }) canvasTemplate!: TemplateRef<any>;

  private iframeViewRef?: EmbeddedViewRef<any>;
  private destroy$ = new Subject<void>();
  width!: number;
  height!: number;

  isZoomInitDone = false;

  constructor(
    public zoomService: ZoomService,
  ) {}

  ngOnInit(): void {
    this.resizeNotifier?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // console.log('### resize notified');
      if (this.isZoomInitDone) {
        setTimeout(() => {
          this.width = !this.isResponsive && this.widthHeight && !isNaN(this.widthHeight[0]) ? this.widthHeight[0] : this.pinchZoomEl?.nativeElement?.offsetWidth;
          this.height = !this.isResponsive && this.widthHeight && !isNaN(this.widthHeight[1]) ? this.widthHeight[1] : this.pinchZoomEl?.nativeElement?.offsetHeight;
          this.zoomService.handleScreenResize(this.width, this.height);
        }, 150);
      }
    });

    this.presChangeNotifier?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // console.log('### pres change notified');
      this.zoomService.destroy();
    });
  }

  ngAfterViewInit(): void {
    // Render the canvasTemplate dynamically
    this.iframeViewRef = this.canvasAnchor.createEmbeddedView(this.canvasTemplate);
    // console.log('### embeddedView crated! ', this.iframeViewRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loaded'] && !changes['loaded'].firstChange) {
      if (changes['loaded'].currentValue === true && !this.isZoomInitDone) {
        setTimeout(() => {
          this.initZoom();
        }, 100);
      } else if (changes['loaded'].currentValue === false && this.isZoomInitDone) {
        // destroy
        this.zoomService.destroy();
        this.isZoomInitDone = false;
      }
    }
  }

  initZoom() {
    this.width = !this.isResponsive && this.widthHeight && !isNaN(this.widthHeight[0]) ? this.widthHeight[0] : this.pinchZoomEl?.nativeElement?.offsetWidth;
    this.height = !this.isResponsive && this.widthHeight && !isNaN(this.widthHeight[1]) ? this.widthHeight[1] : this.pinchZoomEl?.nativeElement?.offsetHeight;

    // console.log('### initZoom calling: ', this.width, this.height);
    this.zoomService.initZoom({
      pinchZoomEl: this.pinchZoomEl.nativeElement,
      wrapperEl: this.canvasContainer.element.nativeElement,
      proxyLayerEl: this.proxyLayerEl.nativeElement,
      iframeEl: this.iframeViewRef?.rootNodes[0] ?? undefined,
      canvasNaturalWidth: this.width,
      canvasNaturalHeight: this.height,
      isResponsive: this.isResponsive,
      offset: this.offset,
      minScale: this.minScale,
      maxScale: this.maxScale,
    });
    this.isZoomInitDone = true;
  }

  ngOnDestroy(): void {
    this.zoomService.destroy();
    this.destroy$.next();
    this.destroy$.complete();
    this.isZoomInitDone = false;
  }
}
