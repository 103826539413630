<ng-container *ngIf="!presData">
    <nothing-selected-view [deviceString]="deviceService.deviceFlags.ios ? 'ios' : 'android'"></nothing-selected-view>
</ng-container>
<ion-header [hidden]="!presData" [ngClass]="{'preview-title': from == pageName.ShortCallHomeComponent}">
  <ng-container *ngIf="from == pageName.ShortCallHomeComponent; else pageTitle">
    <ion-toolbar class="page-title right-header-background-HCP" no-padding>
      <ion-grid no-padding>
        <ion-row>
          <ion-col no-padding>
            <div class="meeting-interaction-wrapper">
              <div class="meeting-interaction">
                <ion-buttons class="short-call-back">
                  <ion-button (click)="goBack()">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <ion-chip class="io-chip-active">
                  <img io-thumbnail [src]="thumbURL | safe: 'url' ">
                  <ion-label>{{presData?.name}}</ion-label>
                </ion-chip>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
    <ion-progress-bar mode="ios" class="left-progress-bar"
      *ngIf="deviceService.isBackgroundUploadInProgress || deviceService.syncInProgress"
      type="indeterminate"></ion-progress-bar>
      <div class="preview-card"><span>{{ 'PREVIEW' | translate}}</span></div>
  </ng-container>
  <ng-template #pageTitle>
    <ind-page-title [viewData]='presentaionPreviewTitle' (onControlClick)='goBack()'></ind-page-title>
  </ng-template>

</ion-header>
<ion-content class="content-page" *ngIf="presData">
    <ng-container ion-fixed *ngIf="presMode !== PresentationView.MEETING">
        <ng-template #file_not_supported>
            <ng-container>
                <p class="no-data-message">
                    {{'CONTACT_NO_PREVIEW_AVAILABLE_FOR' | translate}} <i>{{selectedPage?.name}} - {{'PARTICI_NOT_SUPPORTED' | translate}}</i>
                </p>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="presData && selectedPage?.fileType != 'unsupported'; else file_not_supported">
            <io-iframe class="iframe-container" [selectedPageUrl]="selectedPageUrl" [viewMode]="presMode" scrolling="auto"> </io-iframe>
            <io-carousel class="carousel-container" #carousel [pages]="presentationService.presPages" [showGalleryBtn]="false" [briefcase]="presList" [viewMode]="presMode">
            </io-carousel>
        </ng-container>
    </ng-container>
    <!-- <footer-toolbar *ngIf="deviceService.isMobileDevice" [footerView]="'detailView'" [selectedView]="'presentations'"></footer-toolbar> -->
</ion-content>
<!-- <ion-footer *ngIf="presData"> -->
    <footer-toolbar *ngIf="presData" [footerView]="'detailView'" [selectedView]="'presentations'" [attachedComponent]="pageName.PresentationPreviewComponent"></footer-toolbar>
<!-- </ion-footer> -->
